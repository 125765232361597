import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowRightL, ArrowsSwitchVertical, InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import getIncidents from '../../services/bookmarkerIncidents';
import DetailIncidentPopup from './DetailIncidentPopup';

const ManageIncidents = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [incidents, setIncidents] = useState([]);
  const [incidentsPages, setIncidentsPages] = useState();
  const [editableRowData, setEditableRowData] = useState(null);
  const [displayDetailPopup, setDisplayDetailPopup] = useState(false);

  function changeOrder(column, sortedSide) {
    searchParams.set('orderby', column);
    searchParams.set('orderby_direction', sortedSide);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  }
  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getIncidents(window.location.search);
      setIncidentsPages({
        current_page: response.current_page,
        last_page: response.last_page,
        total: response.total,
        per_page: response.per_page,
      });
      setIncidents(response.data);
    }
    fetchData();
  }, [searchParams]);

  return (
    incidents && (
      <>
        <section className="flex flex-col justify-between mb-1">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-4">Gestión de incidencias</h1>
          <section
            id="filters"
            className="flex flex-col w-full intermedium:mb-0 intermedium:child:w-auto intermedium:flex-row justify-between pb-4 child:mb-3">
            <section className="flex gap-2 lg:gap-0 flex-col-reverse lg:flex-row child:lg:mr-2 mb-2">
              <Select
                id="state"
                border={false}
                width="min-w-[200px]"
                placeholderMessage="Filtrar por estado"
                action={(e) => setSearchParams({ page_number: 1, state: e.value })}
                options={[
                  { value: '', label: 'Todos los estados' },
                  { value: 'open', label: 'Abierta' },
                  { value: 'closed', label: 'Cerrada' },
                  { value: 'solved', label: 'Resuelta' },
                ]}
                icon={<ArrowsSwitchVertical />}
              />
            </section>
          </section>
        </section>
        <Table
          tableClass="w-full hidden lg:table"
          changeOrder={(e, c) => changeOrder(e, c)}
          rowsData={prepareTableData()}
          colsData={[
            {
              Header: 'Asunto',
              accessor: 'title',
              sortable: true,
            },
            {
              Header: 'Usuario',
              accessor: 'username',
              sortable: false,
            },
            {
              Header: 'Estado',
              accessor: 'state',
              sortable: true,
            },
            {
              Header: 'Fecha',
              accessor: 'date_incident',
              sortable: true,
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
            },
          ]}
        />
        <section className="lg:hidden">
          {incidents.map((element, index) => (
            <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
              <div className="flex flex-col text-center mb-3">
                <p className="text-black-12 dark:text-accent-dark font-bold">{element.title}</p>
                <p className="text-black-10 text-sm ">{element.user.username}</p>
              </div>
              <div className="flex flex-col child:mb-3">
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Estado
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">{translateState(element.state)}</p>
                </section>
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">Fecha</span>
                  <p className="text-black-12 dark:text-accent-dark font-bold break-words">
                    {moment(element.created_at).fromNow()}
                  </p>
                </section>
              </div>
              <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
            </div>
          ))}
        </section>
        <section id="paginator" className="mt-5 flex flex-row justify-between mb-20">
          {incidentsPages && (
            <>
              <section id="numbers">
                {generatePaginatorRange(incidentsPages).map((element) =>
                  incidentsPages.current_page === element ? (
                    <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                      {element}
                    </button>
                  ) : (
                    <button
                      onClick={() => changePageNumber(element)}
                      className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                      {element}
                    </button>
                  )
                )}
              </section>
              {incidentsPages.current_page < incidentsPages.last_page && (
                <button
                  onClick={() => changePageNumber(incidentsPages.current_page + 1)}
                  className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
                  Siguiente{' '}
                  <Icon className="text-xl stroke-black pl-2">
                    <ArrowRightL />
                  </Icon>
                </button>
              )}
            </>
          )}
        </section>
        <DetailIncidentPopup
          display={displayDetailPopup}
          closePopup={async () => {
            setDisplayDetailPopup(!displayDetailPopup);
            const response = await getIncidents(window.location.search);
            setIncidents(response.data);
          }}
          data={editableRowData}
        />
      </>
    )
  );

  function generatePaginatorRange(usersPages) {
    const pages = [];
    if (usersPages) {
      if (usersPages.total > 0) {
        if (usersPages.per_page < usersPages.total) {
          if (usersPages.current_page > 1) {
            for (
              let i = usersPages.current_page - 1;
              i <= usersPages.last_page && i < usersPages.current_page + 3;
              i++
            ) {
              pages.push(i);
            }
          } else {
            for (let i = usersPages.current_page; i <= usersPages.last_page && i < usersPages.current_page + 3; i++) {
              pages.push(i);
            }
          }
        }
      }
    }
    return pages;
  }

  function prepareTableData() {
    if (incidents) {
      const rowsData = [];
      incidents.forEach((element) => {
        rowsData.push({
          title: element.title,
          username: element.user.username,
          state: translateState(element.state),
          date_incident: moment(element.created_at).fromNow(),
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  function translateState(state) {
    switch (state) {
      case 'open':
        return (
          <span className="rounded-2xl bg-warning-yellow-5 px-4 py-2 text-white font-bold text-center w-full block">
            Abierta
          </span>
        );
      case 'closed':
        return (
          <span className="rounded-2xl bg-primary px-4 py-2 text-white font-bold text-center w-full block">
            Cerrada
          </span>
        );
      default: // solved
        return (
          <span className="rounded-2xl bg-secondary px-4 py-2 text-white font-bold text-center w-full block">
            Resuelta
          </span>
        );
    }
  }

  function generateActionButtons(element) {
    return (
      <section className="flex flex-row justify-around">
        <button
          onClick={() => {
            setEditableRowData(element);
            setDisplayDetailPopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <InfoCircle />
          </Icon>
        </button>
      </section>
    );
  }
};

export default ManageIncidents;
