import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { useEffect, useState } from 'react';
import updateUser from '../../services/users';

const UserTestPopup = ({ display, closePopup, user }) => {
  const [comment, setComment] = useState(user?.comment);

  useEffect(() => {
    if (user) {
      setComment(user.comment);
    }
  }, [user]);

  return (
    user && (
      <Popup display={display} width={'md:min-w-[40%]'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <section className="flex flex-row items-end">
            <h3 className="text-xl font-semibold dark:text-accent-dark">
              Usuario {user?.username} ({user?.email})
            </h3>
          </section>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                setComment('');
                closePopup();
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        <section className="mb-5 pl-5 pr-5">
          <p htmlFor="info_user_test" className="flex flex-row text-sm dark:text-accent-dark">
            {user?.is_test ? (
              <span>
                El usuario se ha <strong className="text-secondary">MARCADO</strong> como usuario test.
              </span>
            ) : (
              <span>
                El usuario se ha <strong className="text-warning-red">DESMARCADO</strong> como usuario test.
              </span>
            )}
          </p>
        </section>
        <section className="mb-5 pl-5 pr-5">
          <label htmlFor="observaciones" className="dark:text-accent-dark dark:bg-primary-1">
            Observaciones
          </label>
          <Input
            id="observaciones"
            width="w-auto"
            name="observaciones"
            placeholderMessage="Observaciones"
            type={'text'}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </section>
        <Button
          size={'medium'}
          action={async () => {
            await updateUser({ id: user.id, comment });
            user.comment = comment;
            closePopup();
          }}
          className="mx-auto mb-5">
          Actualizar observaciones
        </Button>
      </Popup>
    )
  );
};

export default UserTestPopup;
