const FeaturedGuidesText = ({ children, label, borderColor = 'primary', backgroundColor = 'white', margin }) => {
  return (
    <section
      id="featured-text"
      className={`p-5 border-2 border-${borderColor} bg-${backgroundColor} rounded-lg my-3 ${margin}`}>
      {label && <header className="font-bold text-black-10 dark:text-accent-dark">{label}</header>}
      <div className="text-black-12">{children}</div>
    </section>
  );
};

export default FeaturedGuidesText;
