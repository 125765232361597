import axios from '../lib/axios';

export default async function getIncidents(params = '') {
  const response = await axios
    .get(`/api/v1/incidents?page_size=30${params === '' ? '&page_number=1' : params.replace('?', '&')}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getUserIncidents(userId) {
  const response = await axios
    .get(`/api/v1/users/${userId}/incidents`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function updateIncident(incident) {
  const response = await axios
    .put(`/api/v1/incidents/${incident.id}`, incident)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function createIncident(incident) {
  const response = await axios
    .post(`/api/v1/incidents`, incident)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
