import { Graphic, GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { useNavigate } from 'react-router-dom';
import { admin } from './constants/admin';

const DashboardAdmin = ({ dashboard }) => {
  const navigate = useNavigate();
  const {
    type_bar,
    series,
    options,
    options_money,
    series_betting_general,
    series_bounds_populars,
    options_bounds_populars,
    type_bounds_populars,
    series_referred_users,
    options_referred_users,
    series_bounds_populars_news_reg,
    options_bounds_populars_news_reg,
  } = admin(dashboard);

  return (
    <>
      <section className="grid grid-cols-1 justify-between md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-6">
        {/* 6 */}
        <GraphicCardNumber
          title="Facturación (periodo)"
          number={dashboard?.billing_of_the_month || 0}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix="€"
        />
        {/* 10 */}
        <GraphicCardNumber
          title="Usuarios activos"
          number={dashboard?.count_users?.active_users || 0}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix=""
        />
        <div
          className="cursor-pointer"
          onClick={() => navigate('/users?role=user&page_number=1&days_to_expired=1&is_test=false')}
          title="Consultar los usuarios">
          <GraphicCardNumber
            className="hover:bg-primary-3"
            title="Expiran hoy o mañana"
            number={dashboard?.count_users?.expired_users || 0}
            widthGraph="60"
            heightGraph="50"
            numberClass="text-3xl font-bold dark:text-accent-dark"
            sufix=""
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate('/users?type=expired_users&date_from=' + dashboard?.date_from + '&date_to=' + dashboard?.date_to)
          }
          title="Consultar los usuarios">
          <GraphicCardNumber
            className="hover:bg-primary-3"
            title="No han renovado (periodo)"
            number={dashboard?.count_users?.expired_users_period || 0}
            widthGraph="60"
            heightGraph="50"
            numberClass="text-3xl font-bold dark:text-accent-dark"
            sufix=""
          />
        </div>
        <GraphicCardNumber
          title="Suscripciones que se activan"
          number={dashboard?.count_users?.gained_users || 0}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix=""
        />
      </section>
      <section className="mt-6 mb-6 grid grid-cols-1 justify-between md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-6">
        {/* 7 */}
        <Graphic
          title={`Facturación general (${dashboard?.label_date})`}
          series={series_betting_general}
          options={options_money}
          type={type_bar}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* 2,3,4 */}
        <Graphic
          title={`Usuarios (${dashboard?.label_date})`}
          series={series}
          options={options}
          className="bg-white"
          height="350px"
          width="100%"
        />
      </section>
      <section className="mt-6 mb-20 grid grid-cols-1 justify-between md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 gap-6">
        {/* 8. Uso de tarifas (usuarios activos) */}
        <Graphic
          title={`Uso de tarifas`}
          series={series_bounds_populars}
          options={options_bounds_populars}
          type={type_bounds_populars}
          className="bg-white"
          height="350px"
          width="100%"
        />
        <Graphic
          title={`Nuevos registros (${dashboard?.label_date})`}
          series={series_bounds_populars_news_reg}
          options={options_bounds_populars_news_reg}
          type={type_bounds_populars}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* 9. Afiliados */}
        <Graphic
          title="Referidos por usuario"
          series={series_referred_users}
          options={options_referred_users}
          type={type_bar}
          className="bg-white"
          height="350px"
          width="100%"
        />
      </section>
    </>
  );
};

export default DashboardAdmin;
