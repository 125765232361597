import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { IconLock, IconUser, IconTelegram, UserGroup, ChartBar } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { NavLink } from 'react-router-dom';
import { useUser } from '../../context/UserContext';

const Settings = ({ children }) => {
  const {
    state: { user },
  } = useUser();

  return (
    <div className="flex flex-col lg:flex-row">
      <aside
        id="sidebar"
        className="lg:w-auto overflow-x-auto mb-3 lg:pr-20 child:flex child:flex-col child:lg:flex-row justify-between child:items-center child:px-2 child:py-2 lg:child:mr-0 flex flex-row lg:flex-col lg:justify-start child:whitespace-nowrap child:w-full dark:text-accent-dark border-b border-black-4 dark:border-none lg:border-none">
        <NavLink
          to={'/settings/me'}
          id="me"
          className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
          <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
            <IconUser />
          </Icon>
          <span className="hidden sm:block">Mis datos</span>
        </NavLink>
        <NavLink
          to={'/settings/password'}
          id="password"
          className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
          <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
            <IconLock />
          </Icon>
          <span className="hidden sm:block">Contraseña</span>
        </NavLink>
        <NavLink
          to={'/settings/telegram'}
          id="telegram"
          className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
          <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
            <IconTelegram />
          </Icon>
          <span className="hidden sm:block">Telegram</span>
        </NavLink>
        <NavLink
          to={'/settings/excel'}
          id="excel"
          className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
          <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
            <ChartBar />
          </Icon>
          <span className="hidden sm:block">Excel</span>
        </NavLink>
        <NavLink
          to={'/settings/affiliates'}
          id="affiliates"
          className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
          <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
            <UserGroup />
          </Icon>
          <span className="hidden sm:block">Afiliados</span>
        </NavLink>
        {user?.role === 'admin' && (
          <NavLink
            to={'/settings/global-vars'}
            id="global-vars"
            className="hover:bg-blue-200 hover:cursor-pointer dark:hover:bg-gray-600 border-r border-black-4 sm:border-none">
            <Icon className="stroke-black mr-3 text-3xl sm:text-2xl">
              <IconUser />
            </Icon>
            <span className="hidden sm:block">Variables globales</span>
          </NavLink>
        )}
      </aside>

      <section className="flex flex-col p-10 shadow rounded-2xl bg-white grow">{children}</section>
    </div>
  );
};

export default Settings;
