import axios from '../lib/axios';
import FileSaver from 'file-saver';

export default async function getSettings() {
  const response = await axios
    .get(`/api/v1/settings`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function updateSetting(settings) {
  const response = await axios
    .put(`/api/v1/settings/${settings.key}`, {
      ...settings,
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function downloadExcel() {
  const response = await axios
    .get('/api/v1/settings/download-excel', {
      responseType: 'blob',
    })
    .then((res) => {
      const excelData = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(excelData, 'Plantilla Básica Excel Vilibets.xlsx');
    })
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
