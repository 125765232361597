import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Textarea } from '@vilibets/vilibets-uikit/dist/atoms/Textarea';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { createProfitTracker, updateProfitTracker } from '../../services/profitTracker';
import { useUser } from '../../context/UserContext';
import { toast } from 'react-toastify';

const AddProfitTrackerPopup = ({ display, closePopup, bookmarkers, data = null }) => {
  const {
    state: { user },
  } = useUser();

  const [fields, setField] = useState(
    data === null
      ? {
          bet: '',
          bookmarker_id: '',
          state: '',
          event: '',
          profit: '',
          comment: '',
          stake: '',
          qualifying_loss: '',
        }
      : data
  );
  useEffect(() => {
    if (data !== null) {
      setField(data);
    }
  }, [data]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };
  const handleSelectChange = (selectName, event) => {
    const name = selectName;
    const value = event.value;
    setField((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fields.qualifying_loss && fields.stake)
      fields.profit = (Number(fields.stake) + Number(fields.qualifying_loss)).toFixed(2);
    else if (!fields.qualifying_loss && fields.stake) fields.profit = Number(fields.stake).toFixed(2);
    else if (fields.qualifying_loss && !fields.stake) fields.profit = Number(fields.qualifying_loss).toFixed(2);
    const form = { ...fields, user_id: user.id };
    if (data === null) {
      await createProfitTracker(form);
      toast.success('Añadido al ProfitTracker', {
        icon: '👌',
      });
    } else {
      const form = { ...fields };
      await updateProfitTracker(form);
      toast.success('Actualizado en ProfitTracker', {
        icon: '👌',
      });
    }
    setField({
      bet: '',
      bookmarker_id: '',
      state: '',
      event: '',
      profit: '',
      comment: '',
      stake: '',
      qualifying_loss: '',
    });
    closePopup();
  };

  const options = [
    { value: 'bet_and_receive', label: 'Apostar y recibir' },
    { value: 'refund', label: 'Reembolso' },
    { value: 'rollover_bonus', label: 'Bonus rollover' },
    { value: 'deposit_and_receive', label: 'Depositar y recibir' },
    { value: 'superior_bonuses', label: 'Bonos superiores' },
    { value: 'slots', label: 'Slots' },
    { value: 'cashbacks', label: 'Cashbacks' },
    { value: 'combined', label: 'Combinada' },
    { value: 'unknown', label: 'Otros' },
  ];

  const state = [
    { value: 'completed', label: 'Completada' },
    { value: 'pending', label: 'Pendiente' },
  ];

  return (
    <>
      <Popup display={display} width={'md:w-6/12 md:min-w-6/12'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <h3 className="text-xl font-semibold dark:text-accent-dark">{data === null ? 'Añadir' : 'Editar'} apuesta</h3>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                closePopup();
                setField({
                  bet: '',
                  bookmarker_id: '',
                  state: '',
                  event: '',
                  profit: '',
                  comment: '',
                  stake: '',
                  qualifying_loss: '',
                });
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {/* body */}
        <form
          onSubmit={handleSubmit}
          className="relative px-6 flex-auto bg-white flex flex-wrap child:mb-4 rounded-b-xl">
          <div className="w-full xl:w-8/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="event">
              Promoción
            </label>
            <Input
              id="event"
              width="w-full"
              placeholderMessage={'Boca Juniors v Deportivo Cali'}
              autoFocus
              name="event"
              value={fields.event}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full md:w-6/12 md:pr-4 xl:pr-0 xl:w-4/12 ">
            <label className="dark:text-normal-dark" htmlFor="bet">
              Tipo de promoción
            </label>
            <Select
              options={options}
              id="bet"
              name="bet"
              borderWidth="border-2"
              width="w-full"
              placeholderMessage="Tipo de apuesta"
              action={(e) => handleSelectChange('bet', e)}
              initialValue={options.find((option) => option.value === fields.bet)}
              type="small"
              hasShadow={false}
              className="mt-1"
            />
          </div>
          <div className="w-full md:w-6/12 pr-0 xl:w-6/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="bookmarker_id">
              Casa de apuestas
            </label>
            <Select
              options={bookmarkers}
              initialValue={bookmarkers.find((option) => option.value === fields.bookmarker_id)}
              id="bookmarker_id"
              name="bookmarker_id"
              borderWidth="border-2"
              width="w-full"
              placeholderMessage="Selecciona una casa de apuestas"
              action={(e) => handleSelectChange('bookmarker_id', e)}
              type="small"
              hasShadow={false}
              className="mt-1"
            />
          </div>
          <div className="w-full md:w-6/12 md:pr-4 xl:pr-0 xl:w-6/12">
            <label className="dark:text-normal-dark" htmlFor="state">
              Estado
            </label>
            <Select
              options={state}
              initialValue={state.find((option) => option.value === fields.state)}
              id="state"
              name="state"
              borderWidth="border-2"
              width="w-full"
              placeholderMessage="Estado de la apuesta"
              action={(e) => handleSelectChange('state', e)}
              type="small"
              hasShadow={false}
              className="mt-1"
            />
          </div>
          <div className="w-full md:w-6/12 pr-0 xl:w-6/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="event">
              Pérdida calificante (€)
            </label>
            <Input
              id="qualifying_loss"
              width="w-full"
              placeholderMessage={'12,3'}
              name="qualifying_loss"
              type={'number'}
              value={fields.qualifying_loss}
              onChange={handleChange}
              className="mt-1"
              step="0.01"
            />
          </div>
          <div className="w-full md:w-6/12 md:pr-4 xl:pr-0 xl:w-6/12">
            <label className="dark:text-normal-dark" htmlFor="stake">
              Ganancia total (€)
            </label>
            <Input
              id="stake"
              width="w-full"
              placeholderMessage={'12,3'}
              type="number"
              name="stake"
              value={fields.stake}
              onChange={handleChange}
              className="mt-1"
              step="0.01"
            />
          </div>
          {/* <div className="w-6/12 pr-4 xl:w-6/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="event">Beneficio (€)</label>
            <Input
              id="profit"
              width="w-full"
              placeholderMessage={'12,3'}
              name="profit"
              type={'number'}
              value={fields.profit}
              onChange={handleChange}
              className="mt-1"
            />
          </div> */}
          <div className="w-full">
            <label className="dark:text-normal-dark" htmlFor="event">
              Comentario
            </label>
            <Textarea
              id="comment"
              name="comment"
              type="small"
              placeholderMessage={'Escribe un comentario si lo desea...'}
              value={fields.comment}
              onChange={handleChange}
              width={'w-full'}
            />
          </div>
          <div className="flex items-center justify-start py-3 rounded-b w-full">
            <Button
              className="mr-2"
              size={'large'}
              typeButton="submit"
              disabled={
                fields.event === '' ||
                fields.bet === '' ||
                fields.event === '' ||
                fields.bookmarker_id === '' ||
                fields.state === ''
              }>
              Añadir a profitracker
            </Button>
          </div>
        </form>
        {/* footer */}
      </Popup>
    </>
  );
};

export default AddProfitTrackerPopup;
