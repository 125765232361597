import { SelectCheckBoxList } from '@vilibets/vilibets-uikit/dist/molecules/SelectCheckBoxList/SelectCheckBoxList';
import { useEffect, useState } from 'react';
import getBookmarkers from '../services/bookmarker';

const BookmarkerSelector = ({ action, items, width }) => {
  const [bookmarkers, setBookmarkers] = useState(null);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      async function fetchFunction() {
        const response = await getBookmarkers();
        setBookmarkers(response);
      }
      fetchFunction();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div id="bookmarker">
      <SelectCheckBoxList
        action={action}
        labelAllNone="Todo / Nada"
        width={`!w-full lg:!w-[300px] ${width}`}
        placeHolderMessageAllSelected="Mostrando todas las casas de apuestas"
        items={items.length > 0 && items != null ? items : bookmarkersData()}
      />
    </div>
  );

  function bookmarkersData() {
    if (bookmarkers) {
      const bookmarkersData = [];
      bookmarkers.forEach((element) => {
        bookmarkersData.push({
          id: element.id,
          name: element.title.charAt(0).toUpperCase() + element.title.slice(1),
          defaultChecked: true,
          isDisabled: false,
          hasError: false,
          image: element.logo_url,
          label: element.title.charAt(0).toUpperCase() + element.title.slice(1),
        });
      });
      return bookmarkersData;
    }
  }
};

export default BookmarkerSelector;
