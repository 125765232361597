import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function BonosRolloverPastonBetsson() {
  return (
    <section className="guide-article dark:text-accent-dark pb-5">
      <GuideHeader title={'Bonos con Rollover de Pastón y Betsson'} />

      <p>
        A continuación pasamos a describir la forma en como puedes desbloquear los bonos de las casas de Pastón y de
        Betsson. A pesar de su alto rollover (x10) suelen ser promociones que bien afrontadas pueden reportar grandes
        beneficios.
      </p>
      <p>Las condiciones generales del rollover de estas dos casas son:</p>
      <ul className="list-disc ml-3">
        <li>Las apuestas repetidas no cuentan para el rollover (Solo cuenta la primera apuesta)</li>
        <li>Apuestas con cuota mínima, igual o superior a 2.00.</li>
        <li>Los mercados de hándicap over/under de (goles/corners/tarjetas) no cuentan.</li>
        <li>Dispones de 7 días desde que se asigna el bono para completar su rollover.</li>
        <li>El bono tiene unas ganancias máximas de 2 veces su valor.</li>
      </ul>
      <p>Una vez descritas las condiciones generales de los bonos, pasamos con algunas recomendaciones.</p>
      <ul className="list-disc ml-3">
        <li>Recomendable apostar a mercados 1X2, mayor liquidez y se evitan problemas de futuras limitaciones.</li>
        <li>Fijarse en apuestas Dutching con casas como Marathonbet o Betfred que suelen tener las mejores cuotas.</li>
        <li>Aprovechar otras promociones para desbloquear el bono con una pérdida menor</li>
        <li>Comprobar el Oddsmatcher con buenos ratings para buscar con dutching.</li>
      </ul>
      <p>
        En este primer video hablamos de las condiciones y de como puedes desbloquearlo. Ya que dependiendo del saldo
        que tengas en dicha casa de apuestas puede que sea más rentable usar una estrategia u otra.
      </p>
      <iframe
        src="//www.youtube.com/embed/yfTYYxKvJZQ"
        allowFullScreen="allowfullscreen"
        width="560"
        height="314"
        className="my-10 w-full lg:w-1/2"
      />
      <p>
        En este segundo vídeo, Marc te muestra como encontrar ratings manualmente y como anotar las pérdidas del
        desbloqueo del bono en tu hoja de registro.
      </p>
      <iframe
        src="//www.youtube.com/embed/iDXQxCZVXUk"
        allowFullScreen="allowfullscreen"
        width="560"
        height="314"
        className="my-10 w-full lg:w-1/2"
      />
    </section>
  );
}
