import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowLeftL, IconCheckCircle, SubscriptionEuro } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import IconCloseCircle from '@vilibets/vilibets-uikit/dist/atoms/Icons/IconCloseCircle';
import LogoVilibets from '@vilibets/vilibets-uikit/dist/atoms/Icons/LogoVilibets';
import { GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getKPIBonds, getKPIProfitTracker } from '../../services/bonds';
import { useOrders } from '../../services/orders';
import getSubscriptions from '../../services/subscriptions';
import { generateAffiliateToken, getReferredUsers, getUser } from '../../services/users';
import UserOrderRefundPopup from './UserOrderRefundPopup';

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const response = await getUser(id);
        setUser(response);
      }
    }
    fetchData();
  }, []);

  const [subscriptions, setSubscriptions] = useState(null);
  const [orders, setOrders] = useState(null);
  const [referredUsers, setReferredUsers] = useState([]);
  const [kpiBonds, setKpiBonds] = useState([]);
  const [kpiProfitTracker, setKpiProfitTracker] = useState([]);
  const [displayOrderRefundPopup, setDisplayOrderRefundPopup] = useState(false);
  const [orderToRefund, setOrderToRefund] = useState(null);

  const { data } = useOrders(user?.id);

  useEffect(() => {
    if (data) {
      setOrders(data);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getSubscriptions(user.id);
        setSubscriptions(response);
        const referredUsers = await getReferredUsers(user);
        setReferredUsers(referredUsers);
        const kpiBonds = await getKPIBonds(user.id);
        setKpiBonds(kpiBonds);
        const kpiProfitTracker = await getKPIProfitTracker(user.id);
        setKpiProfitTracker(kpiProfitTracker);
      }
      fetchData();
    }
  }, [user]);

  return (
    user && (
      <div style={{ marginBottom: '100px' }}>
        <div className="flex flex-row child:w-1/2 intermedium:child:w-auto justify-between items-start">
          <h2 className="font-bold text-2xl text-black-12 mb-5 dark:text-accent-dark">{'Usuario'}</h2>
          <Button
            type="secondary"
            size="medium"
            action={() => navigate(-1)}
            icon={
              <Icon className="stroke-white text-2xl">
                <ArrowLeftL />
              </Icon>
            }>
            Volver a la tabla
          </Button>
        </div>
        <section id="user-detail" className="flex flex-col-reverse lg:flex-row">
          <section id="user-content" className="w-full lg:grow lg:mr-5">
            <section id="subscriptions" className="flex flex-col-reverse lg:flex-row w-full lg:w-auto">
              <section
                id="invoices"
                className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col lg:mr-5 mb-4 max-h-[337px] overflow-y-scroll">
                <h1 className="font-bold dark:text-accent-dark text-2xl text-black-12 mb-3">
                  Historial de facturación
                </h1>
                <table>
                  <thead className="border-b border-black-4">
                    <tr className="text-left child:py-5 text-sm dark:text-accent-dark">
                      <th>Fecha de inicio</th>
                      <th>Fecha de fin</th>
                      <th>Método</th>
                      <th>Id de pago</th>
                      <th>Estado</th>
                      <th>Precio</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {!orders ? (
                      <tr>
                        <td className="pt-5 text-lg font-bold dark:text-accent-dark" colSpan={5}>
                          No tiene facturas
                        </td>
                      </tr>
                    ) : Object.keys(orders).length > 0 ? (
                      orders.map((invoice, index) => (
                        <tr key={index} className="border-t border-black-4 child:py-5 text-sm dark:text-accent-dark">
                          <td>
                            {/* format the date to day/month/year hour:minuts */}
                            {moment(invoice.start_date).format('DD/MM/YYYY HH:mm:ss')}
                          </td>
                          <td>{moment(invoice.end_date).format('DD/MM/YYYY HH:mm:ss')}</td>
                          <td>
                            {/* format the date to day/month/year hour:minuts */}
                            {invoice.payment_method.payment_name === 'affiliates' ? (
                              <LogoVilibets height="50px" width="50px" className={'w-15'} />
                            ) : (
                              invoice.payment_method.payment_name !== 'others' && (
                                <img
                                  height="50px"
                                  width="50px"
                                  src={
                                    invoice.payment_method.payment_name === 'stripe'
                                      ? './stripe_logo.png'
                                      : invoice.payment_method.payment_name === 'paypal'
                                      ? './paypal_logo.png'
                                      : invoice.payment_method.payment_name === 'transfer'
                                      ? './transfer_logo.png'
                                      : ''
                                  }
                                />
                              )
                            )}
                          </td>
                          <td>
                            {/* format the date to day/month/year hour:minuts */}
                            {invoice.payment_method.payment_name === 'stripe'
                              ? invoice.subscription.stripe_id
                              : invoice.payment_method.payment_name === 'paypal'
                              ? invoice.subscription.paypal_id
                              : invoice.payment_method.payment_name !== 'affiliates'
                              ? invoice.payment_method.description
                              : user.affiliate_token}
                          </td>
                          {invoice.refunded ? (
                            <td className="text-warning-red font-bold leading-6">{'Reembolsado'}</td>
                          ) : invoice.charged ? (
                            <td className="text-secondary font-bold leading-6">{'Pagado'}</td>
                          ) : (
                            <td className="text-warning-red font-bold leading-6">{'Pendiente'}</td>
                          )}
                          <td className="font-bold">{parseFloat(invoice.total).toFixed(2) + '€'}</td>
                          <td title="Reembolsar pago">
                            <button
                              onClick={() => {
                                setOrderToRefund(invoice);
                                setDisplayOrderRefundPopup(true);
                              }}
                              className="mr-2">
                              <Icon
                                className={`${
                                  !invoice.refunded ? 'stroke-warning-red font-bold' : 'stroke-black'
                                } text-2xl cursor-pointer`}>
                                <SubscriptionEuro />
                              </Icon>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="pt-5 text-lg font-bold dark:text-accent-dark" colSpan={5}>
                          No tiene facturas
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
              <section className="flex flex-col">
                <section
                  id="subscription"
                  className={`w-full lg:w-auto bg-white flex rounded-2xl shadow p-5 flex-col mb-4`}>
                  <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">
                    Estado de subscripción
                  </h1>
                  <section className="flex flex-row gap-2 items-center">
                    <Icon
                      className={`text-2xl ${
                        subscriptions
                          ? Object.keys(subscriptions).length > 0
                            ? subscriptions.expired
                              ? 'stroke-warning-red'
                              : 'stroke-secondary'
                            : 'stroke-warning-red'
                          : 'stroke-warning-red'
                      }`}>
                      {subscriptions ? (
                        Object.keys(subscriptions).length > 0 ? (
                          subscriptions.expired ? (
                            <IconCloseCircle />
                          ) : (
                            <IconCheckCircle />
                          )
                        ) : (
                          <IconCloseCircle />
                        )
                      ) : (
                        <IconCloseCircle />
                      )}
                    </Icon>
                    <p
                      className={`text-sm text-black-12 font-bold dark:text-accent-dark ${
                        subscriptions
                          ? Object.keys(subscriptions).length > 0
                            ? subscriptions.expired
                              ? 'stroke-warning-red'
                              : 'stroke-secondary'
                            : 'stroke-warning-red'
                          : 'stroke-warning-red'
                      }`}>
                      {subscriptions
                        ? Object.keys(subscriptions).length > 0
                          ? subscriptions.expired
                            ? 'No tiene subscripción'
                            : 'Subscripción activa'
                          : 'No tiene subscripción'
                        : 'No tiene subscripción'}
                    </p>
                  </section>
                  {subscriptions && !subscriptions.expired && subscriptions.rate && (
                    <section>
                      <p className="font-bold dark:text-accent-dark mt-2.5 text-black-10 text-xs">
                        (Subscripción de {subscriptions.rate.title})
                      </p>
                      <p className="font-bold dark:text-accent-dark text-black-10 text-xs">
                        Finaliza {moment(subscriptions.end_date).fromNow()}
                      </p>
                    </section>
                  )}
                </section>
                <section
                  id="affiliate_counter"
                  className={`w-full lg:w-auto bg-white flex rounded-2xl shadow p-5 flex-col mb-4`}>
                  <h1 className="font-bold dark:text-accent-dark text-2xl text-black-12 mb-3">
                    Ciclos de facturación por afiliados
                  </h1>
                  <section className="flex flex-row gap-2 items-center">
                    <p className="font-bold text-black-10 dark:text-accent-dark">
                      {subscriptions ? (subscriptions.affiliate_token ? subscriptions.affiliate_token : 0) : 0} ciclos
                    </p>
                  </section>
                </section>
              </section>
            </section>
            <section id="referred-users" className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
              <section
                id="referred-users-list"
                className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
                <section className="flex flex-col lg:flex-row justify-between items-start">
                  <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">Usuarios afiliados</h1>
                  <span
                    className="cursor-pointer text-white bg-primary font-bold px-3 rounded-lg"
                    onClick={async () => {
                      if (!user.affiliate_token) {
                        const token = await generateAffiliateToken(user);
                        navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${token}`);
                      } else {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${user.affiliate_token}`
                        );
                      }
                      toast.success('¡El código de invitación ha sido copiado en el portapapeles!');
                    }}>
                    Copiar link de afiliados
                  </span>
                </section>
                <table>
                  <thead className="border-b border-black-4">
                    <tr className="text-left child:py-5 text-sm dark:text-accent-dark">
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Fecha de registro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(referredUsers).length === 0 && (
                      <tr>
                        <td className="pt-5 text-lg font-bold dark:text-accent-dark" colSpan={3}>
                          No tiene usuarios referidos
                        </td>
                      </tr>
                    )}
                    {Object.keys(referredUsers) &&
                      referredUsers.map((user, index) => (
                        <tr key={index} className="border-t border-black-4 child:py-5 text-sm dark:text-accent-dark">
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </section>
            </section>
          </section>
          <section id="user-card" className="w-full lg:!w-[300px] lg:!min-w-[300px] mb-4">
            <div className="bg-white rounded-2xl shadow p-3 flex flex-col">
              <img className="h-20 object-contain" src="https://cdn-icons-png.flaticon.com/512/194/194938.png" />
              <h2 className="text-black-12 font-bold text-base text-center mt-3 dark:text-accent-dark">
                {user?.name} {user?.surnames}
              </h2>
              <h3 className="text-black-8 text-base text-center mb-3">({user?.username})</h3>
              <section id="card-info" className="px-2 dark:text-normal-dark">
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Email
                  </span>
                  {user?.email}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Identificador de Telegram
                  </span>
                  {user?.telegram_id}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Nombre de telegram
                  </span>
                  {user?.telegram_name ?? 'No tiene nombre configurado'}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Nombre de usuario de telegram
                  </span>
                  {user?.telegram_username ?? 'No tiene nombre de usuario configurado'}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Identificador de Stripe
                  </span>
                  {user?.stripe_id != null ? user?.stripe_id : 'No tiene identificador'}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Identificador de Paypal
                  </span>
                  {user?.subscription?.paypal_id != null ? user?.subscription?.paypal_id : 'No tiene identificador'}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Se unió
                  </span>
                  {moment(user?.created_at).fromNow()}
                </p>
              </section>
            </div>
          </section>
        </section>
        {kpiBonds && (
          <>
            <h2 className="font-bold text-2xl text-black-12 mb-3 mt-5 dark:text-accent-dark">Bonos en el último mes</h2>
            <section id="kpi-bonds" className="flex flex-1 flex-col lg:flex-row w-full gap-4 child:w-full">
              <GraphicCardNumber
                numberClass="text-3xl font-bold dark:text-accent-dark"
                title="Bonos creados"
                percentageNumber={kpiBonds?.createdBondsPercentage}
                sufix=""
                number={kpiBonds?.createdBonds?.totalBonds}
                graphData={kpiBonds?.createdBonds?.countCreatedBondsEveryDay?.bonds}
                widthGraph="96"
                heightGraph="48"
                cardColor="white"
              />
              <GraphicCardNumber
                numberClass="text-3xl font-bold dark:text-accent-dark"
                title="Bonos utilizados"
                percentageNumber={kpiBonds?.completedBondsPercentage}
                sufix=""
                number={kpiBonds?.bondsCompleted?.totalBonds}
                graphData={kpiBonds?.bondsCompleted?.countCompletedBondsEveryDay?.bonds}
                widthGraph="96"
                heightGraph="48"
                cardColor="white"
              />
            </section>
          </>
        )}
        {kpiProfitTracker && (
          <>
            <h2 className="font-bold text-2xl text-black-12 mb-3 mt-5 dark:text-accent-dark">
              ProfitTracker en el último mes
            </h2>
            <section id="kpi-bonds" className="flex flex-1 flex-col lg:flex-row w-full gap-4 child:w-full">
              <GraphicCardNumber
                className="min-h-[140px]"
                title="Pérdidas y beneficios"
                number={kpiProfitTracker?.profitAndLossComparativePercentage?.sumProfit || 0}
                percentageNumber={
                  kpiProfitTracker?.profitAndLossComparativePercentage?.profitCalcPercentage.toString() || '0'
                }
                graphData={kpiProfitTracker?.profitAndLossComparativePercentage?.sumStakeEveryDay?.stake}
                widthGraph="60"
                heightGraph="50"
                numberClass="text-3xl font-bold dark:text-accent-dark"
              />
              {/* Pérdidas y beneficios totales (número + % comparativo) (Profit tracker) */}
              <GraphicCardNumber
                className="min-h-[140px]"
                title="Pérdidas y beneficios totales"
                number={kpiProfitTracker?.totalProfitAndLoss?.totalSumStake || 0}
                numberClass="text-3xl font-bold dark:text-accent-dark"
              />
            </section>
          </>
        )}
        <UserOrderRefundPopup
          display={displayOrderRefundPopup}
          closePopup={async () => {
            setDisplayOrderRefundPopup(!displayOrderRefundPopup);
            // refresh();
          }}
          user={user}
          order={orderToRefund}
        />
      </div>
    )
  );
};

export default UserDetail;
