import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Euro } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { Card } from '@vilibets/vilibets-uikit/dist/atoms/Card';
import PopupSubscription from '../../components/PopupSubscription/PopupSubscription';
import { useUser } from '../../context/UserContext';
import {
  generateAffiliateToken,
  getReferredUsers,
  getAffiliateTransactions,
  getPaymentsReceived,
} from '../../services/users';
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ButtonCopy } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';

const ManageAffiliates = () => {
  const {
    state: { user },
  } = useUser();
  const kNumFilasVisibles = 5;
  const [users, setUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [stripeConf, setStripeConf] = useState(null);
  const [affiliateTransactions, setAffiliateTransactions] = useState([]);
  const [paymentsReceived, setPaymentsReceived] = useState([]);
  const [affiliateToken, setAffiliateToken] = useState(user.affiliate_token);
  const [verAfiliados, setVerAfiliados] = useState(false);
  const [verTransacciones, setVerTransacciones] = useState(false);

  useEffect(() => {
    setStripeConf(loadStripe(process.env.REACT_APP_STRIPE_KEY, {}));

    async function fetchData() {
      const response = await getReferredUsers(user);
      setUsers(response);
      const paymentsReceived = await getPaymentsReceived(user);
      setPaymentsReceived(paymentsReceived);
      const affiliateTransactions = await getAffiliateTransactions(user);
      setAffiliateTransactions(affiliateTransactions);
      if (!user.affiliate_token) {
        const token = await generateAffiliateToken(user);
        setAffiliateToken(token);
      }
    }

    fetchData();
  }, []);

  return (
    users && (
      <>
        <section className="flex flex-row justify-between mb-5">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Afiliados</h1>
          {user.role !== 'influencer' && (
            <Button
              type="secondary"
              size="small"
              action={() => {
                setShowPopup(true);
              }}
              icon={
                <Icon className="stroke-white text-2xl">
                  <Euro />
                </Icon>
              }>
              Pagar subscripción con créditos
            </Button>
          )}
        </section>
        <Card title="Enlace de afiliados" className="bg-white mb-7">
          <p className="mb-2 dark:text-accent-dark">Comparte este enlace con tus amigos para recibir recompensas</p>
          <ButtonCopy textCopy={`${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${affiliateToken}`} />
        </Card>
        <section className="grid grid-cols-1 justify-between lg:grid-cols-2 gap-6 mb-7">
          <GraphicCardNumber
            numberClass="text-3xl font-bold dark:text-accent-dark"
            title="Número de Afiliados"
            percentageNumber={null}
            sufix=""
            number={users.length}
            graphData={null}
            widthGraph="96"
            heightGraph="48"
            cardColor="white"
          />
          <GraphicCardNumber
            numberClass="text-3xl font-bold"
            title={user.role === 'influencer' ? 'Total generado' : 'Créditos disponible'}
            percentageNumber={null}
            sufix={user.role === 'influencer' ? '€' : 'créditos'}
            number={user.affiliate_credits}
            graphData={null}
            widthGraph="96"
            heightGraph="48"
            cardColor="white"
          />
        </section>
        <Elements stripe={stripeConf}>
          <PopupSubscription
            display={showPopup}
            credits={user.affiliate_credits}
            closePopup={() => {
              setShowPopup(false);
            }}
          />
        </Elements>
        {Object.keys(paymentsReceived).length > 0 && (
          <section id="payments-received" className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
            <section
              id="payments-received-list"
              className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
              <section className="flex flex-col lg:flex-row justify-between items-start">
                <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">Pagos recibidos</h1>
              </section>
              <table>
                <thead className="border-b border-black-4">
                  <tr className="text-left child:py-3 text-sm">
                    <th>Fecha del pago</th>
                    <th>Importe</th>
                    <th>Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(paymentsReceived) &&
                    paymentsReceived.map((order, index) => (
                      <tr
                        key={index}
                        className="border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600">
                        <td>{moment(order.start_date).format('DD/MM/YYYY')}</td>
                        <td className="text-secondary font-bold">
                          {order.total.toLocaleString('es-ES', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          €
                        </td>
                        <td>{order.comment}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
          </section>
        )}
        <section id="referred-users" className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
          <section
            id="referred-users-list"
            className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
            <section className="flex flex-col lg:flex-row justify-between items-start">
              <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">Usuarios afiliados</h1>
            </section>
            <table>
              <thead className="border-b border-black-4">
                <tr className="text-left child:py-3 text-sm">
                  <th>Nombre</th>
                  <th>Nombre de usuario</th>
                  <th>Miembro desde</th>
                  <th>{user.role === 'influencer' ? 'Generado' : 'Créditos generados'}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(users).length === 0 && (
                  <tr>
                    <td className="pt-3" colSpan={3}>
                      No tiene usuarios referidos
                    </td>
                  </tr>
                )}
                {Object.keys(users) &&
                  users.map((user, index) => (
                    <tr
                      key={index}
                      className={`${
                        index >= kNumFilasVisibles && !verAfiliados ? 'hidden' : ''
                      } border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600`}>
                      <td>{user.name + ' ' + user.surnames}</td>
                      <td>{user.username}</td>
                      <td>{moment(user.created_at).fromNow()}</td>
                      <td className="font-bold text-secondary">
                        {parseFloat(
                          user.affiliated_credits_generated != null ? user.affiliated_credits_generated : 0
                        ).toLocaleString('es-ES', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        €
                      </td>
                    </tr>
                  ))}
                {Object.keys(users).length > kNumFilasVisibles && !verAfiliados && (
                  <tr>
                    <td className="text-right" colSpan={4}>
                      <a
                        href=""
                        className="text-primary font-bold"
                        onClick={(e) => {
                          e.preventDefault();
                          setVerAfiliados(true);
                        }}>
                        + Ver todos
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </section>
        <section
          id="affiliate-transactions"
          className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start mb-20">
          <section
            id="affiliate-transactions-list"
            className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
            <section className="flex flex-col lg:flex-row justify-between items-start">
              <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">
                Transacciones de afiliados
              </h1>
            </section>
            <table>
              <thead className="border-b border-black-4">
                <tr className="text-left child:py-3 text-sm">
                  <th>Usuario</th>
                  <th>Fecha</th>
                  <th>Pagado</th>
                  <th>Ganado</th>
                  <th>Porcentaje</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {Object.keys(affiliateTransactions).length === 0 && (
                  <tr>
                    <td className="pt-3 text-lg font-bold" colSpan={3}>
                      No tiene transacciones de afiliados
                    </td>
                  </tr>
                )}
                {Object.keys(affiliateTransactions) &&
                  affiliateTransactions.map((transaction, index) => (
                    <tr
                      key={index}
                      className={`${
                        index >= kNumFilasVisibles && !verTransacciones ? 'hidden' : ''
                      } border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600`}>
                      <td>
                        {transaction.user.name} {transaction.user.surnames}
                      </td>
                      <td>{moment(transaction.created_at).format('DD/MM/YYYY')}</td>
                      <td>
                        {parseFloat(transaction.amount).toLocaleString('es-ES', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        €
                      </td>
                      <td className={`${transaction.refunded ? 'text-warning-red' : 'text-secondary'} font-bold`}>
                        {parseFloat(transaction.credit).toLocaleString('es-ES', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        €
                      </td>
                      <td>
                        {parseFloat(transaction.percentage).toLocaleString('es-ES', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        %
                      </td>
                      <td>
                        {transaction.refunded ? <span className="font-bold text-warning-red">Reembolsado</span> : ''}
                      </td>
                    </tr>
                  ))}
                {Object.keys(affiliateTransactions).length > kNumFilasVisibles && !verTransacciones && (
                  <tr>
                    <td className="text-right" colSpan={6}>
                      <a
                        href=""
                        className="text-primary font-bold"
                        onClick={(e) => {
                          e.preventDefault();
                          setVerTransacciones(true);
                        }}>
                        + Ver todas
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </section>
      </>
    )
  );
};

export default ManageAffiliates;
