import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ExpiredPopupSubscription = ({ display, children }) => {
  const { logout } = useAuth({});
  const navigate = useNavigate();
  const routeChange = () => {
    navigate('/subscriptions/my-subscription');
  };

  useEffect(() => {
    const main = document.getElementsByTagName('main')[0];
    if (main != null) {
      if (display) {
        main.style.pointerEvents = 'none';
      } else {
        main.style.pointerEvents = 'all';
      }
    }
  }, [display]);

  return (
    <>
      <Popup display={display} width={'sm:min-w-[380px] md:min-w-[380px] w-[380px] min-w-[380px]'}>
        <section className="p-5 pb-6 flex-auto pointer-events-auto">
          <h3 className="text-2xl font-semibold text-center mb-3 dark:text-accent-dark">
            No tienes suscripción activa
          </h3>
          <p className="text-center mb-3 dark:text-accent-dark">
            Si quieres tener todas las funcionalidades, suscribete!
          </p>
          <div className="flex justify-between">
            <Button action={logout}>👋 Logout</Button>
            <Button action={routeChange}>Renovar subscripción</Button>
          </div>
        </section>
      </Popup>
      {children}
    </>
  );
};

export default ExpiredPopupSubscription;
