import { Helmet } from 'react-helmet';

const OddsMatcher2 = () => {
  return (
    <>
      <Helmet>
        <script src="https://widgets.scrapebet.com/static/oddsmatcher.js" type="text/javascript" />
      </Helmet>
      <div
        id="sbet_widget"
        data-calculator-url=""
        data-elements-per-page="20"
        data-type="oddsmatcher"
        data-font="Inter"
        data-client="6453cb1d76093ae7fd0ba752"
        data-style="2"
      />
    </>
  );
};

export default OddsMatcher2;
