import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { refundOrder } from '../../services/orders';

const UserOrderRefundPopup = ({ display, closePopup, user, order }) => {
  const [comment, setComment] = useState(order?.comment);

  return (
    user &&
    order && (
      <Popup display={display} width={'md:min-w-[40%]'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <section className="flex flex-row items-end">
            <h3 className="text-xl font-semibold dark:text-accent-dark">Ingreso de {user?.name}</h3>
            <section className="px-3 flex-auto mb-1.5">
              {order.created_at && (
                <div className="flex flex-row">
                  <h3 className="w-fit text-[10px] font-semibold dark:text-accent-dark bg-secondary-4 dark:bg-primary-2 rounded-md px-1.5">
                    {moment.utc(order.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  </h3>
                </div>
              )}
            </section>
          </section>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => closePopup()}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        <section className="grid grid-cols-2 gap-4 pl-5 pr-5">
          <section className="dark:text-normal-dark mb-2">
            <p className="text-base mb-3 flex flex-col">
              <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                Email
              </span>
              {user?.email}
            </p>
            <p className="text-base mb-3 flex flex-col">
              <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                Inicio - Fin
              </span>
              {moment.utc(order?.start_date).format('DD/MM/YYYY HH:mm:ss')} -{' '}
              {moment.utc(order?.end_date).format('DD/MM/YYYY HH:mm:ss')}
            </p>
          </section>
          <section className="dark:text-normal-dark mb-2">
            <p className="text-base mb-3 flex flex-col">
              <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                Método de pago
              </span>
              {order?.payment_method?.description}
            </p>
            <p className="text-base mb-3 flex flex-col">
              <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                Importe
              </span>
              <strong>{parseFloat(order?.total).toFixed(2) + '€'}</strong>
            </p>
          </section>
        </section>
        <section className="dark:text-normal-dark mb-2 child:mb-3 pl-5 pr-5">
          <p className="text-base mb-3 flex flex-col">
            <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
              Motivo del reembolso
            </span>
            {order?.refunded ? (
              <strong>{order?.comment}</strong>
            ) : (
              <Input
                id="comment"
                width="w-full"
                placeholderMessage="Motivo"
                name="comment"
                value={order?.comment}
                onChange={(e) => setComment(e.target.value)}
                className="mt-1"
                disabled={order?.refunded}
              />
            )}
          </p>
        </section>
        <section className="child:mb-3 pl-5 pr-5">
          {user.joined_by_id && (
            <p htmlFor="warning_delete_order" className="flex flex-row text-sm text-warning-red">
              <Icon className="stroke-warning-red text-2xl">
                <InfoCircle />
              </Icon>
              <strong>
                &nbsp;Usuario referido por un usuario afiliado. Se descontarán créditos de afiliación a éste último.
              </strong>
            </p>
          )}
          {!order?.refunded && (
            <Button
              size={'medium'}
              action={async () => {
                const response = await refundOrder(order.id, comment);
                const message = response || 'Reembolso procesado correctamente.';
                toast.success(message, {
                  icon: '🤙',
                });
                order.refunded = true;
                order.comment = comment;
                closePopup();
              }}
              className="mx-auto mb-10"
              disabled={!comment}>
              Confirmar reembolso
            </Button>
          )}
        </section>
      </Popup>
    )
  );
};

export default UserOrderRefundPopup;
