import { Graphic, GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { worker } from './constants/worker';

const DashboardWorker = ({ dashboard }) => {
  const {
    series_bounds_populars,
    options_bounds_populars,
    type_bounds_populars,
    series,
    options,
    type_bar,
    series_incidents_betting_house,
    options_incidents_betting_house,
    series_bonds_created_for_betting_house,
    options_bonds_created_for_betting_house,
  } = worker(dashboard);

  return (
    <>
      <section className="grid grid-cols-1 justify-between md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {/* Incidencias (número + % comparativo) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Incidencias"
          number={dashboard?.incidents_comparative_percentage?.total_incident || 0}
          percentageNumber={dashboard?.incidents_comparative_percentage?.total_incident_percentage.toString() || '0'}
          graphData={dashboard?.incidents_comparative_percentage?.every_day_of_the_sum_of_incidents?.incident}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix=""
        />
        {/* Bonos creados (numero + % comparativo) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Bonos creados"
          number={dashboard?.bonds_created_for_period?.bonds || 0}
          percentageNumber={dashboard?.bonds_created_for_period?.bonds_percentage.toString() || '0'}
          graphData={dashboard?.bonds_created_for_period?.every_day_the_count_of_bonds?.bonds}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix=""
        />
        {/* Bonos utilizados de los clientes  (numero + % comparativo) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Bonos creados"
          number={dashboard?.completed_bonds_count_user_role_worker?.bonds_completed_worker || 0}
          percentageNumber={
            dashboard?.completed_bonds_count_user_role_worker?.completed_bonds_percentage.toString() || '0'
          }
          graphData={
            dashboard?.completed_bonds_count_user_role_worker?.every_day_the_count_of_completed_bonds?.completedBonds
          }
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
          sufix=""
        />
      </section>
      <section className="mt-6 mb-20 grid grid-cols-1 justify-between md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-6">
        {/* Incidencias por casa de apuesta */}
        <Graphic
          title="Incidencias por casa de apuesta"
          series={series_incidents_betting_house}
          options={options_incidents_betting_house}
          type={type_bar}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* Casas de apuestas */}
        <Graphic
          title="Bonos creados por casas de apuestas"
          series={series_bonds_created_for_betting_house}
          options={options_bonds_created_for_betting_house}
          type={type_bar}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* Comparativa pérdidas y beneficios de los clientes */}
        <Graphic
          title="Pérdidas y beneficios de los clientes"
          series={series}
          options={options}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* Bonos populares (gráfico pastel) */}
        <Graphic
          title="Bonos populares"
          series={series_bounds_populars}
          options={options_bounds_populars}
          type={type_bounds_populars}
          className="bg-white"
          height="350px"
          width="100%"
        />
      </section>
    </>
  );
};

export default DashboardWorker;
