import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { DropZone } from '@vilibets/vilibets-uikit/dist/molecules/DropZone/DropZone';
import { toast } from 'react-toastify';
import { createBookmarker, updateBookmarker } from '../../services/bookmarker';

const CreditBookmarkerPopup = ({ display, closePopup, data = null }) => {
  const [fields, setField] = useState(
    data === null
      ? {
          title: '',
          url: '',
          logo_url: null,
        }
      : data
  );

  useEffect(() => {
    if (data !== null) {
      setField(data);
    }
  }, [data]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data === null) {
      await createBookmarker(fields);
      toast.success('Casa de apuestas creada', {
        icon: '👌',
      });
    } else {
      await updateBookmarker(data.id, fields);
      toast.success('Casa de apuestas actualizada', {
        icon: '👌',
      });
    }
    setField({
      title: '',
      url: '',
      logo_url: null,
    });
    closePopup();
  };

  return (
    <>
      <Popup display={display} width={'md:w-6/12 md:min-w-6/12'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <h3 className="text-xl font-semibold dark:text-accent-dark">
            {data === null ? 'Añadir' : 'Editar'} casa de apuestas
          </h3>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                closePopup();
                setField({
                  title: '',
                  url: '',
                  logo_url: null,
                });
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {/* body */}
        <form
          onSubmit={handleSubmit}
          className="relative px-6 flex-auto bg-white flex flex-wrap child:mb-4 rounded-b-xl">
          <div className="w-full xl:w-8/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="title">
              Nombre
            </label>
            <Input
              id="title"
              width="w-full"
              placeholderMessage={'Bet365'}
              autoFocus
              name="title"
              value={fields.title}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-6/12 pr-4 xl:pr-0 xl:w-4/12">
            <label className="dark:text-normal-dark" htmlFor="url">
              Url
            </label>
            <Input
              id="url"
              width="w-full"
              placeholderMessage={'www.bet365.es'}
              name="url"
              value={fields.url}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div id="manageBookmarker" className="w-full xl:w-full">
            <section className="flex flex-row items-center">
              <label className="dark:text-normal-dark" htmlFor="logo_url">
                Logo
              </label>
              {data ? data.logo_url ? <img src={data.logo_url} className="h-10 w-auto" /> : '' : ''}
            </section>
            <div className="h-1.5" />
            <DropZone
              onDrop={(acceptedFiles) => {
                setField((values) => ({ ...values, logo_url: acceptedFiles[0] }));
              }}
            />
          </div>
          <div className="flex items-center justify-start py-3 rounded-b w-full">
            <Button
              className="mr-2"
              size={'large'}
              typeButton="submit"
              disabled={fields.title === '' || fields.url === ''}>
              Enviar
            </Button>
          </div>
        </form>
        {/* footer */}
      </Popup>
    </>
  );
};

export default CreditBookmarkerPopup;
