import { RefoundCalculator } from '@vilibets/vilibets-uikit/dist/molecules/Calculators/Calculators';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Calculators from './Calculators';

const FFRefoundCalc = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // get searchParams and set it to initialParams
  const initialParams = () => {
    let params = {};
    for (const [key, value] of searchParams) {
      params = { ...params, [key]: value };
    }
    if (!('retention' in params)) {
      params.retention = '75.00';
    }
    return params;
  };

  useEffect(() => {
    const d = document.querySelector('[title="Retención del Importe de Reembolso"]');
    d.style.textAlign = 'right';
  });
  return (
    <Calculators>
      <RefoundCalculator
        initialParams={initialParams}
        onChange={(params) => {
          setSearchParams(params);
        }}
      />
    </Calculators>
  );
};

export default FFRefoundCalc;
