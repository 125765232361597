import { FlexCheckBoxList } from '@vilibets/vilibets-uikit/dist/molecules/CheckBoxList/CheckBoxList';
import { useEffect, useState } from 'react';
import { getManagementBookmarkers } from '../../services/bookmarker';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { unselectUserBookmarer } from '../../services/users';
import { useUser } from '../../context/UserContext';
import { toast } from 'react-toastify';

const ManageBookmarkers = () => {
  const {
    state: { user },
  } = useUser();
  const [displayingBookmarkers, setDisplayingBookmarkers] = useState(null);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getManagementBookmarkers(user.id);
        const bookmarkers = [];
        response.bookmarkers.forEach((element) => {
          bookmarkers.push({
            id: element.id,
            name: element.title.charAt(0).toUpperCase() + element.title.slice(1),
            defaultChecked: !response.not_seleceted_bookmarkers.includes(element.id),
            isDisabled: false,
            hasError: false,
            image: element.logo_url,
            label: element.title.charAt(0).toUpperCase() + element.title.slice(1),
          });
        });
        setDisplayingBookmarkers(bookmarkers);
      }
      fetchData();
    }
  }, []);

  return (
    displayingBookmarkers && (
      <article className="mb-20">
        <section className="flex flex-col lg:flex-row justify-between mb-7">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Gestión de casas de apuestas</h1>
        </section>
        <section>
          <FlexCheckBoxList
            label="Todo / Nada"
            items={displayingBookmarkers}
            action={setDisplayingBookmarkers}
            checkboxClassName={'dark:bg-[#f4f7fe]'}
          />
        </section>
        <Button
          className="ml-2 mt-5"
          size={'large'}
          action={async () => {
            await unselectUserBookmarer(user.id, displayingBookmarkers);
            toast.success('Se ha actualizado correctamente');
          }}>
          Guardar
        </Button>
      </article>
    )
  );
};

export default ManageBookmarkers;
