import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { EditText, ExternalLink, IconUser, Trash } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import { deleteUser, getInfluencers } from '../../services/users';
import CreditInfluencerPopup from './CreditInfluencerPopup';

const Influencers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [influencers, setInfluencers] = useState([]);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [displayCreditPopup, setDisplayCreditPopup] = useState(false);
  const [editableRowData, setEditableRowData] = useState(null);

  function changeOrder(column, sortedSide) {
    setSearchParams({ orderby: column, orderby_direction: sortedSide });
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getInfluencers(window.location.search);
      setInfluencers(response);
    }
    fetchData();
  }, [searchParams]);

  return (
    influencers && (
      <>
        <section className="flex flex-row justify-between mb-7">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Influencers</h1>
          <Button
            type="secondary"
            size="small"
            action={() => setDisplayCreditPopup(true)}
            icon={
              <Icon className="stroke-white text-2xl">
                <IconUser />
              </Icon>
            }>
            Crear influencer
          </Button>
        </section>
        <Table
          tableClass="w-full hidden lg:table mb-20"
          changeOrder={(e, c) => changeOrder(e, c)}
          rowsData={prepareTableData()}
          colsData={[
            {
              Header: 'Nombre',
              accessor: 'name',
              sortable: true,
            },
            {
              Header: 'Nombre de usuario',
              accessor: 'username',
              sortable: true,
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: true,
            },
            {
              Header: 'Créditos',
              accessor: 'affiliate_credits',
              sortable: true,
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
            },
          ]}
        />
        <section className="lg:hidden">
          {influencers &&
            influencers.map((element, index) => (
              <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
                <div className="flex flex-col text-center mb-3">
                  <p className="text-black-12 font-bold">{element.name + ' ' + element.surnames}</p>
                  <p className="text-black-10 text-sm ">{element.username}</p>
                </div>
                <div className="flex flex-col child:mb-3">
                  <section>
                    <span className="text-black-10 text-sm bg-black-3 px-1.5 py-0 rounded">Email</span>
                    <p className="text-black-12 font-bold">{element.email}</p>
                  </section>
                  <section>
                    <span className="text-black-10 text-sm bg-black-3 px-1.5 py-0 rounded">Créditos</span>
                    <p className="text-black-12 font-bold break-words">{element.affiliate_credits}</p>
                  </section>
                </div>
                <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
              </div>
            ))}
        </section>
        <CreditInfluencerPopup
          display={displayCreditPopup}
          closePopup={async () => {
            setDisplayCreditPopup(!displayCreditPopup);
            setEditableRowData(null);
            const response = await getInfluencers(window.location.search);
            setInfluencers(response);
          }}
          data={editableRowData}
        />
        <PopupDelete
          display={displayDeletePopup}
          item={deleteItem}
          closePopup={() => {
            setDisplayDeletePopup(!displayDeletePopup);
          }}
          title={deleteItem.name}
          action_cancel={() => {
            setDisplayDeletePopup(false);
          }}
          action_delete={async () => {
            // delay of 500ms to reload the data
            await deleteUser(deleteItem.id);
            setDisplayDeletePopup(false);
            const response = await getInfluencers(window.location.search);
            setInfluencers(response);
          }}
        />
      </>
    )
  );

  function prepareTableData() {
    if (influencers) {
      const rowsData = [];
      influencers.forEach((element) => {
        rowsData.push({
          name: element.name + ' ' + element.surnames,
          username: element.username,
          email: element.email,
          affiliate_credits: element.affiliate_credits + ' €',
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  function generateActionButtons(element) {
    return (
      <section className="flex flex-row justify-around">
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
        <button
          onClick={() => {
            setEditableRowData(element);
            setDisplayCreditPopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
        <NavLink to={`/influencers/${element.id}`} className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-primary text-2xl">
            <ExternalLink />
          </Icon>
        </NavLink>
      </section>
    );
  }
};

export default Influencers;
