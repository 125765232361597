import { createContext, useContext, useReducer } from 'react';

const LoadingContext = createContext({
  loading: false,
});

function loadingReducer(state, action) {
  switch (action.type) {
    case 'isLoading': {
      return { loading: true };
    }
    case 'notLoading': {
      return { loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LoadingProvider({ children }) {
  const [state, dispatch] = useReducer(loadingReducer, { loading: false });
  const value = { state, dispatch };
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

function useLoading() {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
}

export { LoadingProvider, useLoading };
