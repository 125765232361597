import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Euro, Paypal, Stripe, SubscriptionEuro } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { LinearProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import PopupSubscription from '../../../components/PopupSubscription/PopupSubscription';
import { useAuth } from '../../../hooks/auth';
import { cancelSubscription, useSubscriptions } from '../../../services/subscriptions';
import Subscriptions from '../Subscriptions';

const MySubscription = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [subscription, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setopen] = useState(false);
  const [stripeConf, setStripeConf] = useState(null);

  const { data } = useSubscriptions(user?.id);

  useEffect(() => {
    if (data) {
      setSubscriptions(data);
    }
  }, [data]);

  useEffect(() => {
    setStripeConf(loadStripe(process.env.REACT_APP_STRIPE_KEY, {}));
  }, []);

  if (!subscription) {
    return <LinearProgress className="!fixed inset-x-0 top-0 !z-100" />;
  }

  const isSubscriptionActive = !subscription.expired && subscription.status === 'active';

  const suscriptionStatus = (status) => {
    switch (status) {
      case 'active':
        return <article className="text-sm text-warning-red-5">{'Tu suscripción ha caducado'}</article>;
      case 'pending_payment':
        return (
          <article className="text-sm text-warning-red-5">
            {'Suscripción pendiente de pago: Espere unos minutos a que confirmemos el pago.'}
          </article>
        );
      default:
        return <article className="text-sm text-warning-red-5">{'Has cancelado tu suscripción'}</article>;
    }
  };

  return (
    <Subscriptions>
      <h1 className="font-bold text-2xl text-black-12 mb-5 dark:text-accent-dark">Mi suscripción</h1>
      {!open && (
        <section className="flex flex-row items-end child:basis-1/3 mb-5">
          <article>
            <h3 className="text-black-10 text-sm">Fecha de inicio</h3>
            <p className="font-bold text-black-12 dark:text-accent-dark">
              {subscription.start_date &&
              subscription.status !== 'pending_payment' &&
              subscription.status !== 'requires_action'
                ? new Date(subscription.start_date).toLocaleDateString('es', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : '-'}
            </p>
          </article>
          <article>
            <h3 className="text-black-10 text-sm">Fecha de fin</h3>
            <p className="font-bold text-black-12 dark:text-accent-dark">
              {subscription.end_date &&
              subscription.status !== 'pending_payment' &&
              subscription.status !== 'requires_action'
                ? new Date(subscription.end_date).toLocaleDateString('es', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : '-'}
            </p>
          </article>
          {!subscription.end_date && (
            <article className="text-sm text-warning-red-5">{'No tienes suscripción activa'}</article>
          )}
          {subscription.end_date &&
            (isSubscriptionActive ? (
              <article className="text-sm text-secondary">{'Tu suscripción está activa'}</article>
            ) : (
              suscriptionStatus(subscription.status)
            ))}
        </section>
      )}
      {!open && (
        <section>
          {subscription.end_date && subscription.status !== 'pending_payment' ? (
            isSubscriptionActive ? (
              <Button
                size={'large'}
                action={async () => {
                  setLoading(true);
                  const new_subscription = await cancelSubscription(subscription.id);
                  setLoading(false);
                  setSubscriptions(new_subscription);
                }}
                icon={
                  <Icon className={'stroke-white text-2xl'}>
                    {subscription.paypal_id ? <Paypal /> : subscription.stripe_id ? <Stripe /> : <Euro />}
                  </Icon>
                }>
                Cancelar suscripción
              </Button>
            ) : (
              <Button
                size={'large'}
                action={async () => {
                  setopen(true);
                }}
                disabled={new Date(subscription.end_date) > new Date()}
                icon={
                  <Icon className={'stroke-white text-2xl'}>{subscription.paypal_id ? <Paypal /> : <Stripe />}</Icon>
                }>
                Renovar suscripción
              </Button>
            )
          ) : subscription.status === 'pending_payment' ? (
            <Button
              size={'large'}
              action={async () => {
                setopen(true);
              }}
              disabled={true}
              icon={
                <Icon className={'stroke-white text-2xl'}>
                  <SubscriptionEuro />
                </Icon>
              }>
              Cancelar suscripción
            </Button>
          ) : (
            <Button
              size={'large'}
              action={async () => {
                setopen(true);
              }}
              icon={
                <Icon className={'stroke-white text-2xl'}>
                  <SubscriptionEuro />
                </Icon>
              }>
              Activar suscripción
            </Button>
          )}
          {subscription && subscription.status === 'cancel' && new Date(subscription.end_date) > new Date() && (
            <p className="text-sm mt-3 text-black-7">Cuando finalice su suscripción podrá reactivarla...</p>
          )}
          {user.next_subscription != null && (
            <div className="text-end">
              <span className="text-black-10 text-xs">
                Próxima suscripción a partir del{' '}
                {new Date(user.next_subscription.end_date).toLocaleDateString('es', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </span>
            </div>
          )}
        </section>
      )}
      <Elements stripe={stripeConf}>
        <PopupSubscription
          display={open}
          closePopup={() => {
            setopen(!open);
          }}
        />
      </Elements>
      <section className={`transition-opacity ease-in duration-700 ${loading ? 'opacity-100' : 'hidden opacity-0'}`}>
        <div className="fixed top-0 left-0 right-0 bottom-0 z-[102] w-full h-screen overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
          <h2 className="text-center text-white text-xl font-semibold">Cargando...</h2>
          <p className="w-1/3 text-center text-white">
            Esto puede tardar unos minutos. Por favor no cierre la página. Gracias por la paciencia.
          </p>
        </div>
      </section>
    </Subscriptions>
  );
};

export default MySubscription;
