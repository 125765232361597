import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { IconCloseCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';

const PopupDelete = ({ display, closePopup, title, action_cancel, action_delete }) => {
  return (
    <Popup display={display} width={'sm:min-w-[400px] md:min-w-[400px] w-[400px] min-w-[400px]'}>
      <div className="flex items-start justify-between p-5 rounded-t">
        <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
          <span
            onClick={() => closePopup()}
            className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin absolute right-5 top-5">
            ×
          </span>
        </button>
      </div>
      <section className="px-5 pb-6 flex-auto">
        <Icon className="stroke-warning-red text-8xl flex justify-center mb-5">
          <IconCloseCircle />
        </Icon>
        <h3 className="text-2xl font-semibold dark:text-accent-dark text-center mb-3">¿Estás seguro?</h3>
        <p className="text-center dark:text-accent-dark">
          ¿Quieres borrar a <strong>"{title}"</strong>? Este proceso no se puede deshacer.
        </p>
        <section className="flex items-center justify-center mt-5 mb-10">
          <button
            onClick={() => action_cancel(false)}
            className="bg-black-7 px-6 py-1.5 rounded shadow font-bold text-white mr-3">
            Cancelar
          </button>
          <button
            onClick={() => {
              action_delete();
            }}
            className="bg-warning-red px-6 py-1.5 rounded shadow font-bold text-white">
            Eliminar
          </button>
        </section>
      </section>
    </Popup>
  );
};

export default PopupDelete;
