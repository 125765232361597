import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { IconLock } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Alert } from '@vilibets/vilibets-uikit/dist/atoms/Alert';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import Settings from '../Settings';
import { changePassword } from '../../../services/users';

const Password = () => {
  const hasErrorInput = false;
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [inputs, setInputs] = useState({
    new_password: '',
    repeat_new_password: '',
  });
  const [errorInput, setError] = useState(hasErrorInput);
  const [showAlert, setAlert] = useState(false);

  useEffect(() => {
    setError(hasErrorInput);
  }, [hasErrorInput]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (errorInput) setError(false);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    user.password = inputs.new_password;
    await changePassword(user);
    setAlert(true);
  };

  return (
    user && (
      <Settings>
        <Alert hidden={!showAlert} title="Tu contraseña se ha actualizado con éxito" />
        <h1 className="font-bold text-2xl text-black-12 mb-4 dark:text-accent-dark">Cambiar contraseña</h1>
        <form onSubmit={handleSubmit} className="max-w-[500px]">
          <fieldset>
            <p className="py-2">
              <label
                htmlFor="new_password"
                className="font-medium text-base text-black-12 block mb-1.5 dark:text-normal-dark">
                Nueva contraseña
              </label>
              <Input
                id="new_password"
                icon={<IconLock />}
                type="password"
                width="w-full"
                placeholderMessage={''}
                autoFocus
                name="new_password"
                value={inputs.new_password}
                onChange={handleChange}
                tabIndex="3"
                hasError={errorInput}
                messageAdvice={'La contraseña debe tener al menos 8 caracteres'}
                typeMessageAdvice="alert"
                hasMessageAdvice={inputs.new_password.length > 3 && inputs.new_password.length < 8}
              />
            </p>
            <p className="py-2">
              <label
                htmlFor="repeat_new_password"
                className="font-medium text-base text-black-12 block mb-1.5 dark:text-normal-dark">
                Repetir contraseña
              </label>
              <Input
                id="repeat_new_password"
                icon={<IconLock />}
                type="password"
                width="w-full"
                placeholderMessage={''}
                name="repeat_new_password"
                value={inputs.repeat_new_password}
                onChange={handleChange}
                tabIndex="3"
                hasError={errorInput}
                messageAdvice={'Las contraseñas no coinciden'}
                typeMessageAdvice={errorInput ? 'warning' : 'alert'}
                hasMessageAdvice={
                  inputs.new_password !== inputs.repeat_new_password && inputs.repeat_new_password.length > 0
                }
              />
            </p>
            <Button
              size="large"
              width="w-full md:w-1/2 md:max-w-[250px]"
              typeButton="submit"
              disabled={inputs.new_password.length < 8 || inputs.new_password !== inputs.repeat_new_password}
              tabIndex="3"
              className="mt-5">
              Guardar contraseña
            </Button>
          </fieldset>
        </form>
      </Settings>
    )
  );
};

export default Password;
