import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { sendPendingPaymentSignal } from '../../../services/subscriptions';

const SuccessfullyCreated = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  useEffect(() => {
    if (user) {
      async function sendPendingPayment() {
        await sendPendingPaymentSignal(user.id);
      }
      sendPendingPayment();
    }
  }, [user]);

  return (
    <section className="text-center items-center">
      <h1 className="font-bold text-black-12 dark:text-accent-dark mb-7 text-4xl">
        Se ha activado su subscripción correctamente
      </h1>
      <p className="pb-10">¡Bienvenido!</p>
      <Link
        to="/subscriptions/my-subscription"
        className="bg-primary hover:bg-primary-8 text-white rounded-xl active:shadow-inn3r-shadow justify-center p-5">
        Gestionar mi suscripción
      </Link>
    </section>
  );
};

export default SuccessfullyCreated;
