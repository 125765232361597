import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function ComoSacarProvechoApuestasGratuitas() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={'Como sacar el máximo provecho de las apuestas gratuitas'} />

      <p>
        Como ya sabréis, con el matched betting nos encargamos de transformar las apuestas gratuitas en dinero real. Que
        un usuario gane más o menos, reside en su habilidad a la hora de hacer las apuestas gratuitas. Las apuestas
        gratuitas se asignan con un plazo de caducidad, saber esperar a que se presenten buenas condiciones para hacer
        las apuestas gratuitas dentro de este plazo, puede representar un incremento de entre un 10%-15% de tus
        ganancias.
      </p>

      <p>
        En algunas veces coger el mejor rating implica unos riesgos muy grandes, si vuestra banca os lo permite,
        adelante. Pero tenéis que ser conscientes de cuándo se va a jugar el partido, ya que, hasta entonces, tendréis
        el dinero retenido en el Exchange.
      </p>
      <p>
        En este artículo os vamos a detallar los ratings óptimos por casas de apuestas, eso quiere decir que, si no
        encontráis ratings dentro de ese rango, lo mejor sería esperar, ya que van a aparecer más buenos rating seguro.
        Eso sí, no os despistéis que las apuestas gratuitas tienen caducidad.
      </p>
      <p />
      <table id="TableClass" className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="py-4 px-6">Casa de apuestas</th>
            <th className="py-4 px-6">Rating</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Bet365</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Marathonbet</td>
            <td className="py-4 px-6 font-bold">(75-90) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Sportium</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">William Hill</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Luckia</td>
            <td className="py-4 px-6 font-bold">(70-75) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Vive La Suerte</td>
            <td className="py-4 px-6 font-bold">(75-90) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Retabet</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Suertia</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Codere</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Betfair Sportsbook</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Mondobets</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Betway</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Betstars</td>
            <td className="py-4 px-6 font-bold">(75-85) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Bwin</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Circus</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
          <tr className="bg-white border-b text-black-12">
            <td className="py-4 px-6">Pastón</td>
            <td className="py-4 px-6 font-bold">(70-80) %</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}
