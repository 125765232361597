import axios from '../lib/axios';

export default async function getBookmarkers(params = '') {
  const response = await axios
    .get(`/api/v1/bookmarkers${params}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getManagementBookmarkers(user_id) {
  const response = await axios
    .get(`/api/v1/bookmarkers/manage-display?user_id=${user_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function deleteBookmarker(id) {
  await axios
    .delete(`/api/v1/bookmarkers/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createBookmarker(bookmarker) {
  if (bookmarker.logo_url) {
    const response_logo = await uploadBookmarkerLogo(bookmarker.logo_url);
    bookmarker.logo_url = response_logo.url;
    bookmarker.logo_url_png = response_logo.url_png;
  }
  await axios
    .post(`/api/v1/bookmarkers/`, bookmarker)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

async function uploadBookmarkerLogo(logo) {
  const formData = new FormData();
  formData.append('file', logo);

  const response = await axios
    .post(`/api/v1/bookmarkers/upload-image`, formData)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

export async function updateBookmarker(id, bookmarker) {
  if (typeof bookmarker.logo_url !== 'string' && bookmarker.logo_url != null) {
    const response_logo = await uploadBookmarkerLogo(bookmarker.logo_url);
    bookmarker.logo_url = response_logo.url;
    bookmarker.logo_url_png = response_logo.url_png;
  }

  const response = await axios
    .put(`/api/v1/bookmarkers/${id}/`, bookmarker)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}
