import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { IconButton } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import {
  Clock,
  Trash,
  IconCheckCircle,
  Copy,
  CloseCircle,
  ArrowLeftL,
} from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBond, completeBond, deleteUserBond, uncompleteBond, undeleteBond } from '../../services/bonds';
import { useUser } from '../../context/UserContext';
import { toast } from 'react-toastify';

const BondDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const hasNavigated = useRef(false);
  const [bond, setBond] = useState(null);
  const {
    state: { user },
  } = useUser();

  useEffect(() => {
    if (user) {
      async function fetchData() {
        if (id) {
          const response = await getBond(id);
          setBond(response);
        }
      }
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    // Esto se ejecuta una vez cuando el componente se monta
    if (window.history.length > 1) {
      hasNavigated.current = true;
    }
  }, []);

  const handleBackNavigation = () => {
    if (hasNavigated.current && window.history.length > 1) {
      navigate(-1);
    } else if (bond.type === 'welcome') {
      navigate('/welcome-bonds/available');
    } else {
      navigate('/bonds/available');
    }
  };

  async function updateBond(pOperation) {
    switch (pOperation) {
      case 'C':
        await completeBond(user.id, bond.id);
        break;
      case 'D':
        await deleteUserBond(user.id, bond.id);
        break;
      case 'UC':
        await uncompleteBond(user.id, bond.id);
        break;
      case 'UD':
        await undeleteBond(user.id, bond.id);
        break;
      case 'CUD':
        await completeBond(user.id, bond.id);
        await undeleteBond(user.id, bond.id);
        break;
      default:
        break;
    }
    const response = await getBond(id);
    setBond(response);
    toast.success('Bono actualizado correctamente.');
  }

  const translateStatus = (pStatus) => {
    switch (pStatus) {
      case 'available':
        return 'Disponible';
      case 'completed':
        return 'Completado';
      case 'outdated':
        return 'Caducado';
      case 'deleted':
        return 'Borrado';
      default:
        return '';
    }
  };

  return (
    bond && (
      <section id="bond-detail" className="flex flex-col lg:flex-row mb-10 lg:gap-5">
        <section id="bond-content" className="w-full mb-10 lg:growlg:mr-16">
          <div className="flex flex-row justify-between items-start">
            <h2 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-5">Cómo desbloquear el bono</h2>
            <Button
              type="secondary"
              size="medium"
              action={() => handleBackNavigation()}
              icon={
                <Icon className="stroke-white text-2xl">
                  <ArrowLeftL />
                </Icon>
              }>
              {hasNavigated.current ? 'Volver' : 'Listado'}
            </Button>
          </div>
          <div
            className="leading-[2.5] bond-html dark:text-normal-dark"
            dangerouslySetInnerHTML={{ __html: bond.content }}
          />
          {typeof bond.video_url === 'string' && bond.video_url.length > 0 && (
            <div className="flex w-full flex-col items-center">
              <video src={bond.video_url} width="450" height="400" type="video/mp4" controls />
            </div>
          )}
        </section>
        <section id="bond-card" className="w-full lg:!w-[300px] lg:!min-w-[300px]">
          <div className="bg-white rounded-2xl shadow p-3 flex flex-col items-start">
            <img className="h-20" src={bond.bookmarker.logo_url} />
            <h2 className="text-black-12 dark:text-accent-dark font-bold text-base text-center mt-1 mb-3">
              {bond.title}
            </h2>
            <div className={`flex flex-row justify-center items-center px-8 pb-2`}>
              <Icon className="stroke-black-7 text-xl mr-2">
                <Clock />
              </Icon>
              <span className="text-black-7 font-bold text-xs leading-4">
                Termina el {moment(bond.valid_until).format('LLL')}
              </span>
            </div>
            <div className={`flex flex-row justify-center items-center px-8`}>
              <Icon className="stroke-black-7 text-xl mr-2">
                <Clock />
              </Icon>
              <span className="text-black-7 font-bold text-xs leading-4 mt-2">
                Última modificación el {moment(bond.update_at).format('LLL')}
              </span>
            </div>
            <div className="flex flex-col md:flex-row bg-primary-1 rounded-xl text-center p-3 justify-center items-center mt-2">
              <div className="font-bold text-black-10 mr-3">Ganancia estimada:</div>
              <div className="font-bold text-primary text-xl">
                {bond.min_reward} - {bond.max_reward} €
              </div>
            </div>
            <div className="flex flex-col gap-2 bg-black-1 rounded-xl text-left p-3 justify-start items-start mt-2">
              {bond.match && (
                <div className="font-bold text-black-10 mr-3">
                  Partido: <span className="font-bold text-primary text-lg">{bond.match}</span>
                </div>
              )}
              {bond.min_bet && (
                <div className="font-bold text-black-10 mr-3">
                  Cuota mínima: <span className="font-bold text-primary text-lg">{bond.min_bet}</span>
                </div>
              )}
              {bond.valid_market && (
                <div className="font-bold text-black-10 mr-3">
                  Mercados válidos: <span className="font-bold text-primary text-lg">{bond.valid_market}</span>
                </div>
              )}
              {bond.max_bet && (
                <div className="font-bold text-black-10 mr-3">
                  Apuesta máxima: <span className="font-bold text-primary text-lg">{bond.max_bet}</span>
                </div>
              )}
              {bond.reward && (
                <div className="font-bold text-black-10 mr-3">
                  Recompensa: <span className="font-bold text-primary text-lg">{bond.reward}</span>
                </div>
              )}
              {bond.condition && (
                <div className="font-bold text-black-10 mr-3">
                  Condiciones: <span className="font-bold text-primary text-sm leading-none">{bond.condition}</span>
                </div>
              )}
              {bond.need_invitation && (
                <div className="font-bold text-black-10 mr-3">
                  Necesita Invitación:{' '}
                  <span className="font-bold text-primary text-sm leading-none">
                    {bond.need_invitation ? 'Si' : 'No'}
                  </span>
                </div>
              )}
              {bond?.calculators.length > 0 && (
                <ul>
                  <li>
                    <span className="font-bold mr-1">Calculadoras: </span>
                    {bond.calculators.map((calculator, index) => (
                      <span className="font-bold text-primary text-md leading-none" key={index}>
                        {calculator.name} {index !== bond.calculators.length - 1 ? '/' : ''}{' '}
                      </span>
                    ))}
                  </li>
                </ul>
              )}
              {bond?.status && (
                <div className="font-bold text-black-10 mr-3">
                  Estado:{' '}
                  <span className="font-bold text-primary text-sm leading-none">{translateStatus(bond?.status)}</span>
                </div>
              )}
            </div>
            <div className="flex flex-row w-full justify-center mt-1">
              {bond.code ? (
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(bond.code);
                  }}
                  className="relative border-dashed border-2 md:grow border-primary-7 mb-2 md:mb-0 md:mr-3 rounded-xl py-2 px-3 font-bold text-primary flex md:text-lg justify-center items-center">
                  {bond.code}
                  <Icon className="absolute right-3 stroke-primary text-2xl">
                    <Copy />
                  </Icon>
                </button>
              ) : (
                <button className="hidden md:flex md:invisible relative border-dashed border-2 md:grow border-black-5 mb-2 md:mb-0 md:mr-3 rounded-xl py-2 px-3 font-bold text-black-5 md:text-lg justify-center items-center cursor-not-allowed">
                  NO REQUIERE CÓDIGO
                  <Icon className="absolute right-3 stroke-black-5 text-2xl">
                    <Copy />
                  </Icon>
                </button>
              )}
              {bond?.status === 'available' ? (
                <>
                  <IconButton
                    onClick={() => updateBond('D')}
                    iconClass="stroke-warning-red text-xl"
                    className="rounded-xl hover:bg-black-1 mr-1.5 w-12 h-12 !bg-black-2"
                    icon={<Trash stroke={'#F4304A'} />}
                  />
                  <IconButton
                    onClick={() => updateBond('C')}
                    className="rounded-xl hover:bg-secondary-9 w-12 h-12 !bg-secondary"
                    iconClass="stroke-white text-xl"
                    icon={<IconCheckCircle />}
                  />
                </>
              ) : bond?.status === 'completed' ? (
                <>
                  <IconButton
                    onClick={() => updateBond('D')}
                    disabled={true}
                    iconClass="stroke-warning-red text-xl"
                    className="rounded-xl hover:bg-black-1 mr-1.5 w-12 h-12 !bg-black-2"
                    icon={<Trash stroke={'#F4304A'} />}
                  />
                  <IconButton
                    onClick={() => updateBond('UC')}
                    className="rounded-xl hover:bg-warning-red-3 w-12 h-12 bg-warning-red"
                    iconClass="stroke-white text-xl"
                    icon={<CloseCircle />}
                  />
                </>
              ) : bond?.status === 'deleted' ? (
                <>
                  <IconButton
                    onClick={() => updateBond('UD')}
                    iconClass="stroke-warning-red text-xl"
                    className="rounded-xl hover:bg-black-1 mr-1.5 w-12 h-12 !bg-black-2"
                    icon={<ArrowLeftL stroke={'#F4304A'} />}
                  />
                  <IconButton
                    onClick={() => updateBond('CUD')}
                    className="rounded-xl hover:bg-secondary-9 w-12 h-12 !bg-secondary"
                    iconClass="stroke-white text-xl"
                    icon={<IconCheckCircle />}
                  />
                </>
              ) : (
                <>
                  <IconButton
                    onClick={() => updateBond('D')}
                    iconClass="stroke-warning-red text-xl"
                    disabled={true}
                    className="rounded-xl hover:bg-black-1 mr-1.5 w-12 h-12 !bg-black-2"
                    icon={<Trash stroke={'#F4304A'} />}
                  />
                  <IconButton
                    onClick={() => updateBond('C')}
                    className="rounded-xl hover:bg-secondary-9 w-12 h-12 bg-secondary"
                    iconClass="stroke-white text-xl"
                    icon={<IconCheckCircle />}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </section>
    )
  );
};

export default BondDetail;
