import FeaturedGuidesText from '../../components/FeaturedGuidesText';
import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function QueEsElOddmatcher() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={'¿Qué es el Oddsmatcher?'} />
      <p>
        Un <strong>Oddsmatcher</strong> es un software encargado de{' '}
        <strong>
          comparar las cuotas&nbsp;<span className="estilo-favor">a favor</span> de las casas de apuestas con las cuotas{' '}
          <span className="estilo-contra">en contra</span>
        </strong>
        &nbsp;de las casas de contrapartida. Gracias a la multitud de filtros de que dispone nuestro{' '}
        <strong>Oddsmatcher</strong>, podrás ordenar los resultados por diferencia de cuotas, retención de apuesta
        gratuita, casa de apuestas, rango de cuotas, fecha e incluso por la liquidez del evento.
      </p>
      <p>
        Si los conceptos que hemos mencionado anteriormente no te suenan de nada, no te preocupes, ya que{' '}
        <strong>daremos una explicación</strong> a lo largo de esta guía.
      </p>
      <h2>¿Por qué es importante un buen Oddsmatcher?</h2>
      <p>
        Esta maravilla de software, <strong>te indicará donde y cuanto apostar</strong>{' '}
        <strong>para maximizar tus beneficios</strong> de una manera clara y rápida. Así que en el momento de realizar
        las apuestas para las diferentes promociones vas a saber{' '}
        <strong>la forma más óptima para ser lo más rentable posible </strong>sin que tengas que perder tiempo buscando
        ratings de forma manual.
      </p>
      <FeaturedGuidesText>
        El objetivo final de un Oddsmatcher es optimizar el proceso para que obtengas un mayor porcentaje de beneficio
        cada bono.
      </FeaturedGuidesText>
      <p>
        Un buen uso del <strong>Oddsmatcher</strong> puede significar <strong>ganar entre un 10% - 20% más</strong> con
        tus promociones, además de la <strong>increíble cantidad de tiempo que te ahorras</strong> en buscar el mercado
        perfecto para desbloquear las promociones.
      </p>
      <p>
        Imagínate un <strong>Oddsmatcher</strong>, que te dé retenciones de apuestas gratuitas de como{' '}
        <strong>máximo un 70%</strong> y que el nuestro encuentre resultados del <strong>85% o 90%</strong>. Eso quiere
        decir que, por cada apuesta gratuita de 10 € puedes ganar <strong>1 € o 1,5 € extras.</strong> Puede parecerte
        poco, pero pronto verás como con cientos de promociones este número puede representar{' '}
        <strong>cientos o incluso miles de euros.</strong>
      </p>
      <h2>Funciones básicas del Oddsmatcher</h2>
      <p>
        En este apartado hablaremos de los conceptos y funcionalidades básicas que vas a encontrar cuando uses nuestro{' '}
        <strong>Oddsmatcher</strong>.
      </p>
      FOTO
      <p>
        En la parte superior del Oddsmatcher <strong>vas a encontrar un filtro</strong> donde puedes seleccionar la casa
        de apuestas en cuestión.
      </p>
      <p>
        El&nbsp;<strong>Oddsmatcher</strong> consta de diferentes columnas con las siguientes funciones:
      </p>
      <ul className="list-disc ml-3">
        <li>
          <strong> Fecha/hora:</strong> Nos indica el día y hora del evento deportivo.
        </li>
        <li>
          <strong> Columnas de deporte:</strong> Nos indica el tipo de deporte del evento.
        </li>
        <li>
          <strong> Detalles:</strong> Nos indica los equipos que juegan el partido.
        </li>
        <li>
          <strong> Apuesta:</strong> Nos indica a que equipo o evento tenemos que realizar la apuesta (tanto{' '}
          <span className="estilo-favor">a favor</span> como <span className="estilo-contra">en contra</span>).
        </li>
        <li>
          <strong> Tipo de Apuesta:</strong> Nos indica el mercado de la apuesta (vencedor del partido, resultado de
          goles, marcador exacto...)
        </li>
        <li>
          <strong> Diferencia de Cuotas:</strong> Esta columna nos indica la cantidad de dinero que vamos a perder en
          realizar una apuesta a dicho evento, siendo 100 una perdida 0. <strong>Por ejemplo</strong>, una apuesta de
          100 € a Diferencia de cuotas 98,5, nos indicará que vamos a perder 1,5 € en realizar y asegurar dicha apuesta.
        </li>
        <li>
          <strong> RF (Retención de Freebet o apuesta gratuita):</strong> Nos indica la cantidad de dinero que vamos a
          ganar de una apuesta realizada con un bono de apuesta gratuita.&nbsp;<strong>Por ejemplo,</strong> una apuesta
          gratuita de 100 € realizada y asegurada a <strong>RF</strong> de 77, nos indicará que vamos a ganar 77 €.
        </li>
        <li>
          <strong> Columna de Calculadoras:</strong> En esta columna aparecen iconos de calculadoras. Al pulsar encima
          vamos a poder calcular el importe a apostar para asegurar las apuestas. Hablaremos de ello más adelante.
        </li>
        <li>
          <strong> Casa de Apuestas:&nbsp;</strong>Nos va a indicar la casa de apuestas donde haremos la apuesta{' '}
          <span className="estilo-favor">a favor.</span>
        </li>
        <li>
          <strong>
            Apuesta <span className="estilo-favor">a Favor</span>:
          </strong>{' '}
          Marca la cuota que ofrece dicha casa de apuestas para el resultado en cuestión del evento.
        </li>
        <li>
          <strong>Exchange:</strong> Como hemos dicho en anteriores guías, para apostar{' '}
          <span className="estilo-contra">en contra</span> siempre vamos a usar <strong>Betfair Exchange.</strong>
        </li>
        <li>
          <strong>
            Apuesta <span className="estilo-contra">en Contra</span>:&nbsp;
          </strong>
          Nos va a indicar la cuota que hay en el Exchange para apostar <span className="estilo-contra">en contra</span>{' '}
          a dicho desenlace del evento deportivo.
        </li>
        <li>
          <strong>Liquidez:</strong> Indica la cantidad de dinero disponible en el Exchange a dicha cuota.&nbsp;
        </li>
      </ul>
      <h2>Función de Calculadora en el Oddsmatcher</h2>
      <p>Esta es la calculadora que se abre al pulsar sobre el icono de calculadora en el Oddsmatcher:</p>
      FOTO
      <p>
        En la <strong>parte izquierda de la calculadora</strong> aparecerá información del evento deportivo, la casa de
        apuestas, el Exchange y las cuotas.
      </p>
      <p>
        En la&nbsp;<strong>parte derecha de la calculadora</strong> nos aparece la propia área de cálculo, con 4 tipos
        de calculadoras diferentes: Normal, Freebet, Bono depósito y Reembolso. Cada modo de la calculadora{' '}
        <strong>servirá para un tipo de bono distinto.&nbsp;</strong>
      </p>
      <p>
        Debajo de los diferentes tipos de calculadoras aparece un apartado para la apuesta{' '}
        <span className="estilo-favor">a favor</span> (en azul) y un apartado para la apuesta{' '}
        <span className="estilo-contra">en contra</span> (en rojo). Simplemente tendremos que{' '}
        <strong>variar el importe en el apartado de "Cantidad a Apostar"</strong> dependiendo de la cantidad de la
        supuesta promoción. Las cuotas{' '}
        <strong>deben de ser las que ofrezcan tanto la casa de apuestas como el Exchange,</strong> no está mal echarle
        un ojo y comprobar que son las mismas, ya que pueden cambiar rápidamente.
      </p>
      <FeaturedGuidesText>
        Debajo nos indica la cantidad a apostar en contra en el Exchange. En el ejemplo de la imagen superior, para una
        apuesta <span className="estilo-favor">a favor</span> de 100 € a cuota 1,33, deberemos de apostar 100,76 € a
        cuota 1,34 <span className="estilo-contra">en contra</span> en el Exchange. El riesgo en el Exchange será de
        34,26 €. Si hacemos eso <strong>pase lo que pase</strong> solo vamos a perder 1,26 €.
      </FeaturedGuidesText>
      <h2>
        <strong>Conceptos importantes del Oddsmatcher</strong>
      </h2>
      <FeaturedGuidesText margin={'mb-5'} label={'Riesgo'}>
        El&nbsp;<strong>riesgo</strong>&nbsp;es un concepto relacionado con las apuestas&nbsp;en contra&nbsp;realizadas
        en Betfair Exchange. Se podría resumir como la&nbsp;
        <strong>cantidad que un usuario está dispuesto a arriesgar para ganar su apuesta.</strong>
        <br />
        El riesgo se calcula como: (cantidad apostada * Cuota – Cantidad apostada).
        <br />
        Al colocar una apuesta,&nbsp;<strong>Betfair te retiene una cantidad igual al riesgo</strong>, que se usará para
        pagar a otro usuario en caso de que tu apuesta sea perdedora. Por eso,
        <strong>
          &nbsp;es imprescindible disponer, como mínimo, de la cantidad de riesgo como saldo en Betfair antes de colocar
          tus apuestas.
        </strong>
        &nbsp;Si tus apuestas resultan ganadoras, Betfair te devuelve la cantidad retenida en concepto de riesgo más las
        ganancias derivadas.
        <br />
        Por último, como el riesgo va asociado a la cuota,&nbsp;<strong>a mayor cuota mayor riesgo.</strong>
        <br />
      </FeaturedGuidesText>
      <FeaturedGuidesText margin={'mb-5'} label={'Apuesta en contra'}>
        Las apuestas&nbsp;en contra&nbsp;son las que se utilizan en el&nbsp;<strong>Matched Betting</strong>
        &nbsp;para&nbsp;minimizar pérdidas o asegurar beneficios&nbsp;y&nbsp;mayoritariamente son las que&nbsp;
        <strong>se colocan en Betfair Exchange pulsando en las cuotas de color rojo.&nbsp;</strong>Con este tipo de
        apuestas&nbsp;<strong>se cubren todas las posibilidades del encuentro.</strong>&nbsp; Por ejemplo si
        apuestas&nbsp;en contra&nbsp;del empate,&nbsp;
        <strong>vas a ganar tu apuesta tanto si gana el equipo A como si gana el equipo B.</strong>&nbsp;También es
        cierto, que si una promoción no se puede cubrir con Betfair Exchange se puede apostar&nbsp;en contra&nbsp;en
        otras casas de apuestas.
      </FeaturedGuidesText>
      <FeaturedGuidesText label={'Liquidez'}>
        La&nbsp;<strong>liquidez</strong>&nbsp;es básicamente la&nbsp;
        <strong>cantidad de dinero disponible a una determinada cuota.</strong>&nbsp;Puedes saber la liquidez de una
        cuota fijándote en&nbsp;
        <strong>el número en euros que sale debajo de ella en Betfair Exchange.</strong>&nbsp;La liquidez es un
        concepto&nbsp;muy importante. Imagina que tienes que cubrir una apuesta gratuita en Betfair Exchange y para
        ello&nbsp;
        <strong>tienes que apostar 14 €&nbsp;</strong>
        <strong>en contra</strong>.&nbsp;Si la&nbsp;<strong>liquidez disponible a esa cuota es de 10 €</strong>
        &nbsp;te&nbsp;<strong>van a quedar 4 € pendientes de igualar</strong>. Puede que durante el transcurso del día
        esos 4 € acaben igualándose o, por el contrario, que debido a un movimiento de cuotas no se igualen.
        Entonces,&nbsp;el beneficio de tu apuesta gratuita no van a ser los 14 € que te mostró la calculadora
        inicialmente, sino 10 €, que ha sido la parte igualada de tu apuesta de 14 €.
      </FeaturedGuidesText>
    </section>
  );
}
