import { SectionTopMenu } from '@vilibets/vilibets-uikit/dist/molecules/SectionTopMenu/SectionTopMenu';

const Bonds = ({ children }) => {
  return (
    <div className="flex flex-col" id="header-menu">
      <div className="flex intermedium:child:w-auto mb-5">
        <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Bonos recurrentes</h1>
      </div>
      <SectionTopMenu
        links={[
          {
            title: 'Bonos disponibles',
            link: '/bonds/available',
          },
          {
            title: 'Bonos completados',
            link: '/bonds/completed',
          },
          {
            title: 'Bonos caducados',
            link: '/bonds/expired',
          },
          {
            title: 'Bonos borrados',
            link: '/bonds/deleted',
          },
        ]}
      />

      <section className="flex flex-col pb-5 grow">{children}</section>
    </div>
  );
};

export default Bonds;
