import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';

const PaymentError = () => {
  useAuth({
    middleware: 'auth',
  });

  return (
    <section className="text-center items-center">
      <h1 className="font-bold text-black-12 dark:text-accent-dark mb-7 text-4xl">
        Ha habido un error con el pago de subscripción
      </h1>
      <p className="pb-10">Vuélvelo a intentar o póngase en contacto con el equipo de administradores.</p>
      <p className="pb-10" />
      <Link
        to="/subscriptions/my-subscription"
        className="bg-primary hover:bg-primary-8 text-white rounded-xl active:shadow-inn3r-shadow justify-center p-5">
        Gestionar mi suscripción
      </Link>
    </section>
  );
};

export default PaymentError;
