/*eslint-disable*/
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { BackofficeHeader } from '@vilibets/vilibets-uikit/dist/organisms/BackofficeHeader/BackofficeHeader';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LoadingProvider } from '../context/LoadingContext';
import AvailableBonds from '../pages/Bonds/AvailableBonds/AvailableBonds';
import AvailableWelcomeBonds from '../pages/Bonds/AvailableBonds/AvailableWelcomeBonds';
import { useUser } from '../context/UserContext';
import { header_links } from '../lib/headerLinks';
import Dashboard from '../pages/Dashboard/Dashboard';
import ForgotPasswordComponent from '../pages/ForgotPassword/ForgotPassword';
import LoginComponent from '../pages/Login/Login';
import Invoices from '../pages/Subscriptions/Invoices/Invoices';
import MySubscription from '../pages/Subscriptions/MySubscription/MySubscription';
import PaymentCanceled from '../pages/Subscriptions/SubscriptionRedirect/PaymentCanceled';
import SuccessfullyCreated from '../pages/Subscriptions/SubscriptionRedirect/SuccessfullyCreated';
import ProfitTracker from '../pages/ProfitTracker/ProfitTracker';
import RegisterPage from '../pages/Register/Register';
import ResetPasswordComponent from '../pages/ResetPassword/ResetPassword';
import getMe from '../services/user';
import ProtectedRoute from './ProtectedRoute';
import RoutesAdmin from './RoutesAdmin';
import RoutesAdminWorkers from './RoutesAdminWorkers';
import Me from '../pages/Settings/Me/Me';
import Password from '../pages/Settings/Password/Password';
import Excel from '../pages/Settings/Excel/Excel';
import Telegram from '../pages/Settings/Telegram/Telegram';
import ManageBonds from '../pages/Bonds/ManageBonds';
import getBookmarkers from '../services/bookmarker';
import BondDetail from '../pages/Bonds/BondDetail';
import CompletedBonds from '../pages/Bonds/CompletedBonds/CompletedBonds';
import CompletedWelcomeBonds from '../pages/Bonds/CompletedBonds/CompletedWelcomeBonds';
import DeletedBonds from '../pages/Bonds/DeletedBonds/DeletedBonds';
import DeletedWelcomeBonds from '../pages/Bonds/DeletedBonds/DeletedWelcomeBonds';
import ExpiredBonds from '../pages/Bonds/ExpiredBonds/ExpiredBonds';
import PopupVerifyEmail from '../components/popupVerifyEmail/PopupVerifyEmail';
import NormalCalc from '../pages/Calculators/NormalCalc';
import DutchingCalc from '../pages/Calculators/DutchingCalc';
import TripleRefoundCalc from '../pages/Calculators/TripleRefoundCalc';
import OddsMatcher from '../pages/OddsMatcher/OddsMatcher';
import FFRefoundCalc from '../pages/Calculators/FFRefoundCalc';
import AccumulatedCalc from '../pages/Calculators/AccumulatedCalc';
import RiskFreeCalc from '../pages/Calculators/RiskFreeCalc';
import Workers from '../pages/Workers/Workers';
import AddEditBond from '../pages/Bonds/AddEditBond';
import Users from '../pages/Users/Users';
import Orders from '../pages/Orders/Orders';
import Bookmarkers from '../pages/Bookmarkers/Bookmarkers';
import PaymentError from '../pages/Subscriptions/SubscriptionRedirect/PaymentError';
import ManageIncidents from '../pages/BookmarkerIncidents/ManageIncidents';
import Incidents from '../pages/BookmarkerIncidents/Incidents';
import ManageNotices from '../pages/Notices/ManageNotices';
import Notices from '../pages/Notices/Notices';
import Affiliates from '../pages/Settings/Affiliates/Affiliates';
import ManageBookmarkers from '../pages/Bookmarkers/ManageBookmarkers';
import GlobalVars from '../pages/Settings/GlobalVars/GlobalVars';
import { useAuth } from '../hooks/auth';
import UserDetail from '../pages/Users/UserDetail';
import ManageAffiliates from '../pages/ManageAffiliates/ManageAffiliates';
import ExpiredPopupSubscription from '../components/ExpiredPopupSubscription/ExpiredPopupSubscription';
import HasSubscriptionRoute from './HasSubscriptionRoute';
import OddsDutcher from '../pages/OddsDutcher/OddsDutcher';
import Guide from '../pages/Guide/Guide';
import Course from '../pages/Course/Course';
import BonosRolloverPastonBetsson from '../pages/Guide/bonos-con-rollover-de-paston-y-betsson';
import CasasDeIntercambio from '../pages/Guide/casas-de-intercambio';
import CasinoMadrid from '../pages/Guide/casino-madrid';
import ComoSacarProvechoApuestasGratuitas from '../pages/Guide/como-sacar-provecho-apuestas-gratuitas';
import FiscalidadEnApuestasDeportivas from '../pages/Guide/fiscalidad-en-apuestas-deportivas';
import QueEsElMatchBetting from '../pages/Guide/introduccion-al-matched-betting';
import QueEsElOddmatcher from '../pages/Guide/que-es-el-oddsmatcher';
import SuperCuotas from '../pages/Guide/supercuotas-oddsboost';
import UsoExcel from '../pages/Guide/uso-excel';
import Influencers from '../pages/Influencers/Influencers';
import InfluencerDetail from '../pages/Influencers/InfluencerDetail';
import '@theme-toggles/react/css/Expand.css';
import { Expand } from '@theme-toggles/react';
import AccumulatedFFCalc from '../pages/Calculators/AccumulatedFFCalc';
import OddsMatcher2 from '../pages/OddsMatcher2/OddsMatcher2';

const App = () => {
  const { dispatchUser } = useUser();
  const {
    state: { user },
  } = useUser();
  const { dispatchBookmarkers } = useUser();
  const {
    state: { bookmarkers },
  } = useUser();

  const { header_links_users, header_links_workers, header_links_admins, header_links_influencer } = header_links(user);
  const [userHeaderLinks, setUserHeaderLinks] = useState(header_links_users);
  useEffect(() => {
    if (user) {
      if (user.role === 'admin') {
        setUserHeaderLinks(header_links_admins);
      } else if (user.role === 'worker') {
        setUserHeaderLinks(header_links_workers);
      } else if (user.role === 'influencer') {
        setUserHeaderLinks(header_links_users);
      }
    }
  }, [user]);

  if (user === null) {
    getMe()
      .then(({ response }) => {
        dispatchUser({ type: 'hasUser', dispatch: response });
      })
      .catch(() => {
        dispatchUser({ type: 'hasNotUser', dispatch: undefined });
      });
    return <Loading />;
  }

  if (bookmarkers === null && user) {
    getBookmarkers()
      .then(({ response }) => {
        dispatchBookmarkers({ type: 'hasBookmarkers', dispatch: response });
      })
      .catch(() => {
        dispatchBookmarkers({ type: 'hasNotBookmarkers', dispatch: undefined });
      });
    return <Loading />;
  }

  return (
    <LoadingProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<LoginComponent />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/forgot-password" element={<ForgotPasswordComponent />} />
          <Route exact path="/reset-password" element={<ResetPasswordComponent />} />
          <Route exact path="/verify-email" element={<ResetPasswordComponent />} />
          {/* Routes protected */}
          <Route element={<ProtectedRoute user={user} />}>
            <Route
              path="/*"
              element={
                <>
                  <Backoffice userHeader={userHeaderLinks} user={user} /> <PopupVerifyEmail />
                </>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoadingProvider>
  );
};

function Backoffice({ userHeader, user }) {
  const { logout } = useAuth({
    middleware: 'auth',
  });
  const logoutButton = () => {
    logout();
  };
  const location = useLocation();
  // pages not show popup subscription
  const pagesNotShowPopup = [
    '/settings/me',
    '/settings/password',
    '/settings/excel',
    '/settings/affiliates',
    '/subscription',
    '/manage-affiliates',
    '/course',
  ];
  const filterNotShowPopup = pagesNotShowPopup.filter((page) => location.pathname.includes(page)).length === 0;
  const finishSubscription = user?.subscription?.end_date
    ? new Date(user.subscription.end_date).getTime() < new Date().getTime()
    : true;

  useEffect(() => {
    const pathname = location.pathname;
    let title = '';
    if (pathname.includes('subscriptions')) title += 'Suscripciones';
    if (pathname.includes('dashboard')) title += 'Dasboard';
    if (pathname.includes('calculators')) {
      title += 'Calculadoras';
      if (pathname.includes('normal')) title += ' - Normal';
      if (pathname.includes('dutching')) title += ' - Dutching';
      if (pathname.includes('triple-refound')) title += ' - 3F Reembolso';
      if (pathname.includes('ff-refound')) title += ' - F-F Reembolso';
      if (pathname.includes('accumulated')) title += ' - Acumuladas';
      if (pathname.includes('risk-free')) title += ' - Riesgo sin reembolso';
      if (pathname.includes('ff-accumulated')) title += ' - Acumuladas F-F';
    }
    if (pathname.includes('welcome')) {
      title += 'Bienvenidas';
      if (pathname.includes('available')) title += ' - Disponibles';
      if (pathname.includes('completed')) title += ' - Completados';
      if (pathname.includes('deleted')) title += ' - Eliminados';
    } else if (pathname.includes('bonds')) {
      title += 'Bonos';
      if (pathname.includes('available')) title += ' - Disponibles';
      if (pathname.includes('completed')) title += ' - Completados';
      if (pathname.includes('deleted')) title += ' - Eliminados';
      if (pathname.includes('refound')) title += ' - Eliminados';
      if (pathname.includes('expired')) title += ' - Expirados';
    }
    if (pathname.includes('profit-tracker')) title += 'ProfitTracker';
    // if (pathname.includes('oddsmatcher')) title += 'OddsMatcher';
    if (pathname.includes('oddsmatcher')) title += 'OddsMatcher';
    if (pathname.includes('dutcher')) title += 'OddsDutcher';
    if (pathname.includes('manage-bookmarkers')) title += 'Gestión de Bookmarkers';
    if (pathname.includes('manage-affiliates')) title += 'Gestión de afiliados';
    if (pathname.includes('course')) title += 'Curso';
    if (pathname.includes('profile')) title += 'Mi perfil';
    if (pathname.includes('manage-bonds')) title += 'Gestión de Bonos';
    if (pathname.includes('workers')) title += 'Trabajadores';
    if (pathname.includes('users')) title += 'Usuarios';
    if (pathname.includes('bookmarkers')) title += 'Casas de apuestas';
    title += pathname.includes('manage-incidents')
      ? 'Gestión de incidencias'
      : pathname.includes('incidents')
      ? 'Incidencias'
      : '';
    title += pathname.includes('manage-notices') ? 'Gestión de avisos' : pathname.includes('notices') ? 'Avisos' : '';
    if (pathname.includes('orders')) title += 'Facturación';
    if (pathname.includes('settings')) {
      title += 'Configuración';
      if (pathname.includes('me')) title += ' - Sobre mí';
      if (pathname.includes('password')) title += ' - Contraseña';
      if (pathname.includes('telegram')) title += ' - Telegram';
      if (pathname.includes('excel')) title += ' - Excel';
      if (pathname.includes('affiliates')) title += ' - Afiliados';
      if (pathname.includes('global-vars')) title += ' - Variables globales';
    }
    document.title = title + (title !== '' ? ' | Vilibets' : 'Vilibets');
  }, [location]);

  useEffect(() => {
    // toLightMode();
    if (localStorage.theme === 'dark') {
      toDarkMode();
    } else {
      toLightMode();
    }
  }, []);

  const [isDarkMode, setIsDarkMode] = useState(localStorage.theme === 'dark');

  const toDarkMode = () => {
    localStorage.theme = 'dark';
    setIsDarkMode(true);
    updateTheme();
  };

  const toLightMode = () => {
    localStorage.theme = 'light';
    setIsDarkMode(false);
    updateTheme();
  };

  function updateTheme() {
    // console.log(localStorage.theme);
    switch (localStorage.theme) {
      case 'dark':
        document.documentElement.classList.add('dark');
        document.documentElement.setAttribute('color-theme', 'dark');
        break;
      case 'light':
        document.documentElement.classList.remove('dark');
        document.documentElement.setAttribute('color-theme', 'light');
        break;
    }
  }

  return (
    <BackofficeHeader
      header_links={userHeader}
      user_img={'avatar-person.svg'}
      logout_action={logoutButton}
      public_page_url={process.env.REACT_APP_PUBLIC_PAGE_URL}
      public_header_links={[
        { name: 'Descubre cómo funciona', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/como-funciona' },
        { name: 'Bonos bienvenida', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/bonos-lista' },
        {
          name: 'Curso',
          route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/curso',
        },
        { name: 'Comunidad', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/comunidad' },
        { name: 'Tarifas', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/tarifas' },
        { name: 'Contacto', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/contacto' },
      ]}
      toggleIcon={
        <Expand toggled={isDarkMode} onToggle={() => (localStorage.theme === 'dark' ? toLightMode() : toDarkMode())} />
      }>
      <ExpiredPopupSubscription display={filterNotShowPopup && finishSubscription && user?.role === 'user'}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/subscriptions/my-subscription" element={<MySubscription />} />
          <Route path="/subscriptions/invoices" element={<Invoices />} />
          <Route path="/subscriptions/succesfully-created" element={<SuccessfullyCreated />} />
          <Route path="/subscriptions/canceled" element={<PaymentCanceled />} />
          <Route path="/subscriptions/error" element={<PaymentError />} />
          <Route path="/manage-affiliates" element={<ManageAffiliates />} />
          <Route path="/course" element={<Course />} />
          {/* Routes hasSubscription protected */}
          <Route element={<HasSubscriptionRoute role={user?.role} hasValidSubscription={finishSubscription} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/incidents" element={<Incidents />} />
            <Route path="/notices" element={<Notices />} />
            <Route path="/welcome-bonds" element={<AvailableWelcomeBonds />} />
            <Route path="/welcome-bonds/available" element={<AvailableWelcomeBonds />} />
            <Route path="/welcome-bonds/completed" element={<CompletedWelcomeBonds />} />
            <Route path="/welcome-bonds/deleted" element={<DeletedWelcomeBonds />} />
            <Route path="/bonds" element={<AvailableBonds />} />
            <Route path="/bonds/:id" element={<BondDetail />} />
            <Route path="/bonds/available" element={<AvailableBonds />} />
            <Route path="/bonds/completed" element={<CompletedBonds />} />
            <Route path="/bonds/deleted" element={<DeletedBonds />} />
            <Route path="/bonds/expired" element={<ExpiredBonds />} />
            <Route path="/profit-tracker" element={<ProfitTracker />} />
            <Route path="/manage-bookmarkers" element={<ManageBookmarkers />} />
            <Route path="/calculators" element={<Navigate replace to="/calculators/normal" />} />
            <Route path="/calculators/normal" element={<NormalCalc />} />
            <Route path="/calculators/dutching" element={<DutchingCalc />} />
            <Route path="/calculators/triple-refound" element={<TripleRefoundCalc />} />
            <Route path="/calculators/ff-refound" element={<FFRefoundCalc />} />
            <Route path="/calculators/accumulated" element={<AccumulatedCalc />} />
            <Route path="/calculators/risk-free" element={<RiskFreeCalc />} />
            <Route path="/calculators/ff-accumulated" element={<AccumulatedFFCalc />} />
            {/* <Route path="/oddsmatcher" element={<OddsMatcher />} /> */}
            <Route path="/oddsmatcher" element={<OddsMatcher2 />} />
            <Route path="/dutcher" element={<OddsDutcher />} />
            <Route path="/guide" element={<Guide />} />
            <Route path="/guide/bonos-con-rollover-de-paston-y-betsson" element={<BonosRolloverPastonBetsson />} />
            <Route path="/guide/casas-de-intercambio" element={<CasasDeIntercambio />} />
            <Route path="/guide/casino-madrid" element={<CasinoMadrid />} />
            <Route
              path="/guide/como-sacar-provecho-apuestas-gratuitas"
              element={<ComoSacarProvechoApuestasGratuitas />}
            />
            <Route path="/guide/fiscalidad-en-apuestas-deportivas" element={<FiscalidadEnApuestasDeportivas />} />
            <Route path="/guide/introduccion-al-matched-betting" element={<QueEsElMatchBetting />} />
            <Route path="/guide/que-es-el-oddsmatcher" element={<QueEsElOddmatcher />} />
            <Route path="/guide/supercuotas-oddsboost" element={<SuperCuotas />} />
            <Route path="/guide/uso-excel" element={<UsoExcel />} />
          </Route>
          {/* Routes workers and admins protected */}
          <Route element={<RoutesAdminWorkers role={user?.role} />}>
            <Route path="/profile" element={<Dashboard />} />
            <Route path="/manage-bonds" element={<ManageBonds />} />
            <Route path="/manage-bonds/add" element={<AddEditBond />} />
            <Route path="/manage-bonds/:id/edit" element={<AddEditBond />} />
            <Route path="/workers" element={<Workers />} />
            <Route path="/influencers" element={<Influencers />} />
            <Route path="/influencers/:id" element={<InfluencerDetail />} />
            <Route path="/users" element={<Users />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/users/:id" element={<UserDetail />} />
          </Route>
          {/* Routes admin protected */}
          <Route element={<RoutesAdmin role={user?.role} />}>
            <Route path="/bookmarkers" element={<Bookmarkers />} />
            <Route path="/manage-incidents" element={<ManageIncidents />} />
            <Route path="/settings/global-vars" element={<GlobalVars />} />
            <Route path="/manage-notices" element={<ManageNotices />} />
          </Route>
          <Route path="/settings" element={<Navigate replace to="/settings/me" />} />
          <Route path="/settings/me" element={<Me />} />
          <Route path="/settings/password" element={<Password />} />
          <Route path="/settings/telegram" element={<Telegram />} />
          <Route path="/settings/excel" element={<Excel />} />
          <Route path="/settings/affiliates" element={<Affiliates />} />
        </Routes>
      </ExpiredPopupSubscription>
    </BackofficeHeader>
  );
}

export default App;
