import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Switcher } from '@vilibets/vilibets-uikit/dist/atoms/Switch';
import { toast } from 'react-toastify';
import updateUser, { createUser, getTelegramInfo } from '../../services/users';
import { IconTelegram } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const CreditUserPopup = ({ display, closePopup, data = null, users = [] }) => {
  const [telegramInfo, setTelegramInfo] = useState(null);
  const [fields, setField] = useState(
    data === null
      ? {
          name: '',
          surnames: '',
          username: '',
          email: '',
          telegram_id: '',
          role: 'user',
          password: '',
          joined_by_id: '',
          affiliate_credits_percentage: 10,
          comment: '',
        }
      : data
  );
  const [userOptions, setUserOptions] = useState(null);

  useEffect(() => {
    if (data !== null) {
      setField(data);
      getTelegramInfo(data.id).then((res) => {
        setTelegramInfo(res);
      });
    }
  }, [data]);

  useEffect(() => {
    if (users.length > 0) {
      setUserOptions(
        users
          .filter((user) => user.role !== 'influencer' || !user.deleted_at)
          .map((user) => {
            return {
              value: user.id,
              label:
                user.username +
                ' - ' +
                user.email +
                ' (' +
                user.role.charAt(0).toUpperCase() +
                user.role.slice(1).toLowerCase() +
                ')',
              role: user.role,
              username: user.username,
              email: user.email,
            };
          })
          .sort((a, b) => {
            // Comparar por rol
            if (a.role === 'influencer' && b.role !== 'influencer') {
              return -1;
            }
            if (a.role !== 'influencer' && b.role === 'influencer') {
              return 1;
            }
            // Si ambos tienen el mismo role, ordenar por label
            const roleComparison = a.role.localeCompare(b.role);
            if (roleComparison !== 0) {
              return roleComparison;
            }
            return a.label.localeCompare(b.label);
          })
      );
    }
  }, [users]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setField((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = { ...fields };
    if (data === null) {
      await createUser(form);
      toast.success('Usuario creado', {
        icon: '👌',
      });
    } else {
      const form = { ...fields };
      await updateUser(form);
      toast.success('Usuario actualizado', {
        icon: '👌',
      });
    }
    setField({
      name: '',
      surnames: '',
      username: '',
      email: '',
      telegram_id: '',
      role: 'user',
      password: '',
      joined_by_id: '',
      affiliate_credits_percentage: '',
    });
    closePopup();
  };

  return (
    <>
      <Popup display={display} width={'md:w-6/12 md:min-w-6/12'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <h3 className="text-xl font-semibold dark:text-accent-dark">{data === null ? 'Añadir' : 'Editar'} usuario</h3>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                closePopup();
                setField({
                  bet: '',
                  bookmarker_id: '',
                  state: '',
                  event: '',
                  profit: '',
                  comment: '',
                  stake: '',
                  qualifying_loss: '',
                });
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {/* body */}
        <form
          onSubmit={handleSubmit}
          className="relative px-6 flex-auto bg-white flex flex-wrap child:mb-4 rounded-b-xl">
          <div className="w-full xl:w-6/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="name">
              Nombre
            </label>
            <Input
              id="name"
              width="w-full"
              placeholderMessage={'John'}
              autoFocus
              name="name"
              value={fields.name}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:w-6/12">
            <label className="dark:text-normal-dark" htmlFor="surnames">
              Apellidos
            </label>
            <Input
              id="surnames"
              width="w-full"
              placeholderMessage={'Doe Smith'}
              name="surnames"
              value={fields.surnames}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:pr-4 xl:w-6/12">
            <label className="dark:text-normal-dark" htmlFor="username">
              Username
            </label>
            <Input
              id="username"
              width="w-full"
              placeholderMessage={'john.doe'}
              name="username"
              value={fields.username}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:w-6/12">
            <label className="dark:text-normal-dark" htmlFor="email">
              Email
            </label>
            <Input
              id="email"
              width="w-full"
              placeholderMessage={'johndoe@gmail.com'}
              name="email"
              value={fields.email}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:w-2/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="curso">
              Curso
            </label>
            <div className="mt-2 -ml-3">
              <Switcher id="curso" defaultValue={fields.course} action={(checked) => (fields.course = checked)} />
            </div>
          </div>
          <div className="w-full xl:w-10/12">
            <label htmlFor="observaciones" className="dark:text-normal-dark">
              Observaciones
            </label>
            <Input
              id="comment"
              width="w-full"
              placeholderMessage={'Observaciones'}
              name="comment"
              value={fields.comment}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full">
            <label className="dark:text-normal-dark" htmlFor="affiliate_code">
              Unido por
            </label>
            {userOptions != null && (
              <Autocomplete
                disableClearable
                size="small"
                options={userOptions}
                groupBy={(option) => option.role.charAt(0).toUpperCase() + option.role.slice(1).toLowerCase()}
                sx={{ border: 2, borderRadius: 2, borderColor: '#7a808d' }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > strong': { mr: 2, flexShrink: 0 } }} {...props}>
                    <strong>{option.username}</strong>
                    {option.email}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { fontWeight: 600 },
                    }}
                  />
                )}
                defaultValue={userOptions.find((option) => option.value === fields.joined_by_id)}
                onChange={(e, v) => {
                  handleSelectChange('joined_by_id', v?.value);
                }}
              />
            )}
          </div>
          {data === null && (
            <div className="w-full">
              <label htmlFor="password">Contraseña</label>
              <Input
                id="password"
                width="w-full"
                placeholderMessage={'****'}
                name="password"
                type={'password'}
                value={fields.password}
                onChange={handleChange}
                className="mt-1"
              />
            </div>
          )}
          <div className="w-full xl:w-full">
            <label htmlFor="telegram_id" className="dark:text-normal-dark">
              Telegram Id
            </label>
            <Input
              id="telegram_id"
              width="w-full"
              placeholderMessage={'443083437'}
              name="telegram_id"
              value={fields.telegram_id}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:pr-4 xl:w-4/12">
            <label
              htmlFor="telegram_name"
              className="font-medium text-base text-black-12 mb-1.5 flex dark:text-normal-dark">
              Nombre de telegram
            </label>
            <Input
              id="telegram_name"
              width="w-full"
              name="telegram_name"
              value={fields.telegram_name}
              onChange={handleChange}
              icon={<IconTelegram className="text-xl pr-0.5" />}
              tabIndex="1"
              placeholderMessage="Nombre"
            />
          </div>
          <div className="w-full xl:pr-4 xl:w-4/12">
            <label
              htmlFor="telegram_username"
              className="font-medium text-base text-black-12 mb-1.5 flex dark:text-normal-dark">
              Nombre de usuario de telegram
              {/* <Icon
                  className="stroke-black-7 text-xl mx-2"
                  onClick={() => {
                    setShowHelp(true);
                  }}>
                  <InfoCircle />
                </Icon> */}
            </label>
            <Input
              id="telegram_username"
              width="w-full"
              name="telegram_username"
              value={fields.telegram_username}
              onChange={handleChange}
              icon={<IconTelegram className="text-xl pr-0.5" />}
              tabIndex="1"
              placeholderMessage="Nombre de usuario"
            />
          </div>
          <div className="w-full xl:w-4/12">
            <label
              htmlFor="affiliate_credits_percentage"
              className="font-medium text-base text-black-12 mb-1.5 flex dark:text-normal-dark">
              Porcentaje de créditos de afiliado (%)
            </label>
            <Input
              id="affiliate_credits_percentage"
              width="w-full"
              name="affiliate_credits_percentage"
              value={fields.affiliate_credits_percentage}
              onChange={handleChange}
              tabIndex="1"
              type={'number'}
              placeholderMessage="10"
            />
          </div>
          {telegramInfo != null && (
            <div className="w-full xl:w-full grid grid-cols-3 dark:text-accent-dark dark:bg-">
              <div className="flex justify-start">
                <IconTelegram
                  className={`${
                    telegramInfo.recurrentes == null
                      ? 'stroke-black'
                      : (telegramInfo.recurrentes.status === 'member' || telegramInfo.recurrentes.status === 'creator'
                          ? 'stroke-secondary font-bold'
                          : telegramInfo.recurrentes.status === 'kicked'
                          ? 'stroke-warning-red font-bold'
                          : 'stroke-warning-orange') + ' font-bold'
                  } text-xl cursor-pointer child:mx-auto mr-3`}
                />
                <span>
                  {telegramInfo.recurrentes == null
                    ? 'Id de telegram no válido'
                    : telegramInfo.recurrentes.status === 'member' || telegramInfo.recurrentes.status === 'creator'
                    ? 'En el grupo'
                    : telegramInfo.recurrentes.status === 'kicked'
                    ? 'Expulsado'
                    : 'Ha dejado el grupo'}
                  {' de Recurrentes'}
                </span>
              </div>
              <div className="flex justify-start">
                <IconTelegram
                  className={`${
                    telegramInfo.community == null
                      ? 'stroke-black'
                      : (telegramInfo.community.status === 'member' || telegramInfo.community.status === 'creator'
                          ? 'stroke-secondary font-bold'
                          : telegramInfo.community.status === 'kicked'
                          ? 'stroke-warning-red font-bold'
                          : 'stroke-warning-orange') + ' font-bold'
                  } text-xl cursor-pointer child:mx-auto mr-3`}
                />
                <span>
                  {telegramInfo.community == null
                    ? 'Id de telegram no válido'
                    : telegramInfo.community.status === 'member' || telegramInfo.community.status === 'creator'
                    ? 'En el grupo'
                    : telegramInfo.community.status === 'kicked'
                    ? 'Expulsado'
                    : 'Ha dejado el grupo'}
                  {' de Community'}
                </span>
              </div>
              <div className="flex justify-start">
                <IconTelegram
                  className={`${
                    telegramInfo.charla == null
                      ? 'stroke-black'
                      : (telegramInfo.charla.status === 'member' || telegramInfo.charla.status === 'creator'
                          ? 'stroke-secondary font-bold'
                          : telegramInfo.charla.status === 'kicked'
                          ? 'stroke-warning-red font-bold'
                          : 'stroke-warning-orange') + ' font-bold'
                  } text-xl cursor-pointer child:mx-auto mr-3`}
                />
                <span>
                  {telegramInfo.charla == null
                    ? 'Id de telegram no válido'
                    : telegramInfo.charla.status === 'member' || telegramInfo.charla.status === 'creator'
                    ? 'En el grupo'
                    : telegramInfo.charla.status === 'kicked'
                    ? 'Expulsado'
                    : 'Ha dejado el grupo'}
                  {' de Charla'}
                </span>
              </div>
            </div>
          )}
          <div className="flex items-center justify-start py-3 rounded-b w-full">
            <Button
              className="mr-2"
              size={'large'}
              typeButton="submit"
              disabled={
                fields.name === '' || fields.surnames === '' || fields.username === '' || fields.email === ''
                // || fields.password === ''
              }>
              Guardar
            </Button>
          </div>
        </form>
        {/* footer */}
      </Popup>
    </>
  );
};

export default CreditUserPopup;
