import { useEffect, useState } from 'react';
import { getActiveNotices } from '../../services/notices';
import Moment from 'moment';

const Notices = () => {
  const [notices, setNotices] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await getActiveNotices();
      setNotices(response);
    }
    fetchData();
  }, []);

  return (
    <div className="flex flex-col mb-10" id="header-menu">
      <div className="flex intermedium:child:w-auto mb-5">
        <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Avisos</h1>
      </div>
      <p className="text-black-10 text-sm dark:text-accent-dark mb-3 text-justify">
        Aquí aparecerá cualquier tipo de información que sea relevante <strong>de forma temporal</strong> para todos
        vosotros: incidencias que puedan haber con las casas de apuestas en nuestras herramientas (Dutcher y
        Oddsmatcher), novedades importantes en el funcionamiento de la web, etc.
      </p>

      <p className="font-semibold dark:text-accent-dark">
        {notices?.length > 0
          ? `Actualmente hay ${notices.length} aviso${notices.length > 1 ? 's' : ''}:`
          : 'No hay avisos en este momento.'}
      </p>
      {notices?.length > 0 && (
        <div className="flex flex-wrap gap-6 p-6">
          {notices.map((element, index) => (
            <div
              className="relative bg-yellow-300 p-4 w-full md:w-[16rem] min-h-[16rem] rounded-lg shadow-lg transform rotate-[-3deg]"
              key={index}>
              <div className="absolute top-[-4px] left-[-4px]">
                <div className="w-4 h-4 bg-red-600 rounded-full shadow-md" />
              </div>
              <h3 className="text-lg font-bold mb-2 text-gray-800">{element.title}</h3>
              <p className="text-gray-700 whitespace-pre-line mb-5">{element.description}</p>
              <div className="absolute bottom-2 right-2 text-xs text-gray-600">
                {Moment(element.updated_at).format('DD/MM/YYYY HH:mm')}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notices;
