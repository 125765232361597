import { useAuth } from '../../hooks/auth';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { useEffect, useState } from 'react';
import getProfitTracker, { download, deleteProfitTracker, updateProfitTracker } from '../../services/profitTracker';
import Moment from 'moment';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { ProfitTrackerCard } from '@vilibets/vilibets-uikit/dist/molecules/ProfitTrackerCard/ProfitTrackerCard';
import {
  ArrowRightL,
  ArrowsSwitchVertical,
  EditText,
  File,
  Trash,
  InfoCircle,
  Pin,
} from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { useSearchParams } from 'react-router-dom';
import BookmarkerSelector from '../../utils/BookmarkerSelector';
import AddProfitTrackerPopup from './AddProfitTrackerPopup';
import getBookmarkers from '../../services/bookmarker';
import ProfitTrackerInfoPopup from './ProfitTrackerInfoPopup';
import { toast } from 'react-toastify';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';

const ProfitTracker = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBookmarkers, setSelectedBookmarkers] = useState([]);
  const [profitTracker, setProfitTracker] = useState(null);
  const [profitTrackerPages, setProfitTrackerPages] = useState();
  const [displayAddProfitTrackerPopup, setDisplayAddProfitTrackerPopup] = useState(false);
  const [displayInfoPopup, setDisplayInfoPopup] = useState(false);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [tableRowComment, setTableRowComment] = useState(null);
  const [editableRowData, setEditableRowData] = useState(null);
  const [availabelBookmarkers, setAvailabelBookmarkers] = useState([]);

  // Change table row order
  function changeOrder(column, sortedSide) {
    setSearchParams({ orderby: column, orderby_direction: sortedSide, page_number: 1 });
  }

  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (user) {
      async function fetchFunction() {
        const response = await getBookmarkers(`?user_id=${user.id}`);
        const bookmarker = [];
        response.forEach((element) => {
          bookmarker.push({
            value: element.id,
            label: <img className="h-10 dark:bg-[#f4f7fe]" src={element.logo_url} />,
          });
        });
        setAvailabelBookmarkers(bookmarker);
      }
      fetchFunction();
    }
  }, [user]);

  useEffect(() => {
    if (user && selectedBookmarkers.length > 0) {
      async function fetchData() {
        const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
        setProfitTrackerPages({
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
          per_page: response.per_page,
        });
        setProfitTracker(response.data);
      }
      fetchData();
    }
  }, [searchParams, selectedBookmarkers]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        changePageNumber(1);
      }
      fetchData();
    }
  }, [selectedBookmarkers]);

  // Fetch profittracker data from API
  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getProfitTracker(user.id, window.location.search);
        setProfitTrackerPages({
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
        });
        setProfitTracker(response.data);
      }
      fetchData();
    }
  }, [user]);

  return profitTracker ? (
    <article id="profit-tracker" className="mb-24">
      <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-5">ProfitTracker</h1>
      <section
        id="filters"
        className="flex flex-col w-full intermedium:mb-0 intermedium:child:w-auto intermedium:flex-row justify-between pb-4 child:mb-3">
        <section className="flex gap-2 lg:gap-0 flex-col-reverse lg:flex-row child:lg:mr-2">
          <BookmarkerSelector
            action={(items) => {
              setSelectedBookmarkers(items);
            }}
            items={selectedBookmarkers}
          />
          <Select
            id="orderby"
            border={false}
            width="min-w-full"
            placeholderMessage="Filtrar por estado"
            action={(e) => setSearchParams({ page_number: 1, state: e.value })}
            options={[
              { value: '', label: 'Todos los estados' },
              { value: 'completed', label: 'Completada' },
              { value: 'pending', label: 'Pendiente' },
            ]}
            icon={<ArrowsSwitchVertical />}
          />
          <CalendarInput
            time={false}
            inputClass={
              'drop-shadow-md h-12 items-center border-none font-normal text-black-7 pl-10 cursor-pointer dark:bg-white'
            }
            iconClass={'top-[27%]'}
            action={(e) => {
              filterByDate(e);
            }}
          />
        </section>
        <section className="flex flex-row child:w-1/2 intermedium:child:w-auto">
          <Button
            className="mr-2"
            type="grey"
            outline={true}
            size="medium"
            action={async () => {
              await toast.promise(download(user), {
                pending: 'Exportando ProfitTracker...',
                success: {
                  render() {
                    return 'Exportación finalizada!';
                  },
                  // other options
                  icon: '🟢',
                },
                error: 'Error en la descarga 🤯',
              });
            }}
            icon={
              <Icon>
                <File />
              </Icon>
            }>
            Exportar
          </Button>
          <Button
            type="secondary"
            size="medium"
            action={() => setDisplayAddProfitTrackerPopup(true)}
            icon={
              <Icon className="stroke-white text-2xl">
                <File />
              </Icon>
            }>
            Añadir Bono
          </Button>
        </section>
      </section>
      <Table
        tableClass="w-full hidden lg:table"
        changeOrder={(e, c) => changeOrder(e, c)}
        rowsData={prepareTableData()}
        colsData={[
          {
            Header: 'Fijar',
            accessor: 'pin',
          },
          {
            Header: 'Fecha/Hora',
            accessor: 'created_at',
            sortable: true,
          },
          {
            Header: 'Promoción',
            accessor: 'event',
            sortable: true,
          },
          {
            Header: 'Tipo de promoción',
            accessor: 'bet',
            sortable: true,
          },
          {
            Header: 'Casa de Apuestas',
            accessor: 'bookmarker_id',
            sortable: true,
          },
          {
            Header: 'Estado',
            accessor: 'state',
            sortable: true,
          },
          {
            Header: 'Pérdida calificante',
            accessor: 'qualifying_loss',
            sortable: true,
            align: 'center',
            cellColor: 'warning-red-2 dark:bg-white',
          },
          {
            Header: 'Ganancia total',
            accessor: 'stake',
            sortable: true,
            align: 'center',
            cellColor: 'secondary-2 dark:bg-white',
          },
          {
            Header: 'Beneficio',
            accessor: 'profit',
            sortable: true,
            align: 'center',
            cellColor: 'warning-green-2 dark:bg-white',
          },
          {
            Header: 'Acciones',
            accessor: 'actions',
          },
        ]}
      />
      <section className="lg:hidden">
        {profitTracker.map((element, index) => (
          <ProfitTrackerCard
            key={index}
            className="mb-4"
            created_at={
              <div className="flex flex-row justify-center">{Moment(element.created_at).format('DD/MM/YYYY')}</div>
            }
            event={element.event}
            bet={translateType(element.type)}
            bookmarker={
              <img className="max-h-[48px] mx-auto dark:bg-[#f4f7fe]" src={`${element.bookmarker.logo_url}`} />
            }
            state={element.state}
            stake={element.stake}
            qualifying_loss={element.qualifying_loss}
            profit={element.profit}
            actions={generateActionButtons(element, true)}
          />
        ))}
      </section>
      <section id="paginator" className="mt-5 flex flex-row justify-between">
        <section id="numbers">
          {generatePaginatorRange(profitTrackerPages).map((element) =>
            profitTrackerPages.current_page === element ? (
              <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                {element}
              </button>
            ) : (
              <button
                onClick={() => changePageNumber(element)}
                className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                {element}
              </button>
            )
          )}
        </section>
        {profitTrackerPages.current_page < profitTrackerPages.last_page && (
          <button
            onClick={() => changePageNumber(profitTrackerPages.current_page + 1)}
            className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
            Siguiente{' '}
            <Icon className="text-xl stroke-black pl-2">
              <ArrowRightL />
            </Icon>
          </button>
        )}
      </section>
      <AddProfitTrackerPopup
        display={displayAddProfitTrackerPopup}
        closePopup={async () => {
          setDisplayAddProfitTrackerPopup(!displayAddProfitTrackerPopup);
          setEditableRowData(null);
          const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
          setProfitTrackerPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setProfitTracker(response.data);
        }}
        data={editableRowData}
        bookmarkers={availabelBookmarkers}
      />
      <ProfitTrackerInfoPopup
        display={displayInfoPopup}
        closePopup={() => {
          setDisplayInfoPopup(!displayInfoPopup);
        }}>
        {tableRowComment}
      </ProfitTrackerInfoPopup>
      <PopupDelete
        display={displayDeletePopup}
        item={deleteItem}
        closePopup={async () => {
          setDisplayDeletePopup(!displayDeletePopup);
          const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
          setProfitTrackerPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setProfitTracker(response.data);
        }}
        title={deleteItem.event}
        action_cancel={() => {
          setDisplayDeletePopup(false);
        }}
        action_delete={async () => {
          await deleteProfitTracker(deleteItem.id);
          setDisplayDeletePopup(false);
          const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
          setProfitTrackerPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setProfitTracker(response.data);
        }}
      />
    </article>
  ) : (
    <Loading />
  );

  function generatePaginatorRange(profitTrackerPages) {
    const pages = [];
    if (profitTrackerPages.total > 0) {
      if (profitTrackerPages.per_page < profitTrackerPages.total) {
        if (profitTrackerPages.current_page > 1) {
          for (
            let i = profitTrackerPages.current_page - 1;
            i <= profitTrackerPages.last_page && i < profitTrackerPages.current_page + 3;
            i++
          ) {
            pages.push(i);
          }
        } else {
          for (
            let i = profitTrackerPages.current_page;
            i <= profitTrackerPages.last_page && i < profitTrackerPages.current_page + 3;
            i++
          ) {
            pages.push(i);
          }
        }
      }
    }
    return pages;
  }

  function prepareTableData() {
    if (profitTracker) {
      const rowsData = [];
      profitTracker.forEach((element) => {
        rowsData.push({
          pin: (
            <button
              title={element.pinned ? 'Desfijar de arriba' : 'Fijar arriba'}
              onClick={async () => {
                element.pinned = element.pinned ? 0 : 1;
                await updateProfitTracker(element);
                toast.success(element.pinned ? 'Bono fijado' : 'Bono desfijado', {
                  icon: '👌',
                });
                const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
                setProfitTrackerPages({
                  current_page: response.current_page,
                  last_page: response.last_page,
                  total: response.total,
                  per_page: response.per_page,
                });
                setProfitTracker(response.data);
              }}>
              <Icon className="stroke-black text-2xl">{element.pinned ? <Pin fill="#7A808D" /> : <Pin />}</Icon>
            </button>
          ),
          created_at: <div className="flex flex-col leading-5">{Moment(element.created_at).format('DD/MM/YYYY')}</div>,
          event: element.event,
          bet: translateType(element.bet),
          bookmarker_id: <img className="w-[90px] mx-auto dark:bg-[#f4f7fe]" src={`${element.bookmarker.logo_url}`} />,
          state: translateState(element.state),
          stake: (
            <span
              className={`font-bold text-center text-xs mx-auto ${
                element.stake >= 0 ? 'text-secondary' : 'text-warning-red'
              }`}>
              {element.stake ? `${element.stake} €` : 'Variable'}
            </span>
          ),
          qualifying_loss: (
            <span
              className={`font-bold text-center text-xs mx-auto ${element.qualifying_loss ? 'text-warning-red' : ''}`}>
              {element.qualifying_loss ? `${element.qualifying_loss} €` : '-'}
            </span>
          ),
          profit: (
            <span
              className={`font-bold text-center text-xs mx-auto ${
                element.profit >= 0 ? 'text-warning-green' : 'text-warning-red'
              }`}>
              {element.profit ? `${element.profit} €` : '-'}
            </span>
          ),
          actions: generateActionButtons(element, false),
        });
      });
      return rowsData;
    }
  }

  function translateState(state) {
    switch (state) {
      case 'completed':
        return (
          <span className="rounded-2xl bg-secondary px-4 py-2 text-white font-bold text-center w-full block">
            Completada
          </span>
        );
      case 'pending':
        return (
          <span className="rounded-2xl bg-warning-yellow-5 px-4 py-2 text-white font-bold text-center w-full block">
            Pendiente
          </span>
        );
      default:
        return (
          <span className="rounded-2xl bg-warning-yellow-5 px-4 py-2 text-white font-bold text-center w-full block">
            Pendiente
          </span>
        );
    }
  }

  function translateType(type) {
    switch (type) {
      case 'refund':
        return 'Reembolso';
      case 'bet_and_receive_with_condition':
        return 'Apuesta y recibe con condiciones';
      case 'enhanced_quota':
        return 'Cuota mejorada';
      case 'refund_with_conditions':
        return 'Reembolso con condiciones';
      case 'bet_and_receive':
        return 'Apostar y recibir';
      case 'rollover_bonus':
        return 'Bonus rollover';
      case 'deposit_and_receive':
        return 'Depositar y recibir';
      case 'superior_bonuses':
        return 'Bonos superiores';
      case 'slots':
        return 'Slots';
      case 'cashbacks':
        return 'Cashbacks';
      case 'combined':
        return 'Combinada';
      default:
        return 'Otros';
    }
  }

  function generateActionButtons(element, showPin) {
    return (
      <section className="flex flex-row justify-around">
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
        <button
          onClick={() => {
            setEditableRowData(element);
            setDisplayAddProfitTrackerPopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
        <button
          onClick={() => {
            if (element.comment) {
              setTableRowComment(element.comment);
              setDisplayInfoPopup(true);
            }
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark">
          <Icon className={`${element.comment ? 'stroke-primary' : 'stroke-black cursor-not-allowed'} text-2xl`}>
            <InfoCircle />
          </Icon>
        </button>
        {showPin && (
          <button
            title={element.pinned ? 'Desfijar de arriba' : 'Fijar arriba'}
            onClick={async () => {
              element.pinned = element.pinned ? 0 : 1;
              await updateProfitTracker(element);
              toast.success(element.pinned ? 'Bono fijado' : 'Bono desfijado', {
                icon: '👌',
              });
              const response = await getProfitTracker(user.id, window.location.search, selectedBookmarkers);
              setProfitTrackerPages({
                current_page: response.current_page,
                last_page: response.last_page,
                total: response.total,
                per_page: response.per_page,
              });
              setProfitTracker(response.data);
            }}
            className="ml-2">
            <Icon className="stroke-black text-2xl">{element.pinned ? <Pin fill="#7A808D" /> : <Pin />}</Icon>
          </button>
        )}
      </section>
    );
  }

  function filterByDate(date) {
    if (date) {
      searchParams.set('created_at', Moment(date).format('YYYY-MM-DD'));
      setSearchParams(searchParams);
    } else {
      searchParams.delete('created_at');
      setSearchParams(searchParams);
    }
  }
};

export default ProfitTracker;
