import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Textarea } from '@vilibets/vilibets-uikit/dist/atoms/Textarea';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { createNotice, updateNotice } from '../../services/notices';
import { toast } from 'react-toastify';

const AddEditNoticePopup = ({ display, closePopup, data = null }) => {
  const [fields, setField] = useState(
    data === null
      ? {
          title: '',
          description: '',
        }
      : data
  );
  useEffect(() => {
    if (data !== null) {
      setField(data);
    }
  }, [data]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data === null) {
      await createNotice({ title: fields.title, description: fields.description });
      toast.success('Aviso creado correctamente.', {
        icon: '👌',
      });
    } else {
      await updateNotice({ id: fields.id, title: fields.title, description: fields.description });
      toast.success('Aviso actualizado correctamente.', {
        icon: '👌',
      });
    }
    setField({
      title: '',
      description: '',
    });
    closePopup();
  };

  return (
    <>
      <Popup display={display} width={'md:min-w-[40%]'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <h3 className="text-xl font-semibold dark:text-accent-dark">{data === null ? 'Añadir' : 'Editar'} aviso</h3>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                closePopup();
                setField({
                  title: '',
                  description: '',
                });
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {/* body */}
        <form
          onSubmit={handleSubmit}
          className="relative px-6 flex-auto bg-white flex flex-wrap child:mb-4 rounded-b-xl">
          <div className="w-full md:w-6/12 pr-0 xl:w-6/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="event">
              Título
            </label>
            <Input
              id="title"
              width="w-full"
              placeholderMessage={'Título del aviso'}
              autoFocus
              name="title"
              value={fields.title}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full">
            <label className="dark:text-normal-dark" htmlFor="event">
              Contenido
            </label>
            <Textarea
              id="description"
              name="description"
              type="small"
              placeholderMessage={'Escribe el contenido del aviso...'}
              value={fields.description}
              onChange={handleChange}
              width={'w-full'}
            />
          </div>
          {data?.deleted_at && (
            <div htmlFor="warning_deleted" className="flex flex-row text-sm text-warning-red">
              <Icon className="stroke-warning-red text-2xl">
                <InfoCircle />
              </Icon>
              <span>
                &nbsp;Al actualizar el aviso se eliminará la fecha de borrado y pasar a estar <strong>ACTIVO</strong>.
              </span>
            </div>
          )}
          <div className="flex items-center justify-start py-3 rounded-b w-full">
            <Button
              className="mr-2"
              size={'large'}
              typeButton="submit"
              disabled={fields.title === '' || fields.description === ''}>
              {data === null ? 'Crear aviso' : 'Actualizar aviso'}
            </Button>
          </div>
        </form>
        {/* footer */}
      </Popup>
    </>
  );
};

export default AddEditNoticePopup;
