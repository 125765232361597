import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function FiscalidadEnApuestasDeportivas() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={'Fiscalidad en las apuestas deportivas'} />

      <p>
        Una de las dudas más recurrentes del apostante común es si debe declarar sus ganancias en la renta y bajo qué
        circunstancias. La casuística puede ser de gran amplitud, pero existen unas ideas generales que pueden serte de
        ayuda en la mayoría de los casos. Esto es lo que queremos explicar hoy en este artículo.
      </p>
      <p>
        Pero antes de entrar en materia es importante diferenciar entre obligación de declarar y obligación de pagar
        impuestos. Una cosa es tener la obligación de presentar la declaración y otra muy distinta es tener la
        obligación de devolver dinero a Hacienda (pagar).
      </p>
      <p>
        Existen casos (y no pocos) en los cuales tienes la obligación de presentar la declaración (por superar los
        umbrales) y no tener que pagar impuestos (por ejemplo, te han retenido más de la cuenta o tu situación te
        exonera de pagar) y la situación contraria, no tener la obligación de presentar la declaración y si la de pagar
        (aunque esta es menos común). Esta diferenciación es una cuestión técnica, pero es muy importante conocerla, ya
        que en las próximas líneas iremos mezclando ambos conceptos y comprender la diferencia es relevante para
        entender los diferentes casos.
      </p>

      <h2>Conozcamos un poco más al IRPF.</h2>
      <p>
        El IRPF es un impuesto que grava la renta de las personas físicas y lo hace con progresividad, es decir, según
        aumenta la renta por la que se tributa el porcentaje aplicable va siendo mayor. Es decir, cuánto más ganas, más
        pagas.
      </p>
      <p>
        Como características esenciales, diríamos que es un impuesto <strong>directo</strong> (grava la renta personal
        que en esencia es la que define tu capacidad económica) <strong>personal</strong> (gradúa la carga tributaria
        según tus circunstancias personales y familiares) y <strong>progresivo</strong> (que significa que generalmente
        cuánto más ganas, más pagarás).
      </p>
      <p>
        El IRPF es también un <strong>impuesto dual</strong>, grava por un lado los rendimientos de trabajo (base
        general) y por otro lado lo que se conoce como la base del ahorro, que grava los rendimientos mobiliarios (por
        ejemplo, cobro de dividendos o plusvalías derivadas de la compraventa de acciones).
      </p>
      <p>
        Para el caso que nos ocupa, la tributación de los beneficios derivados de las apuestas deportivas tal y como ha
        reconocido en varias consultas la DGT(1) han sido reconocidas como ganancias patrimoniales, es decir se trata de
        “una variación en el valor del patrimonio del contribuyente que se pongan de manifiesto con ocasión de cualquier
        alteración en la composición de aquel, salvo que por esta Ley se califiquen como rendimientos”(2) y como tales,
        tributarán por la base general del trabajo, sin posibilidad de aplicar ningún tipo de deducción por gastos.
      </p>

      <h2>Ámbito de aplicación</h2>
      <p>
        Es un tributo que se aplica en España, pero no en todas las comunidades autónomas por igual. Por ejemplo, si te
        encuentras en el País Vasco o Navarra estas comunidades tienen un sistema de IRPF “distinto” debido a sus
        beneficios forales. Por tanto, si es tu caso es vital saberlo y ver sus diferencias con un asesor fiscal
        especializado en el tema.
      </p>

      <p>
        Pero esto no es todo, además de estas excepciones también debes saber que, en el resto del territorio común,
        aunque estamos hablando de una carga estatal, algunos elementos del impuesto pueden ser regulados de manera
        distinta en las diferentes comunidades autónomas.
      </p>
      <p>
        En resumidas cuentas y sin entrar en más detalles, vemos que se trata de un impuesto de carácter estatal, pero
        con competencias delegadas a las diferentes CCAA que aplicados a cada contribuyente puede hacer que los
        resultados sean distintos entre uno y otros. La primera idea que me gustaría transmitiros es que depende de
        dónde vivas y cuáles sean tus circunstancias personales tu obligación puede cambiar.
      </p>
      <p>
        Por tanto, la mejor contestación a quién te pregunta si debes o no tributar por tus ganancias deportivas es
        contestar: Depende de tus circunstancias.
      </p>

      <h2>Líneas generales</h2>
      <p>
        Una vez delimitado el impuesto y cómo son consideradas las apuestas deportivas vamos a ver cómo y cuándo
        tendremos que declarar estas ganancias en apuestas deportivas y si nos tocará o no pagar impuestos por ellas,
        así como de manera somera contestar las dudas más comunes acerca de este impuesto.
      </p>

      <h2>¿Cuándo estoy obligado a DECLARAR?</h2>
      <p>
        Una persona que se dedica a la inversión de las apuestas deportivas y tiene beneficios estará obligado a
        presentar la declaración de la renta cuando supere el límite de 1.000 € en conjunto de sus ganancias totales.
        Solamente estarán excluidos cuando obtengan, exclusivamente, rendimientos del trabajo, del capital mobiliario o
        inmobiliario, de actividades económicas o ganancias patrimoniales (con o sin retención) cuyo importe conjunto{' '}
        <strong>no supere la cantidad de 1.000 euros anuales,</strong> así, como en su caso, pérdidas patrimoniales en
        cuantía inferior a 500 euros, en tributación individual o conjunta.
      </p>

      <p>
        Por tanto, aquí la AEAT nos está diciendo que si ganas más de 1.000 € entre tu trabajo y las apuestas
        deportivas, tienes la obligación de declarar.
      </p>

      <p>
        <strong>Ejemplo</strong>: Ganas solo 800 € anuales en tu trabajo, pero 300 € en apuestas deportivas.
      </p>
      <p>
        <strong>Respuesta</strong>: Tendrás la obligación de declarar al superar tus ganancias los 1.000 € en conjunto.
      </p>

      <p>
        Por tanto, esa frase de “solo se declaran los beneficios en apuestas que superen los 1.000 €” es una falacia.
        Por tanto, aquí derruimos parte del mito que rodea este impuesto entre los contribuyentes.
      </p>
      <p>Solo estarías exento de declarar si tus ingresos no superan los 1.000 € enConjunto.</p>
      <p>
        En resumidas cuentas y en el 99% de los casos, si trabajas vas a tener que meter en tu declaración de la renta
        todos los beneficios que deriven de las apuestas, desde el primer euro.
      </p>
      <h2>¿Cuánto me tocará PAGAR impuestos?</h2>

      <p>
        Como venimos diciendo a lo largo del artículo no es lo mismo tener la obligación de declarar que la de pagar
        impuestos. De hecho, se dan casos en lo que se tiene la obligación de hacer la declaración, pero no de pagar
        impuestos.
      </p>

      <p>
        <strong>Ejemplo</strong>: Estudiante, soltero y sin hijos que no tenga ninguna renta de trabajo, pero que
        gane3.000 € en apuestas.
      </p>
      <p>
        <strong>Respuesta</strong>: Si tendría que realizar la declaración de la renta (al superar ese límite conjunto
        de 1.000 €) pero no pagaría impuestos, ya que esos 3.000 € son inferiores a la cifra del mínimo personal y
        familiar (5.500 €) y por tanto están exentos de pagar impuestos.
      </p>

      <h2>¿Qué es esto del mínimo personal y familiar?</h2>
      <p>
        Es una cantidad establecida por la AEAT que permite cuantificar aquella parte de la renta que, por destinarse a
        satisfacer las necesidades básicas personales y familiares del contribuyente no se somete a ningún tipo de
        tributación. Es un límite que técnicamente funciona como un tramo cero y es una cantidad que se resta de la base
        imponible del IRPF.
      </p>
      <p>Su cuantía es de 5.500 € aunque puede verse incrementada por las CCAA (3)</p>

      <p>
        <strong>Ejemplo</strong>: Joven que compagina estudios con el trabajo por el cual percibe un salario de 11.500 €
        anuales y gana 800 € en apuestas deportivas.
      </p>
      <p>
        <strong>Respuesta</strong>: Si tendría la obligación de declarar por sus rendimientos, ya que en conjunto supera
        los 1.000 € de ganancias totales, por lo que la base general habría que sumar a los rendimientos del trabajo las
        ganancias obtenidas por las apuestas deportivas.
      </p>

      <h2>¿Tengo que declarar si no retiro el dinero de las casas de apuestas?</h2>
      <p>
        El hecho de retirar el dinero o no retirarlo de las casas de apuestas es totalmente irrelevante para el cálculo
        de las ganancias. Por tanto, la respuesta es sí y lo explica la DGT en una de sus consultas (4):{' '}
        <strong>
          “deberá tener en cuenta las ganancias y pérdidas obtenidas en el ejercicio, resultando irrelevante, a estos
          efectos, si el contribuyente ha retirado o no cantidades de la cuenta abierta con el operador de juego
          “online”.
        </strong>
      </p>

      <h2>¿En qué ámbito temporal se tributan las ganancias patrimoniales derivadas de las apuestas?</h2>
      <p>
        La misma consulta anterior soluciona esta duda. El ámbito temporal es el periodo impositivo en que tenga lugar
        esa alteración patrimonial (el periodo en el que ganes) y que a efectos de IRPF corresponde al año natural. Para
        el cálculo de dichas ganancias se aplica una fórmula muy sencilla que más adelante explicaremos.
      </p>

      <h2>¿Me puedo deducir el coste de Vilibets u otros servicios de mis ganancias?</h2>
      <p>
        La respuesta es que NO. La DGT resolvió en una consulta en 2016 y dijo textualmente: “Respecto al cómputo de las
        pérdidas y ganancias patrimoniales obtenidas en el juego cabe señalar que tal cómputo se establece a un nivel
        global, en cuanto a las obtenidas por el contribuyente a lo largo de un mismo período impositivo y en relación
        estricta con los importes ganados o perdidos en las apuestas o juegos, sin intervenir en ese cómputo ningún otro
        concepto distinto al de la propia ganancia o pérdida, por lo que los honorarios de los pronosticadores no tienen
        incidencia en la determinación del importe de las variaciones patrimoniales derivadas del juego.
      </p>

      <h2>¿Cómo tributan los bonos que nos dan las casas de apuestas?</h2>
      <p>
        Uno de los puntos que pueden llevar a confusión es si los bonos que nos ofrecen las casas de apuestas se deben
        incluir dentro de la declaración. La respuesta es un rotundo NO.
      </p>
      <p>
        Dentro del resumen anual que nos facilitan las casas de apuestas no están incluidos los bonos y solo figuran los
        beneficios que se obtienen de ellos.
      </p>
      <p>
        No tendría mucho sentido declarar por un bono de 200 € si lo has perdido. Solo se declarará por la ganancia
        derivada de la liberación del bono y en cuanto pase a ser 100% dinero real y retirable.
      </p>

      <h2>Tablas IRPF</h2>
      <p>
        La tributación final dependerá de la superación o no de ciertos límites (como por ejemplo el mínimo personal) y
        de la situación personal de cada individuo además de las otras ganancias que se obtengan.
      </p>
      <p>
        Se calcula aplicando unos tramos a la{' '}
        <strong>
          base liquidable del IRPF = (base imponible (todos los ingresos en bruto) – deducciones/reducciones)
        </strong>
      </p>
      <p>
        Para saber qué tipo impositivo pagarás por tus rendimientos económicos tienes que aplicar una tabla que se
        publica cada año por Hacienda. Existen ciertas creencias erróneas acerca de cómo se calculan los tramos del
        IRPF.
      </p>
      <p>
        Por ejemplo, se puede escuchar aquello de que “cuidado, si te suben el sueldo acabarás cobrando menos porque
        saltas de tramo”. Mucho cuidado con esto, porque es un error muy importante.
      </p>
      <p>El pago se realiza de manera escalada y va por tramos. Veamos un ejemplo:</p>
      <p>
        Ismael que es soltero y sin hijos, cobra 20.000 € en su trabajo y gana 3.500 € en apuestas. Si hacemos un
        cálculo sencillo (sin tener en cuenta más circunstancias) su base imponible sería de 23.500 €. Su base
        liquidable sería aproximadamente de 16.000 € (23.500 € - 5.500 € mínimo personal y familiar) y quiere saber
        cuánto pagará por IRPF. Para saberlo solo tienes que aplicar la tabla siguiente: (5)
      </p>
      <div align="center">
        <table width="306" cellSpacing="0" cellPadding="0" border="1" style={{ width: '94%', margin: '35px 3%' }}>
          <tbody>
            <tr>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center" style={{ fontWeight: '800' }}>
                  Tramos IRPF 2020
                </p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center" style={{ fontWeight: '800' }}>
                  Tipos a aplicar
                </p>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'rgba(180,180,255,0.6)' }}>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">Desde 0 hasta 12.450 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">19%</p>
              </td>
            </tr>
            <tr>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">De 12.450 € a 20.200 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">24%</p>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'rgba(180,180,255,0.6)' }}>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">De 20.200 € a 35.200 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">30%</p>
              </td>
            </tr>
            <tr>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">De 35.200 € a 60.000 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">37%</p>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'rgba(180,180,255,0.6)' }}>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">De 60.000 € a 300.000 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">45%</p>
              </td>
            </tr>
            <tr>
              <td width="171" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">Más de 300.000 €</p>
              </td>
              <td width="135" valign="top" style={{ border: '1px solid #ddd', padding: '8px' }}>
                <p align="center">47%</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>En el ejemplo propuesto, si su base liquidable son 16.000 € se haría de la siguiente forma:</p>
      <p>- En el primer tramo, aplicaríamos el 19% de retención sobre los primeros 12.450 €</p>
      <p>
        Pero como la base son 16.000 € tenemos que saltar al segundo tramo y restaríamos (16.000 – 12.450 € = 3.550 €).
        Estos 3.550 € es la cantidad excedente que tributaría en el segundo tramo a un 24%.
      </p>
      <p>
        Como veis en este ejemplo, el cálculo se realiza por tramos por lo que pasar de cobrar 16.000 € a 20.300 € no
        supone ningún “hachazo” de Hacienda, ya que de ese incremento solo pasaría de tramo 100 € y no el total del
        salario bruto como erróneamente entienden muchas personas.
      </p>
      <h2>¿Tiene la casa de apuestas la obligación de emitir un certificado de nuestras ganancias?</h2>
      <p>
        Muchos usuarios se preguntan si necesitan algún tipo de acreditación por parte de la casa de apuestas que
        certifique sus ganancias o pérdidas y si esta está en la obligación de otorgarlas al usuario. La respuesta la
        encontramos en otra consulta de la DGT (6).
      </p>
      <p>
        “La normativa del IRPF no recoge ninguna obligación expresa de emisión de certificados por parte de la casa de
        apuestas (operador habilitado) y a nombre del cliente apostante, a efectos de la realización de su declaración
        del impuesto”
      </p>
      <p>
        Por tanto, deberá ser el jugador quién recabe esa información al respecto y certifique con los medios que estén
        a su alcance que los datos son los correctos. El periodo que deben ser guardados por parte del apostante por
        posibles inspecciones es de 5 años.
      </p>
      <p>
        A pesar de eso, la mayoría de casas facilitan esta información por bajo demanda del cliente o directamente en su
        web.
      </p>
      <h2>¿Puede Hacienda saber mis ganancias?</h2>
      <p>
        Desde el año 2016 las casas de apuestas con título habilitante tienen la obligación de notificar a Hacienda las
        ganancias pagadas a cada contribuyente. Es decir, que Hacienda sabe lo que ganas o pierdes casi al céntimo.
      </p>
      <p>
        No intentes declarar de menos porque Hacienda sabe exactamente lo que ganas y si el inspector de hacienda puede
        cobrar un buen bonus de tu declaración, que no te quepa duda que te harán una inspección.
      </p>
      <h2>¿Qué pasaría si no declaro mis ganancias? ¿Me pueden multar?</h2>
      <p>
        Pues sí y además sanciones cuantiosas. En caso de que la agencia tributaria descubra que le hemos ocultado
        información la multa podría ser de la cantidad no pagada más un incremento de entre el 50% y el 150% (depende de
        la gravedad y el posible dolo).
      </p>
      <p>
        En el caso de que no se declare los rendimientos a pesar de no tener que pagar impuestos, también puedes ser
        sancionado, con hasta incluso 200 €. Es decir, si no tienes otros ingresos y ganas 3,000 € en apuestas, aunque
        vayas a pagar 0 € de impuestos, si no declaras esas cantidades te arriesgas a ser multado por la AEAT.
      </p>
      <h2>¿Cómo calculo las ganancias en apuestas deportivas?</h2>
      <p>
        Deberás aplicar una fórmula para calcular tus ganancias anuales, los importes deberás extraerlos de los informes
        anuales que te facilitarán las casas de apuestas.&nbsp;
      </p>
      <p>
        <em>Saldo a 31 de diciembre - Saldo a 1 de Enero - Ingresos + Retiradas</em>
      </p>
      <p>
        En terminos generales, casi el 90% de las casas de apuestas que utilizamos para el Matched Betting facilita
        estos valores de forma más o menos ordenada. Alguna casas como Interwetten, Betfair o Betway reportan pequeñas
        complicaciones al momento de facilitar los datos.
      </p>
      <p>
        Estos problemas pueden ser, falta de datos (saldo inicial/saldo final caso de Interwetten) demora en facilitar
        los datos (más de un mes en el caso de Betway) o exceso de información (Betfair facilita todas las
        transacciones, con cada apuesta detallada).
      </p>
      <div className="blueSection">
        <p className="">
          Actualización (Parece ser que Betfair ya ofrece un resumen con solo los valores que sirven para la
          declaración)
        </p>
      </div>
      <h2>Conclusiones finales</h2>
      <p>
        En resumidas cuentas, se podría decir que si tienes un trabajo y además ganas dinero con las apuestas deportivas
        estás en la obligación de declararlo a Hacienda (que no es lo mismo que tributar por ello). Solo en los casos en
        que seas una persona sin ningún ingreso podrás beneficiarte del sistema, ya que podrás ganar hasta 5.500 € y no
        tener que pagar ningún impuesto por esas ganancias. En los demás casos (superando los 1.000 € en conjunto) la
        obligación de declarar será efectiva y dependerá como hemos dicho de tu situación personal y la CCAA donde
        residas pagar más o menos impuestos.
      </p>
      <p>
        Como nota final, me gustaría hacer una pequeña reflexión sobre la integración de las apuestas deportivas como
        una ganancia patrimonial en vez de un rendimiento de capital mobiliario. Es un sistema muy desfavorable para el
        apostante, ya que al tributarse como una simple ganancia patrimonial no puedes deducirte ningún gasto por lo si
        ganas vas a pagar y no puedes deducirte pérdidas para ahorrar la factura fiscal. Si por ejemplo encadenas
        simultáneamente años de ganancias y otros de pérdidas, la situación es muy poco ventajosa.
      </p>
    </section>
  );
}
