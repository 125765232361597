import { Navigate, Outlet } from 'react-router-dom';

const RoutesAdminWorkers = ({ role, redirectPath = '/login' }) => {
  if ((role !== 'admin' && role !== 'worker') || role === undefined) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default RoutesAdminWorkers;
