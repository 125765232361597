import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';

const ProfitTrackerInfoPopup = ({ display, closePopup, children }) => {
  return (
    <Popup display={display} width={'md:min-w-[40%]'}>
      <div className="flex items-start justify-between p-5 rounded-t">
        <h3 className="text-xl font-semibold dark:text-accent-dark">Comentario</h3>
        <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
          <span
            onClick={() => closePopup()}
            className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin dark:text-accent-dark">
            ×
          </span>
        </button>
      </div>
      <section className="px-5 pb-6 flex-auto dark:text-accent-dark">{children}</section>
    </Popup>
  );
};

export default ProfitTrackerInfoPopup;
