/* eslint-disable */
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import {
  IconWallet,
  Paypal,
  Stripe,
  SubscriptionEuro,
  UserGroup,
  Copy,
} from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { PricingText } from '@vilibets/vilibets-uikit/dist/atoms/Pricing';
import { Radiobox } from '@vilibets/vilibets-uikit/dist/atoms/Radiobox';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import getRates from '../../services/rates';
import { createSubscription, useCreditsForSubscription } from '../../services/subscriptions';
import getPaymentMethods from '../../services/paymentMethods';
import { useStripe } from '@stripe/react-stripe-js';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FeaturedGuidesText from '../../components/FeaturedGuidesText';

const PopupSubscription = ({ display, closePopup, credits, ...props }) => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [fields, setField] = useState({ payment_method: 'transfer' });
  const [rates, setRates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [useCredits, setUseCredits] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [discount, setDiscount] = useState(null);

  const navigate = useNavigate();

  const stripe = useStripe();

  useEffect(() => {
    const tmpUseCredits = credits !== undefined;
    setUseCredits(tmpUseCredits);
    async function fetchFunction() {
      // Get payment methods
      if (!tmpUseCredits) {
        const response_methods = await getPaymentMethods();
        setPaymentMethods(response_methods);
      } else {
        setField({ ...fields, payment_method: '' });
      }
      const response = await getRates();
      setRates(response);
    }
    fetchFunction();
  }, []);

  useEffect(() => {
    if (paymentMethods.length) {
      setDiscount(paymentMethods.find((p) => p.payment_name === 'transfer').discount);
    }
  }, [paymentMethods]);

  const handleScroll = (e) => {
    if (window.innerWidth < 768) {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        // hide bottom-arrow-animation
        document.getElementById('bottom-arrow-animation').classList.add('hidden');
      } else {
        // show bottom-arrow-animation
        document.getElementById('bottom-arrow-animation').classList.remove('hidden');
      }
    }
  };

  return (
    user &&
    rates &&
    display && (
      <section className="flex flex-col gap-3 py-5">
        {/* Select Subscription */}
        <div className="flex justify-between items-center">
          <div className="flex items-start gap-3">
            <Icon>
              <SubscriptionEuro />
            </Icon>
            <h1 className="font-bold text-black-12 dark:text-accent-dark">
              {props.course ? 'Tarifas del curso' : 'Suscripciones'}
            </h1>
          </div>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => closePopup()}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {!useCredits && (
          <>
            {/* Select paymentMethod */}
            <div className="flex items-start justify-center gap-3">
              <Icon>
                <IconWallet />
              </Icon>
              <h1 className="font-bold text-black-12 dark:text-accent-dark">Formas de Pago</h1>
            </div>
            <div className="px-4 gap-3 flex flex-row items-center justify-center mb-2">
              <Radiobox
                action={(e) => {
                  setField({ ...fields, payment_method: e });
                  setDiscount(paymentMethods.find((p) => p.payment_name === e).discount);
                }}
                className="mx-4"
                items={[
                  {
                    id: 'transfer',
                    name: 'Transferencia bancaria',
                    defaultChecked: fields?.payment_method === 'transfer',
                    isDisabled: false,
                    label: 'Transferencia bancaria',
                    defaultValue: 'transfer',
                    badge: paymentMethods.find((p) => p.payment_name === 'transfer')?.discount
                      ? paymentMethods.find((p) => p.payment_name === 'transfer')?.discount + '% descuento'
                      : '',
                  },
                  {
                    id: 'paypal',
                    name: 'Paypal',
                    defaultChecked: fields?.payment_method === 'paypal',
                    isDisabled: props.course,
                    label: 'Paypal',
                    defaultValue: 'paypal',
                    badge: paymentMethods.find((p) => p.payment_name === 'paypal')?.discount
                      ? paymentMethods.find((p) => p.payment_name === 'paypal')?.discount + '% descuento'
                      : '',
                  },
                ]}
              />
            </div>
            {loading && (
              <>
                <Loading />
                <div className="flex flex-col justify-center items-center gap-3">
                  <h1 className="font-bold text-black-12 dark:text-accent-dark">
                    Estamos validando la suscripción. Esto puede tardar unos minutos. Por favor no cierre la página.
                    Gracias por la paciencia.
                  </h1>
                </div>
              </>
            )}
          </>
        )}
        {(useCredits || fields?.payment_method === 'paypal' || fields?.payment_method === 'transfer') && (
          <ul
            id="subscriptionsCards"
            onScroll={handleScroll}
            className="flex flex-row flex-wrap justify-center gap-5 child:mb-4">
            {rates
              .filter((rate) => !props.course || rate.type === 'course') // Filtrado basado en props.course
              .map((rate) => {
                return (
                  <li
                    key={rate.id}
                    className={
                      (fields?.rate_id && fields.rate_id !== rate.id
                        ? 'opacity-40 transition-opacity duration-300 ease-in-out mx-2 bg-gray-200'
                        : 'transition-transform duration-300 scale-105 mx-2') +
                      ((useCredits && rate.total > user.affiliate_credits) ||
                      (user.course && rate.type === 'course') ||
                      (rate.type === 'course' && fields?.payment_method === 'paypal')
                        ? ' pointer-events-none opacity-70'
                        : '')
                    }>
                    <PricingText
                      height="h-full"
                      width="w-full"
                      title={rate.title}
                      badge={discount ? discount + '% descuento' : ''}
                      description={rate.description}
                      price={`${rate.total - (discount ? (rate.total * discount) / 100 : 0)} ${
                        credits !== undefined ? 'créditos' : '€'
                      }`}
                      action={(e) => setField({ ...fields, rate_id: rate.id })}
                      textAnuality={
                        rate.interval ? (rate.months === 1 ? 'mes' : rate.months === 6 ? 'semestre' : 'año') : 'pack'
                      }
                      textMensuality={rate.months !== 1 ? 'mes' : ''}
                      priceMonthly={
                        rate.months !== 1
                          ? ((rate.total - (discount ? (rate.total * discount) / 100 : 0)) / rate.months).toFixed(2)
                          : ''
                      }
                      additionalText={rate.additional_info}
                    />
                  </li>
                );
              })}
            {window.innerWidth < 768 && (
              <div id="bottom-arrow-animation">
                {' '}
                <span />
                <span />
                <span />
              </div>
            )}
          </ul>
        )}
        {useCredits && (
          <Button
            disabled={!fields.rate_id}
            size={'large'}
            action={async () => {
              setLoading(true);
              await useCreditsForSubscription(user.id, fields.rate_id);
              toast.success('Canjeado correctamente');
              closePopup();
              setLoading(false);
            }}
            icon={
              <Icon className={'stroke-white text-2xl'}>
                <UserGroup />
              </Icon>
            }>
            Usar créditos
          </Button>
        )}
        {!useCredits && fields?.payment_method === 'paypal' && (
          <div className="flex flex-col justify-center items-center gap-3">
            <Button
              disabled={!fields.rate_id}
              size={'large'}
              action={async () => {
                setLoading(true);
                const createdSubscription = await createSubscription(user.id, fields.rate_id);
                window.location.replace(createdSubscription.links[0].href);
                setLoading(false);
              }}
              icon={
                <Icon className={'stroke-white text-2xl'}>
                  <Paypal />
                </Icon>
              }>
              {rates.find((r) => r.id === fields?.rate_id)?.type === 'course' || props.course
                ? 'Pagar el curso'
                : 'Activar suscripción'}
            </Button>
          </div>
        )}
        {!useCredits && fields?.payment_method === 'transfer' && (
          <div>
            <p className="mb-2 text-black-12 dark:text-accent-dark">
              Pulsa <strong>Elige tu pack</strong> de tu tarifa elegida y realiza una{' '}
              <strong>transferencia bancaria</strong> con los siguientes datos:
            </p>
            <FeaturedGuidesText>
              {/* Pantallas de móvil  */}
              <div className="block sm:hidden">
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">Importe</span>
                  <p className="text-black-12 dark:text-accent-dark font-bold break-words">
                    {fields?.rate_id ? (
                      rates.find((r) => r.id === fields?.rate_id).total -
                      (discount ? (rates.find((r) => r.id === fields?.rate_id).total * discount) / 100 : 0) +
                      '€'
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">Beneficiario</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {process.env.REACT_APP_TRANSFER_RECIPIENT}&nbsp;
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_RECIPIENT);
                        toast.success('Beneficiario copiado al portapapeles', { autoClose: 1000 });
                      }}>
                      <Copy className="cursor-pointer" />
                    </Icon>
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">BIC</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {process.env.REACT_APP_TRANSFER_BIC}&nbsp;
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_BIC);
                        toast.success('BIC copiado al portapapeles', { autoClose: 1000 });
                      }}>
                      <Copy className="cursor-pointer" />
                    </Icon>
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">IBAN</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {process.env.REACT_APP_TRANSFER_IBAN}&nbsp;
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_IBAN);
                        toast.success('IBAN copiado al portapapeles', { autoClose: 1000 });
                      }}>
                      <Copy className="cursor-pointer" />
                    </Icon>
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">Entidad</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {process.env.REACT_APP_TRANSFER_ENTIDAD}
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">Dirección</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {process.env.REACT_APP_TRANSFER_ADDRESS}
                  </p>
                </section>
                <section className="mb-1">
                  <span className="text-black-12 dark:text-accent-dark">Concepto</span>
                  <p className="flex text-black-12 dark:text-accent-dark font-bold break-words">
                    {user.username}
                    {' - '}
                    {fields?.rate_id ? rates.find((r) => r.id === fields?.rate_id).title : <span>&nbsp;</span>}&nbsp;
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          user.username +
                            ' - ' +
                            (fields?.rate_id ? rates.find((r) => r.id === fields?.rate_id).title : '')
                        );
                        toast.success('Concepto copiado al portapapeles', { autoClose: 1000 });
                      }}>
                      <Copy className="cursor-pointer" />
                    </Icon>
                  </p>
                </section>
              </div>
              {/* Pantallas grandes */}
              <ul className="list-disc mb-2 hidden sm:block">
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  Importe:{' '}
                  <strong>
                    {fields?.rate_id
                      ? rates.find((r) => r.id === fields?.rate_id).total -
                        (discount ? (rates.find((r) => r.id === fields?.rate_id).total * discount) / 100 : 0) +
                        '€'
                      : ''}
                  </strong>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  Beneficiario: <strong>{process.env.REACT_APP_TRANSFER_RECIPIENT}</strong>
                  <Icon
                    onClick={() => {
                      navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_RECIPIENT);
                      toast.success('Beneficiario copiado al portapapeles', { autoClose: 1000 });
                    }}>
                    <Copy className="cursor-pointer" />
                  </Icon>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  BIC: <strong>{process.env.REACT_APP_TRANSFER_BIC}</strong>
                  <Icon
                    onClick={() => {
                      navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_BIC);
                      toast.success('BIC copiado al portapapeles', { autoClose: 1000 });
                    }}>
                    <Copy className="cursor-pointer" />
                  </Icon>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  IBAN: <strong>{process.env.REACT_APP_TRANSFER_IBAN}</strong>
                  <Icon
                    onClick={() => {
                      navigator.clipboard.writeText(process.env.REACT_APP_TRANSFER_IBAN);
                      toast.success('IBAN copiado al portapapeles', { autoClose: 1000 });
                    }}>
                    <Copy className="cursor-pointer" />
                  </Icon>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  Entidad: <strong>{process.env.REACT_APP_TRANSFER_ENTIDAD}</strong>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  Dirección: <strong>{process.env.REACT_APP_TRANSFER_ADDRESS}</strong>
                </li>
                <li className="flex items-start gap-1 text-black-12 dark:text-accent-dark">
                  Concepto:{' '}
                  <strong>
                    {user.username}
                    {' - '}
                    {fields?.rate_id ? rates.find((r) => r.id === fields?.rate_id).title : ''}
                  </strong>
                  <Icon
                    onClick={() => {
                      navigator.clipboard.writeText(
                        user.username +
                          ' - ' +
                          (fields?.rate_id ? rates.find((r) => r.id === fields?.rate_id).title : '')
                      );
                      toast.success('Concepto copiado al portapapeles', { autoClose: 1000 });
                    }}>
                    <Copy className="cursor-pointer" />
                  </Icon>
                </li>
              </ul>
            </FeaturedGuidesText>
            <p className="mb-2">
              Una vez realizado el pago deberás <strong>enviar el comprobante</strong> a{' '}
              {rates.find((r) => r.id === fields?.rate_id)?.type === 'course' ? (
                <>
                  <a href="https://t.me/salla17" target="_blank" rel="noreferrer" className="text-primary">
                    <strong>Adrià (@salla17)</strong>
                  </a>
                </>
              ) : (
                <a href="https://t.me/VilibetsSoporte" target="_blank" rel="noreferrer" className="text-primary">
                  <strong>@VilibetsSoporte</strong>
                </a>
              )}{' '}
              en Telegram para una activación al momento de tu cuenta.
            </p>
          </div>
        )}
        {/* footer */}
      </section>
    )
  );
};

export default PopupSubscription;
