import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { Alert } from '@vilibets/vilibets-uikit/dist/atoms/Alert';
import updateUser from '../../../services/users';
import Settings from '../Settings';

const Me = () => {
  const hasErrorInput = false;
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [inputs, setInputs] = useState({});
  useEffect(() => {
    if (user) {
      setInputs({
        name: user?.name,
        surnames: user?.surnames,
        email: user?.email,
      });
    }
  }, [user]);

  const [errorInput, setError] = useState(hasErrorInput);
  const [showAlert, setAlert] = useState(false);

  useEffect(() => {
    setError(hasErrorInput);
  }, [hasErrorInput]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (errorInput) setError(false);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    user.name = inputs.name;
    user.surnames = inputs.surnames;
    user.email = inputs.email;
    await updateUser(user);
    setAlert(true);
  };

  return (
    user && (
      <Settings>
        <Alert hidden={!showAlert} />
        <h1 className="font-bold text-2xl text-black-12 mb-4 dark:text-accent-dark">Datos personales</h1>

        <form onSubmit={handleSubmit} className="mt-2 max-w-[500px]">
          <fieldset>
            <p className="py-2">
              <label htmlFor="name" className="font-medium text-base text-black-12 block mb-1.5 dark:text-normal-dark">
                Nombre
              </label>
              <Input
                id="name"
                width="w-full"
                name="name"
                value={inputs.name}
                onChange={handleChange}
                hasError={errorInput}
                tabIndex="1"
              />
            </p>
            <p className="py-2">
              <label
                htmlFor="surnames"
                className="font-medium text-base text-black-12 block mb-1.5 dark:text-normal-dark">
                Apellidos
              </label>
              <Input
                id="surnames"
                width="w-full"
                name="surnames"
                value={inputs.surnames}
                onChange={handleChange}
                hasError={errorInput}
                tabIndex="1"
              />
            </p>
            <p className="py-2">
              <label htmlFor="email" className="font-medium text-base text-black-12 block mb-1.5 dark:text-normal-dark">
                E-mail
              </label>
              <Input
                id="email"
                width="w-full"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                hasError={errorInput}
                messageAdvice={'El email no es válido'}
                typeMessageAdvice="warning"
                // eslint-disable-next-line no-useless-escape
                hasMessageAdvice={!/^[a-zA-Z0-9\.\-\_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(inputs.email)}
                tabIndex="1"
              />
            </p>
            <Button
              size="large"
              width="w-full md:w-1/2 md:max-w-[250px]"
              typeButton="submit"
              // eslint-disable-next-line no-useless-escape
              disabled={!inputs.name || !/^[a-zA-Z0-9\.\-\_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(inputs.email)}
              tabIndex="3"
              className="mt-5">
              Guardar cambios
            </Button>
          </fieldset>
        </form>
      </Settings>
    )
  );
};

export default Me;
