import { Navigate, Outlet } from 'react-router-dom';

const RoutesAdmin = ({ role, redirectPath = '/login' }) => {
  if (role !== 'admin' || role === undefined) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default RoutesAdmin;
