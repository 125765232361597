export const dashboardOptions = (role) => {
  const optionsSelect = [
    {
      value: '1_week',
      label: `1 semana`,
    },
    {
      value: '2_week',
      label: `2 semanas`,
    },
    {
      value: '1_month',
      label: `1 mes`,
    },
    {
      value: '3_month',
      label: `3 meses`,
    },
    {
      value: '6_month',
      label: `6 meses`,
    },
    {
      value: '1_year',
      label: `1 año`,
    },
  ];
  let optionsSelectRole = [];

  if (role === 'admin') {
    optionsSelectRole = [
      {
        value: 'admin',
        label: `Administrador`,
      },
      {
        value: 'user',
        label: `Usuario`,
      },
    ];
  } else if (role === 'worker') {
    optionsSelectRole = [
      {
        value: 'worker',
        label: `Trabajador`,
      },
      {
        value: 'user',
        label: `Usuario`,
      },
    ];
  } else if (role === 'influencer') {
    optionsSelectRole = [
      {
        value: 'user',
        label: `Usuario`,
      },
      {
        value: 'influencer',
        label: `Influencer`,
      },
    ];
  }

  return {
    optionsSelect,
    optionsSelectRole,
  };
};
