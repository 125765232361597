import { createContext, useContext, useReducer } from 'react';

const UserContext = createContext({
  user: null,
});

function userReducer(state, action) {
  const user = action.dispatch;
  switch (action.type) {
    case 'hasUser': {
      return { user };
    }
    case 'hasNotUser': {
      return { user: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatchUser] = useReducer(userReducer, { user: null });
  const value = { state, dispatchUser };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
