import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import getNotices, { deleteNotice } from '../../services/notices';
import Moment from 'moment';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { ArrowLeftL, ArrowRightL, File, EditText, Trash } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import AddEditNoticePopup from './AddEditNoticePopup';

const ManageNotices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [time, setTime] = useState('');
  const timerRef = useRef(null);
  const [notices, setNotices] = useState(null);
  const [noticesPages, setNoticesPages] = useState();
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [editableNotice, setEditableNotice] = useState(null);
  const [displayAddEditNoticePopup, setDisplayAddEditNoticePopup] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const params = window.location.search;
      const response = await getNotices(params);
      setNoticesPages({
        current_page: response.current_page,
        last_page: response.last_page,
        total: response.total,
        per_page: response.per_page,
      });
      setNotices(response.data);
    }
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    // Clear the interval when the component unmounts
    if (time.length === 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    } else {
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [time]);

  // Change table row order
  function changeOrder(column, sortedSide) {
    searchParams.set('page_number', 1);
    searchParams.set('orderby', `${column}`);
    searchParams.set('orderby_direction', sortedSide);
    setSearchParams(searchParams);
  }

  // Change table page number
  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  function generatePaginatorRange(noticesPages) {
    const pages = [];
    if (noticesPages.total > 0) {
      if (noticesPages.per_page < noticesPages.total) {
        if (noticesPages.current_page > 1) {
          for (
            let i = noticesPages.current_page - 1;
            i <= noticesPages.last_page && i < noticesPages.current_page + 3;
            i++
          ) {
            pages.push(i);
          }
        } else {
          for (
            let i = noticesPages.current_page;
            i <= noticesPages.last_page && i < noticesPages.current_page + 3;
            i++
          ) {
            pages.push(i);
          }
        }
      }
    }
    return pages;
  }

  const generateActionButtons = (element) => {
    return (
      <div className="flex flex-row items-center">
        <button
          onClick={() => {
            setEditableNotice(element);
            setDisplayAddEditNoticePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          disabled={element.deleted_at}
          className={`text-secondary-blue hover:text-secondary-blue-dark mr-2
            ${element.deleted_at ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
      </div>
    );
  };

  function prepareTableData() {
    if (notices) {
      const rowsData = [];
      notices?.forEach((element) => {
        rowsData.push({
          title: <div className="flex flex-col leading-5">{element.title}</div>,
          description: <div className="flex flex-col whitespace-pre-line">{element.description}</div>,
          updated_at: (
            <div className="flex flex-col leading-5">{Moment(element.updated_at).format('DD/MM/YYYY HH:mm')}</div>
          ),
          deleted_at: (
            <div className="flex flex-col leading-5">
              {element.deleted_at && Moment(element.deleted_at).format('DD/MM/YYYY HH:mm')}
            </div>
          ),
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  return notices ? (
    <article id="notices" className="mb-20">
      <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-5">Gestión de Avisos</h1>
      <p className="text-black-10 dark:text-accent-dark mb-3">
        Los avisos activos son los que no tienen fecha de borrado y aparecerán en el apartado <strong>Avisos</strong>{' '}
        ordenados por fecha de última modificación de forma descendente.
      </p>
      <section
        id="filters"
        className="flex flex-row w-full intermedium:mb-0 intermedium:child:w-auto justify-between pb-4 child:mb-3 gap-2">
        <section className="flex">
          <Input
            type="text"
            value={searchParams.get('search')}
            placeholder="Buscar avisos"
            width="w-full md:w-[300px]"
            className="child:h-[48px]"
            onChange={({ target }) => {
              searchParams.set('search', target.value);
              searchParams.set('page_number', 1);
              setTime(target.value);
            }}
          />
        </section>
        <section className="flex flex-row intermedium:child:w-auto">
          <Button
            type="secondary"
            size="medium"
            action={() => setDisplayAddEditNoticePopup(true)}
            icon={
              <Icon className="stroke-white text-2xl">
                <File />
              </Icon>
            }>
            Añadir Aviso
          </Button>
        </section>
      </section>
      <Table
        tableClass="w-full hidden lg:table"
        changeOrder={(e, c) => changeOrder(e, c)}
        rowsData={prepareTableData()}
        colsData={[
          {
            Header: 'Titulo',
            accessor: 'title',
            sortable: true,
          },
          {
            Header: 'Descripción',
            accessor: 'description',
            sortable: false,
          },
          {
            Header: 'Última modificación',
            accessor: 'updated_at',
            sortable: true,
          },
          {
            Header: 'Borrado',
            accessor: 'deleted_at',
            sortable: true,
          },
          {
            Header: 'Acciones',
            accessor: 'actions',
          },
        ]}
      />
      <section className="lg:hidden">
        {notices.map((element, index) => (
          <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
            <div className="flex flex-col child:mb-3">
              <section>
                <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">Título</span>
                <p className="text-black-12 dark:text-accent-dark font-semibold">{element.title}</p>
              </section>
              <section>
                <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                  Descripción
                </span>
                <p className="text-black-12 dark:text-accent-dark font-semibold">{element.description}</p>
              </section>
              <section>
                <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                  Última actualización
                </span>
                <p className="text-black-12 dark:text-accent-dark font-semibold break-words">
                  {Moment(element.updated_at).format('DD/MM/YYYY HH:mm')}
                </p>
              </section>
              {element.deleted_at && (
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Borrado
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-semibold break-words">
                    {Moment(element.deleted_at).format('DD/MM/YYYY HH:mm')}
                  </p>
                </section>
              )}
            </div>
            <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
          </div>
        ))}
      </section>
      <section id="paginator" className="mt-5 flex flex-row justify-between">
        <section id="numbers" className="flex">
          {noticesPages.current_page > 1 && (
            <button
              onClick={() => changePageNumber(noticesPages.current_page - 1)}
              className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center mr-3 dark:text-accent-dark">
              <Icon className="text-xl stroke-black pr-2">
                <ArrowLeftL />
              </Icon>{' '}
              Anterior
            </button>
          )}
          {generatePaginatorRange(noticesPages).map((element) =>
            noticesPages.current_page === element ? (
              <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                {element}
              </button>
            ) : (
              <button
                onClick={() => changePageNumber(element)}
                className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                {element}
              </button>
            )
          )}
        </section>
        {noticesPages.current_page < noticesPages.last_page && (
          <button
            onClick={() => changePageNumber(noticesPages.current_page + 1)}
            className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
            Siguiente{' '}
            <Icon className="text-xl stroke-black pl-2">
              <ArrowRightL />
            </Icon>
          </button>
        )}
      </section>
      <PopupDelete
        display={displayDeletePopup}
        item={deleteItem}
        closePopup={() => {
          setDisplayDeletePopup(!displayDeletePopup);
        }}
        title={deleteItem.title}
        action_cancel={() => {
          setDisplayDeletePopup(false);
        }}
        action_delete={async () => {
          await deleteNotice(deleteItem.id);
          setDisplayDeletePopup(false);
          const response = await getNotices(window.location.search);
          setNoticesPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setNotices(response.data);
        }}
      />
      <AddEditNoticePopup
        display={displayAddEditNoticePopup}
        closePopup={async () => {
          setDisplayAddEditNoticePopup(!displayAddEditNoticePopup);
          setEditableNotice(null);
          const response = await getNotices(window.location.search);
          setNoticesPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setNotices(response.data);
        }}
        data={editableNotice}
      />
    </article>
  ) : (
    <Loading />
  );
};

export default ManageNotices;
