import { Container } from '@vilibets/vilibets-uikit/dist/atoms/Container';
import { Footer } from '@vilibets/vilibets-uikit/dist/organisms/Footer';
import { PublicHeader } from '@vilibets/vilibets-uikit/dist/organisms/PublicHeader';
import { ForgotPassword } from '@vilibets/vilibets-uikit/dist/molecules/ForgotPassword';
import { useAuth } from '../../hooks/auth';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLoading } from '../../context/LoadingContext';
import { t } from 'i18next';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { Expand } from '@theme-toggles/react';

const ForgotPasswordComponent = () => {
  const { forgotPassword } = useAuth({
    middleware: 'guest',
  });
  const { dispatch } = useLoading();
  const {
    state: { loading },
  } = useLoading();
  const [error, setErrors] = useState(0);
  const [status, setStatus] = useState(null);

  const submitForm = async ({ email }) => {
    // estado loading true
    dispatch({ type: 'isLoading' });
    forgotPassword({ email, setErrors, setStatus });
  };

  const [isDarkMode, setIsDarkMode] = useState(localStorage.theme === 'dark');

  useEffect(() => {
    // toLightMode();
    if (localStorage.theme === 'dark') {
      toDarkMode();
    } else {
      toLightMode();
    }
  }, []);

  const toDarkMode = () => {
    localStorage.theme = 'dark';
    setIsDarkMode(true);
    updateTheme();
  };

  const toLightMode = () => {
    localStorage.theme = 'light';
    setIsDarkMode(false);
    updateTheme();
  };

  function updateTheme() {
    switch (localStorage.theme) {
      case 'dark':
        document.documentElement.classList.add('dark');
        document.documentElement.setAttribute('color-theme', 'dark');
        break;
      case 'light':
        document.documentElement.classList.remove('dark');
        document.documentElement.setAttribute('color-theme', 'light');
        break;
    }
  }

  return (
    <>
      {loading && <Loading />}
      <PublicHeader
        header_links={[
          { name: 'Descubre cómo funciona', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/como-funciona' },
          { name: 'Bonos bienvenida', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/bonos-lista' },
          {
            name: 'Curso',
            route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/curso',
          },
          { name: 'Comunidad', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/comunidad' },
          { name: 'Tarifas', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/tarifas' },
          { name: 'Contacto', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/contacto' },
        ]}
        toggleIcon={
          <Expand
            toggled={isDarkMode}
            onToggle={() => {
              if (localStorage.theme === 'dark') {
                toLightMode();
              } else {
                toDarkMode();
              }
            }}
          />
        }
        route_access={'/login'}
        route_try_now={'/register'}
      />
      {status?.status && <Navigate to="/reset-password" />}
      <main className="bg-primary-1 pt-16 pb-20 h-screen flex">
        <Container>
          <div className="m-auto h-full w-[347px] md:w-[400px] lg:w-[400px] justify-center items-center">
            <ForgotPassword
              submitForm={(e) => {
                submitForm(e);
              }}
              textError="El email no es válido"
              hasErrorInput={Object.keys(error).length > 0}
              messageErrorTryRegister={t(`${error.status}.${error.message}`)}
            />
          </div>
        </Container>
      </main>
      <footer className="fixed bottom-0 w-screen mt-11 bg-white">
        <Footer
          type="simple"
          legal_links={[
            { name: 'Aviso legal', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/aviso-legal' },
            { name: 'Política de cookies', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/politica-de-cookies' },
          ]}
        />
      </footer>
    </>
  );
};

export default ForgotPasswordComponent;
