import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowLeftL } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { useNavigate } from 'react-router-dom';

export default function GuideHeader({ title }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row child:w-1/2 intermedium:child:w-auto justify-between items-start">
      <h1 className="text-5xl mb-5 pl-3 text-black-12 font-bold" style={{ borderLeft: '5px solid #1e62e8' }}>
        {title}
      </h1>
      <Button
        style={{ minWidth: '170px' }}
        type="secondary"
        size="small"
        action={() => navigate('/guide')}
        icon={
          <Icon className="stroke-white text-2xl">
            <ArrowLeftL />
          </Icon>
        }>
        Volver
      </Button>
    </div>
  );
}
