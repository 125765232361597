import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { SectionTopMenu } from '@vilibets/vilibets-uikit/dist/molecules/SectionTopMenu/SectionTopMenu';
import { CalculatorsWizard } from '@vilibets/vilibets-uikit/dist/molecules/Wizards/Wizards';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import updateUser from '../../services/users';

const Calculators = ({ children }) => {
  const [showWizard, setShowWizard] = useState(false);

  const {
    dispatchUser,
    state: { user },
  } = useUser();

  const links = [
    '/calculators/normal',
    '/calculators/dutching',
    '/calculators/risk-free',
    '/calculators/accumulated',
    '/calculators/ff-refound',
    '/calculators/triple-refound',
    '/calculators/ff-accumulated',
  ];
  const navigate = useNavigate();

  function changeStep(step) {
    const url = step === 0 ? links[0] : links[step - 1];
    return navigate(url);
  }

  function checkInitWizardStep() {
    const step = window.location.href.includes('calculators/normal')
      ? 0
      : links.findIndex((val) => {
          return window.location.href.includes(val);
        }) + 1;
    // console.log(step);
    return step;
  }

  const closeWizard = async (show) => {
    setShowWizard(show);
    const response = await updateUser({ ...user, is_showed_wizard_calculator: show });
    dispatchUser({ type: 'hasUser', dispatch: response });
  };

  useEffect(() => {
    if (user) {
      setShowWizard(user?.is_showed_wizard_calculator);
    }
  }, [user]);

  return (
    <div className="flex flex-col mb-24" id="header-menu">
      <CalculatorsWizard
        display={showWizard}
        closePopup={() => {
          closeWizard(false);
        }}
        changeStep={(step) => changeStep(step)}
        initialWizardStep={checkInitWizardStep()}
      />
      <SectionTopMenu
        openInNewTab={true}
        links={[
          {
            title: 'Calculadora normal',
            link: '/calculators/normal',
          },
          {
            title: 'Calc. Dutching',
            link: '/calculators/dutching',
          },
          {
            title: 'Calc. Sin Riesgo Reembolso',
            link: '/calculators/risk-free',
          },
          {
            title: 'Calc. Acumuladas',
            link: '/calculators/accumulated',
          },
          {
            title: 'Calc. F-F Reembolso',
            link: '/calculators/ff-refound',
          },
          {
            title: 'Calc. 3-F Reembolso',
            link: '/calculators/triple-refound',
          },
          {
            title: 'Calc. Acumuladas F-F',
            link: '/calculators/ff-accumulated',
          },
        ]}
      />
      <section className="flex justify-end">
        <Icon
          className="stroke-black-7 text-xl mr-2"
          onClick={() => {
            closeWizard(true);
          }}>
          <InfoCircle />
        </Icon>
      </section>

      <section className="items-center">{children}</section>
    </div>
  );
};

export default Calculators;
