export const user = (dashboard) => {
  const type = 'bar';
  const seriesCumulativeProfitAndLoss = [
    {
      name: 'Beneficio Acumulado',
      data: dashboard?.profitAndLossHistogram?.cumulativeStakeEveryDay?.stake,
    },
  ];

  const optionsCumulativeProfitAndLoss = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + '€';
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: dashboard?.profitAndLossHistogram?.cumulativeStakeEveryDay?.date,
      labels: {
        rotate: -90,
      },
    },
  };

  const seriesProfitAndLoss = [
    {
      name: 'Cash Flow',
      data: dashboard?.profitAndLossHistogram?.sumStakeEveryDay?.stake,
    },
  ];

  const optionsProfitAndLoss = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -100000,
              to: -46,
              color: '#F4304A',
            },
            {
              from: -45,
              to: 0,
              color: '#FAB830',
            },
            {
              from: 1,
              to: 100000,
              color: '#1E62E8',
            },
          ],
        },
        columnWidth: '75%',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + '€';
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: dashboard?.profitAndLossHistogram?.sumStakeEveryDay?.date,
      labels: {
        rotate: -90,
      },
    },
  };
  const seriesCreditsByReferred = [
    {
      name: 'Créditos generados',
      data: dashboard?.joinedUsers?.map((user) => user?.total_credits) || [],
    },
  ];
  const optionsCreditsByReferred = {
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        distributed: true,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dashboard?.joinedUsers?.map((affiliate) => affiliate?.fullname) || [],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  return {
    type,
    optionsProfitAndLoss,
    seriesProfitAndLoss,
    optionsCumulativeProfitAndLoss,
    seriesCumulativeProfitAndLoss,
    optionsCreditsByReferred,
    seriesCreditsByReferred,
  };
};
