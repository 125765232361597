import axios from '../lib/axios';

export default async function getGuides(only_public) {
  const response = await axios
    .get(`/api/v1/guides?is_active=true${only_public ? '&is_private=false' : ''}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return {
    response: response.data,
  };
}

export async function getGuide(url) {
  const response = await axios
    .get(`/api/v1/guides?slug=${url}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return {
    response: response.data,
  };
}
