import useSWR from 'swr';
import axios from '../lib/axios';

export default async function getSubscriptions(user_id) {
  const response = await axios
    .get(`/api/v1/users/${user_id}/subscriptions`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function createSubscription(user_id, rate_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/paypal/subscribe`, { rate_id })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function renewSubscription(user_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/paypal/renew`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function cancelSubscription(subscription_id) {
  const response = await axios
    .post(`/api/v1/subscriptions/${subscription_id}/cancel`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function useCreditsForSubscription(user_id, rate_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/use-credits/${rate_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function useCreditsForSubscriptionFAKE(user, end_date, rate) {
  const response = await axios
    .put(`/api/v1/subscriptions/${user.subscription.id}`, { end_date })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  await axios
    .put(`/api/v1/users/${user.id}`, { affiliate_credits: user.affiliate_credits - rate.total })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function sendCancelPaymentSignal(user_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/paypal/canceled-payment`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
export async function sendPendingPaymentSignal(user_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/paypal/pending_payment`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function createStripeSubscription(user_id, rate_id, payment_method_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/stripe/subscribe`, rate_id, payment_method_id)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function cancelStripeSubscription(user_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/stripe/cancel`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

const fetchSubscriptions = async (url) => {
  return await axios
    .get(url)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
};

export const useSubscriptions = (user_id = -1) => {
  // /**
  //  * swr hook for fetching subjects
  //  */
  const { data, error } = useSWR(user_id === -1 ? null : `/api/v1/users/${user_id}/subscriptions`, fetchSubscriptions);

  return {
    data,
    error,
  };
};

export async function updateSubscription(user_id, subscription) {
  await axios
    .post(`/api/v1/users/${user_id}/subscription/update`, subscription)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createRawSubscription(subscription) {
  await axios
    .post(`/api/v1/subscriptions`, subscription)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createManualSubscription(user_id, subscription) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/subscription`, subscription)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
