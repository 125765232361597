import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { createPayment } from '../../services/users';

const MoneyToInfluencerPopup = ({ display, closePopup, user, order }) => {
  const [fields, setField] = useState();

  useEffect(() => {
    if (order) {
      setField({
        id: order.id,
        start_date: order.start_date,
        total: order.total,
        comment: order.comment,
        user_id: order.user_id,
      });
    } else {
      setField({
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        total: '',
        comment: '',
        user_id: user?.id,
      });
    }
  }, [order]);

  return (
    fields && (
      <Popup display={display} width={'md:min-w-[40%]'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <section className="flex flex-row items-end">
            <h3 className="text-xl font-semibold dark:text-accent-dark">
              Nuevo pago a {user?.name} ({user?.email})
            </h3>
          </section>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => closePopup()}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        <div className="flex flex-row gap-3 child:w-1/2 pl-5 pr-5 mb-2">
          <section id="date" className="flex flex-col child:mb-1">
            <label className="dark:text-normal-dark" htmlFor="date">
              Fecha del pago
            </label>
            <CalendarInput
              time={false}
              placeholder="Seleccione fecha del pago"
              initialDate={new Date()}
              inputClass={
                'drop-shadow-md h-10 items-center font-normal text-black-7 pl-10 cursor-pointer dark:bg-primary-1'
              }
              iconClass={'top-[30%]'}
              action={(e) => {
                setField({ ...fields, start_date: moment(e).format('YYYY-MM-DD') });
              }}
            />
          </section>
          <section className="flex flex-col child:mb-1">
            <label className="dark:text-normal-dark" htmlFor="total">
              Importe
            </label>
            <Input
              id="total"
              width="w-auto"
              placeholderMessage="Importe"
              name="total"
              type={'number'}
              value={fields.total}
              onChange={(e) => setField({ ...fields, total: parseFloat(e.target.value) })}
              step="0.01"
            />
          </section>
        </div>
        <section className="mb-3 child:mb-2 pl-5 pr-5">
          <label className="dark:text-normal-dark" htmlFor="observaciones">
            Observaciones
          </label>
          <Input
            id="comment"
            width="w-full"
            placeholderMessage="Observaciones"
            name="comment"
            value={fields.comment}
            onChange={(e) => setField({ ...fields, comment: e.target.value })}
            className="mt-1"
          />
        </section>

        <Button
          size={'medium'}
          action={async () => {
            if (fields.total <= user.affiliate_credits) {
              await createPayment(fields.user_id, fields);
              toast.success('Pago creado correctamente.', {
                icon: '🤙',
              });
              closePopup();
            } else {
              toast.error('El importe no puede ser superior a los créditos del usuario.');
            }
          }}
          disabled={fields.start_date === '' || !fields.total || fields.total < 0 || fields.comment === ''}
          className="mx-auto mb-5">
          {order?.id ? 'Actualizar datos de pago' : 'Crear pago'}
        </Button>
      </Popup>
    )
  );
};

export default MoneyToInfluencerPopup;
