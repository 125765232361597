import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { getCourse, updateReadArticle } from '../../services/users';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { IconChevronDown, IconChevronUp, PlayL, File, InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Checkbox } from '@vilibets/vilibets-uikit/dist/atoms/Checkbox';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import PopupSubscription from '../../components/PopupSubscription/PopupSubscription';
// import ReactPlayer from 'react-player';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Course = () => {
  const {
    state: { user },
  } = useUser();

  const [course, setCourse] = useState({});
  const [activeTab, setActiveTab] = useState('description');
  const [articleSelected, setArticleSelected] = useState({});
  const [stripeConf, setStripeConf] = useState(null);
  const [showPopupPayment, setShowPopupPayment] = useState(false);

  useEffect(() => {
    if (user) {
      setStripeConf(loadStripe(process.env.REACT_APP_STRIPE_KEY, {}));

      async function fetchData() {
        const response = await getCourse(user);

        // Inicializar collapse a true en todas las secciones
        const updatedSections = response.sections.map((section) => ({ ...section, collapse: true }));

        // Actualizar el estado de course con las secciones actualizadas
        setCourse({ ...response, sections: updatedSections });
        setArticleSelected(updatedSections[0].articles[0]);
      }

      fetchData();
    }
  }, []);

  const handleToggleCollapse = (pIndex) => {
    const updatedSections = [...course.sections];
    updatedSections[pIndex].collapse = !updatedSections[pIndex].collapse;
    updatedSections.forEach((section, index) => {
      if (pIndex !== index) {
        section.collapse = true;
      }
    });
    setCourse({ ...course, sections: updatedSections });
  };

  const handleCheckArticle = async (indexSection, indexArticle, value) => {
    if (!user?.course) {
      return;
    }
    const updatedSections = [...course.sections];
    const article = updatedSections[indexSection].articles[indexArticle];
    setArticleSelected(article);
    article.read = value;
    await updateReadArticle(user, article);
    setCourse({ ...course, sections: updatedSections });
  };

  const handleClickArticle = async (indexSection, indexArticle) => {
    if (!user?.course) {
      return;
    }
    const article = course.sections[indexSection].articles[indexArticle];
    setArticleSelected(article);
    if (article.type === 'articulo' && !article.read) {
      article.read = true;
      await updateReadArticle(user, article);
      setCourse({ ...course });
    }
  };

  const handleVideoEnd = async () => {
    // Realizar la llamada a la base de datos aquí para marcar el vídeo como visto
    articleSelected.read = true;
    await updateReadArticle(user, articleSelected);
    setCourse({ ...course });
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: getPercentajeProgress() === 100 ? '#16a34a' : '#1a90ff',
    },
  }));

  const getPercentajeProgress = () => {
    if (Object.keys(course).length > 0) {
      // Calcula el número total de artículos
      const totalArticulos = course.sections.flatMap((section) => section.articles).length;

      // Calcula el número de artículos leídos
      const articulosLeidos = course.sections
        .flatMap((section) => section.articles)
        .reduce((contador, articulo) => {
          return contador + (articulo.read ? 1 : 0);
        }, 0);

      // Calcula el porcentaje de artículos leídos
      const porcentajeArticulosLeidos = (articulosLeidos / totalArticulos) * 100;

      return parseInt(porcentajeArticulosLeidos.toFixed(0));
    }
  };

  function formatDuration(pSeconds) {
    const hours = Math.floor(pSeconds / 3600);
    const minutes = Math.floor((pSeconds % 3600) / 60);
    const remainingSeconds = pSeconds % 60;

    let formattedDuration = '';
    if (hours > 0) {
      formattedDuration += hours.toString() + ' h ';
    }
    if (minutes > 0 || hours > 0) {
      formattedDuration += minutes.toString() + ' min ';
    }
    if (remainingSeconds > 0) {
      formattedDuration += remainingSeconds.toString().padStart(2, '0') + ' seg';
    }

    return formattedDuration;
  }

  const descripcionCurso = () => {
    return (
      <div
        className="p-2 text-black-10 text-sm line-clamp-4 course-description leading-[22px] text-justify"
        dangerouslySetInnerHTML={{ __html: course.description }}
      />
    );
  };

  const seccionesCurso = () => {
    return (
      <ul className="w-full mb-10">
        {course.sections.map((section, indexSection) => (
          <div className="border-b border-gray-300" key={indexSection}>
            <li
              className="px-2 py-2 hover:cursor-pointer flex flex-col"
              onClick={() => handleToggleCollapse(indexSection)}>
              <div className="flex flex-row justify-between">
                <div>
                  <span className="text-sm font-bold">
                    Sección {section.order} - {section.title}
                  </span>
                  <br />
                  <small
                    className={`${
                      section.articles.filter((item) => item.read).length === 0
                        ? 'text-red-600'
                        : section.articles.filter((item) => item.read).length < section.articles.length
                        ? 'text-orange-600'
                        : 'text-green-600'
                    } font-bold`}>
                    {section.articles.filter((item) => item.read).length} / {section.articles.length}{' '}
                    {section.articles.filter((item) => item.read).length === 0
                      ? 'No iniciada'
                      : section.articles.filter((item) => item.read).length < section.articles.length
                      ? 'En curso'
                      : 'Finalizada'}
                  </small>
                </div>
                <Icon className="stroke-black text-2xl">
                  {section.collapse ? <IconChevronDown /> : <IconChevronUp />}
                </Icon>
              </div>
            </li>
            {!section.collapse &&
              section.articles.map((article, indexArticle) => (
                <div
                  className="flex flex-row px-2 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 items-start hover:cursor-pointer"
                  key={indexArticle}
                  onClick={() => handleClickArticle(indexSection, indexArticle)}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que el clic se propague al div padre
                    }}>
                    <Checkbox
                      defaultChecked={article.read}
                      isDisabled={!user?.course}
                      exteriorClassName="w-auto mr-1"
                      action={(e, a) => handleCheckArticle(indexSection, indexArticle, a)}
                    />
                  </div>
                  <div className="flex flex-col child:mb-1">
                    <small>
                      {article.order}. {article.title}
                    </small>
                    <div className="flex">
                      <Icon className="stroke-black mr-1">{article.type === 'video' ? <PlayL /> : <File />}</Icon>
                      <small>{formatDuration(article.duration)}</small>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </ul>
    );
  };

  return (
    Object.keys(course).length > 0 && (
      <div className="flex flex-col rounded-2xl bg-white grow mb-8">
        <section className="flex flex-row justify-between pb-5">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">{course.title}</h1>
          {!user.course && (
            <Button
              type="secondary"
              action={() => {
                setShowPopupPayment(true);
              }}
              icon={
                <Icon className="stroke-white text-2xl">
                  <InfoCircle />
                </Icon>
              }>
              Tarifas
            </Button>
          )}
        </section>
        <Elements stripe={stripeConf}>
          <PopupSubscription
            display={showPopupPayment}
            course={true}
            closePopup={() => {
              setShowPopupPayment(false);
            }}
          />
        </Elements>
        <section className="flex">
          <div className={`flex flex-col w-full ${user?.course ? 'lg:w-2/3 xl:w-3/4' : ''}`}>
            {/* Solo si el curso está pagado */}
            {user?.course && (
              <div>
                {/* Artículo tipo vídeo */}
                {articleSelected && articleSelected?.type === 'video' && (
                  <>
                    <div className="player-wrapper mb-2">
                      <video
                        className="react-player"
                        src={articleSelected.url} // URL del video
                        width="100%" // Ancho completo
                        height="100%" // Alto completo
                        controls // Mostrar controles del video
                        playsInline // Evitar que se abra en pantalla completa en móviles
                        onEnded={handleVideoEnd} // Detectar cuando el video termina
                        preload="metadata" // Precargar solo los metadatos
                        controlsList="nodownload" // Deshabilitar descarga
                        disablePictureInPicture // Deshabilitar Picture-in-Picture
                      >
                        Tu navegador no soporta la reproducción de videos.
                      </video>
                    </div>
                  </>
                )}
                {/* Artículo tipo texto */}
                {articleSelected?.type === 'articulo' && (
                  <div
                    className="course-description text-black-10 line-clamp-4 leading-[22px] mb-2 border-4 p-5"
                    dangerouslySetInnerHTML={{ __html: articleSelected.content }}
                  />
                )}
                <div className="italic font-bold text-right dark:text-accent-dark pr-2">{articleSelected.title}</div>
                {/* Progreso del curso */}
                <div className="flex flex-col pl-2 pr-2 mb-5">
                  <span>{getPercentajeProgress()}% completado</span>
                  <BorderLinearProgress variant="determinate" value={getPercentajeProgress()} />
                </div>
              </div>
            )}

            {/* Pestañas del curso (Visible: md y anterior) */}
            <div className={`${user?.course ? 'lg:hidden' : ''}`}>
              <ul className="flex flex-row list-none border-b border-black-4 overflow-x-auto mb-1">
                <li
                  className={`px-2 py-2 hover:bg-gray-200 hover:cursor-pointer dark:hover:bg-gray-600 ${
                    activeTab === 'description' ? 'font-bold border-b-2 border-black' : ''
                  }`}
                  onClick={() => setActiveTab('description')}>
                  Descripción general
                </li>
                <li
                  className={`px-2 py-2 hover:bg-gray-200 hover:cursor-pointer dark:hover:bg-gray-600 ${
                    activeTab === 'content' ? 'font-bold border-b-2 border-black' : ''
                  }`}
                  onClick={() => setActiveTab('content')}>
                  Contenido del curso
                </li>
              </ul>
              {/*  Contenido de las pestañas */}
              {activeTab === 'description' && descripcionCurso()}
              {activeTab === 'content' && seccionesCurso()}
            </div>

            {/* Pestañas del curso (Visible en lg y superior */}
            <div className={`${user?.course ? 'hidden lg:block' : 'hidden'}`}>
              <ul className="flex flex-row list-none border-b border-black-4 overflow-x-auto mb-1">
                <li
                  className="px-2 py-2 hover:bg-gray-200 hover:cursor-pointer dark:hover:bg-gray-600 font-bold border-b-2 border-black"
                  onClick={() => setActiveTab('description')}>
                  Descripción general
                </li>
              </ul>
              {descripcionCurso()}
            </div>
          </div>

          {/* Contenido del curso a la derecha de la pantalla (Visible en lg y superior y si se ha pagado */}
          {user?.course && (
            <div className="flex flex-col w-full hidden lg:block lg:w-1/3 xl:w-1/4">
              <div className="p-2 dark:hover:bg-gray-600 font-bold border-b border-t border-gray-300">
                Contenido del curso
              </div>
              {seccionesCurso()}
            </div>
          )}
        </section>
      </div>
    )
  );
};

export default Course;
