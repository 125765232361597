import Calculators from './Calculators';
import { NormalCalculator } from '@vilibets/vilibets-uikit/dist/molecules/Calculators/Calculators';
import { useSearchParams } from 'react-router-dom';

const NormalCalc = () => {
  // get params from url
  const [searchParams, setSearchParams] = useSearchParams();
  // get searchParams and set it to initialParams
  const initialParams = () => {
    let params = {};
    for (const [key, value] of searchParams) {
      params = { ...params, [key]: value };
    }
    return params;
  };

  return (
    <Calculators>
      <NormalCalculator
        initialParams={initialParams}
        onChange={(params) => {
          setSearchParams(params);
        }}
      />
    </Calculators>
  );
};

export default NormalCalc;
