import axios from '../lib/axios';

import FileSaver from 'file-saver';

export default async function getProfitTracker(user_id, params = '', selectedBookmarks = null) {
  // Envía el parámetro de bookies si hay alguna que no está marcada. De esta forma obtiene también las eliminadas
  let activeBookmarks = [];
  if (selectedBookmarks != null) {
    selectedBookmarks.forEach((el) => {
      if (el.defaultChecked === true) activeBookmarks.push(el.id);
    });
    if (selectedBookmarks.length === activeBookmarks.length) {
      activeBookmarks = [];
    }
  }

  const response = await axios
    .get(
      `/api/v1/profit-tracker?user_id=${user_id}&page_size=30${params.replace('?', '&')}${
        activeBookmarks.length ? '&bookmarkers=' + activeBookmarks : ''
      }${params.includes('page_number') ? '' : '&page_number=1'}`
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

export async function deleteProfitTracker(id) {
  await axios
    .delete(`/api/v1/profit-tracker/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createProfitTracker(bond) {
  await axios
    .post(`/api/v1/profit-tracker/`, bond)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function updateProfitTracker(bond) {
  await axios
    .put(`/api/v1/profit-tracker/${bond.id}`, bond)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function download(user) {
  await axios
    .get(`/api/v1/profit-tracker/download?user_id=${user.id}`, { responseType: 'blob' })
    .then((res) => {
      const csvData = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, 'data.csv');
    })
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}
