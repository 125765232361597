import axios from '../lib/axios';

export default async function getDashboard(user_id, filter_date, date_from, date_to, role) {
  const response = await axios
    .get(
      `/api/v1/dashboard/user/${user_id}?filter_date=${filter_date}&role=${role}&date_from=${date_from}&date_to=${date_to}`
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return {
    response: response.data,
  };
}
