import useSWR from 'swr';
import axios from '../lib/axios';

const fetchOrders = async (url) => {
  return await axios
    .get(url)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
};

export const useOrders = (user_id = -1) => {
  // /**
  //  * swr hook for fetching orders
  //  */
  const { data, error } = useSWR(user_id === -1 ? null : `/api/v1/users/${user_id}/orders`, fetchOrders);

  return {
    data,
    error,
  };
};

export async function getOrders(params = '') {
  const response = await axios
    .get(
      `/api/v1/orders?page_size=30&${!params.includes('page_number') ? 'page_number=1' : ''}${params.replace('?', '&')}`
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function refundOrder(order_id, comment) {
  const response = await axios
    .post(`/api/v1/orders/refund`, { order_id, comment })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
