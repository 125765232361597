import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../../../hooks/auth';
import { sendCancelPaymentSignal } from '../../../services/subscriptions';

const PaymentCanceled = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  useEffect(() => {
    if (user) {
      async function sendCancelPayment() {
        await sendCancelPaymentSignal(user.id);
      }
      sendCancelPayment();
    }
  }, [user]);

  return (
    <section className="text-center items-center">
      <h1 className="font-bold text-black-12 dark:text-accent-dark mb-7 text-4xl">
        Has rechazado el pago de subscripción
      </h1>
      <p className="pb-10" />
      <Link
        to="/subscriptions/my-subscription"
        className="bg-primary hover:bg-primary-8 text-white rounded-xl active:shadow-inn3r-shadow justify-center p-5">
        Gestionar mi suscripción
      </Link>
    </section>
  );
};

export default PaymentCanceled;
