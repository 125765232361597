import Bonds from '../Bonds';
import { Bond } from '@vilibets/vilibets-uikit/dist/molecules/Bond/Bond';
import { ArrowRightL } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { useUser } from '../../../context/UserContext';
import { useEffect, useState } from 'react';
import { completeBond, getUserDeletedBonds, undeleteBond } from '../../../services/bonds';
import { Link, useSearchParams } from 'react-router-dom';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
moment.locale('es');

const DeletedBonds = () => {
  const {
    state: { user },
  } = useUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const [DeletedBonds, setDeletedBonds] = useState();
  const [bondsPages, setBondsPages] = useState();

  const getSearchParamsWithTypes = () => {
    return window.location.search
      ? window.location.search + '&types=top_bonds,recursive,free_spins'
      : '?types=top_bonds,recursive,free_spins';
  };

  useEffect(() => {
    if (user) {
      async function fetchData() {
        if (!searchParams.get('page_number')) {
          searchParams.set('page_number', 1);
          setSearchParams(searchParams);
          const response = await getUserDeletedBonds(user.id, getSearchParamsWithTypes());
          setBondsPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setDeletedBonds(response.data);
        }
      }
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getUserDeletedBonds(user.id, getSearchParamsWithTypes());
        setBondsPages({
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
          per_page: response.per_page,
        });
        setDeletedBonds(response.data);
      }
      fetchData();
    }
  }, [searchParams]);

  return (
    DeletedBonds && (
      <Bonds>
        <section>
          <ul className="child:mb-4 mb-10">
            {DeletedBonds.map((bond) => (
              <li key={bond.id}>
                <Bond
                  type="deleted"
                  level={bond.level}
                  profit={bond.max_reward ? `${bond.min_reward ?? '0'} - ${bond.max_reward} €` : 'Variable'}
                  title={<Link to={`/bonds/${bond.id}`}>{bond.title}</Link>}
                  discount_code={bond.code}
                  description={bond.description}
                  ends_at={moment(bond.valid_until).format('LLL')}
                  logo={bond.bookmarker.logo_url}
                  bond={bond}
                  cardColor="white bond-detail"
                  url={'/bonds/' + bond.id}
                  delete_action={async () => {
                    await undeleteBond(user.id, bond.id);
                    const response = await getUserDeletedBonds(user.id, getSearchParamsWithTypes());
                    setBondsPages({
                      current_page: response.current_page,
                      last_page: response.last_page,
                      total: response.total,
                      per_page: response.per_page,
                    });
                    setDeletedBonds(response.data);
                  }}
                  complete_action={async () => {
                    await completeBond(user.id, bond.id);
                    await undeleteBond(user.id, bond.id);
                    const response = await getUserDeletedBonds(user.id, getSearchParamsWithTypes());
                    setBondsPages({
                      current_page: response.current_page,
                      last_page: response.last_page,
                      total: response.total,
                      per_page: response.per_page,
                    });
                    setDeletedBonds(response.data);
                  }}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="paginator" className="mb-20 flex flex-row justify-between">
          <section id="numbers">
            {generatePaginatorRange(bondsPages).map((element) =>
              bondsPages.current_page === element ? (
                <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                  {element}
                </button>
              ) : (
                <button
                  onClick={() => changePageNumber(element)}
                  className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                  {element}
                </button>
              )
            )}
          </section>
          {bondsPages.current_page < bondsPages.last_page && (
            <button
              onClick={() => changePageNumber(bondsPages.current_page + 1)}
              className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
              Siguiente{' '}
              <Icon className="text-xl stroke-black pl-2">
                <ArrowRightL />
              </Icon>
            </button>
          )}
        </section>
      </Bonds>
    )
  );

  function generatePaginatorRange(bondsPages) {
    const pages = [];
    if (bondsPages.total > 0) {
      if (bondsPages.per_page < bondsPages.total) {
        if (bondsPages.current_page > 1) {
          for (let i = bondsPages.current_page - 1; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        } else {
          for (let i = bondsPages.current_page; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        }
      }
    }
    return pages;
  }

  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }
};

export default DeletedBonds;
