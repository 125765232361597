import { PublicHeader } from '@vilibets/vilibets-uikit/dist/organisms/PublicHeader';
import { Container } from '@vilibets/vilibets-uikit/dist/atoms/Container';
import { Footer } from '@vilibets/vilibets-uikit/dist/organisms/Footer';
import { Register } from '@vilibets/vilibets-uikit/dist/molecules/Register';

import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { useLoading } from '../../context/LoadingContext';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { Expand } from '@theme-toggles/react';

const RegisterPage = () => {
  const { register } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/dashboard',
  });
  const { dispatch } = useLoading();
  const {
    state: { loading },
  } = useLoading();

  const [error, setErrors] = useState({});
  const [cookies, setCookie] = useCookies(['vilibets_affiliate_token']);

  const submitForm = async (event) => {
    const { email, name, surnames, password, repeatPassword, username, role, recaptcha } = event;
    const affiliate_token = cookies.vilibets_affiliate_token;
    // estado loading true
    dispatch({ type: 'isLoading' });
    register({
      email,
      name,
      surnames,
      password,
      password_confirmation: repeatPassword,
      username,
      affiliate_token,
      role,
      recaptcha,
      setErrors,
    });
    // add afiliate_token to user
  };

  const [isDarkMode, setIsDarkMode] = useState(localStorage.theme === 'dark');

  function getAffiliateToken() {
    let affiliateToken = '';
    const arrayParams = window.location.search.replace('?', '').split('&');
    for (let i = 0; i < arrayParams.length; i++) {
      const datos = arrayParams[i].split('=');
      if (datos[0] === 'affiliate') {
        affiliateToken = datos[1];
        break;
      }
    }

    return affiliateToken;
  }

  useEffect(() => {
    // toLightMode();
    if (localStorage.theme === 'dark') {
      toDarkMode();
    } else {
      toLightMode();
    }

    // Affiliate token
    const affiliateToken = getAffiliateToken();
    if (affiliateToken) {
      setCookie('vilibets_affiliate_token', affiliateToken, { maxAge: 120 * 60 * 60 }); // 5 días de duración (en segundos)
    }
  }, []);

  const toDarkMode = () => {
    localStorage.theme = 'dark';
    setIsDarkMode(true);
    updateTheme();
  };

  const toLightMode = () => {
    localStorage.theme = 'light';
    setIsDarkMode(false);
    updateTheme();
  };

  function updateTheme() {
    switch (localStorage.theme) {
      case 'dark':
        document.documentElement.classList.add('dark');
        document.documentElement.setAttribute('color-theme', 'dark');
        break;
      case 'light':
        document.documentElement.classList.remove('dark');
        document.documentElement.setAttribute('color-theme', 'light');
        break;
    }
  }

  return (
    <>
      {loading && <Loading />}
      <section className="bg-primary-1">
        <PublicHeader
          header_links={[
            { name: 'Descubre cómo funciona', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/como-funciona' },
            { name: 'Bonos bienvenida', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/bonos-lista' },
            {
              name: 'Curso',
              route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/curso',
            },
            { name: 'Comunidad', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/comunidad' },
            { name: 'Tarifas', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/tarifas' },
            { name: 'Contacto', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/contacto' },
          ]}
          toggleIcon={
            <Expand
              toggled={isDarkMode}
              onToggle={() => {
                if (localStorage.theme === 'dark') {
                  toLightMode();
                } else {
                  toDarkMode();
                }
              }}
            />
          }
          route_access={'/login'}
          route_try_now={'/register'}
          logo_url={process.env.REACT_APP_PUBLIC_PAGE_URL}
        />
        <main className="bg-primary-1 pt-20 flex">
          <Container>
            <div className="m-auto h-full w-[347px] md:w-[400px] lg:w-[400px] justify-center items-center">
              <Register
                submitForm={(e) => {
                  submitForm(e);
                }}
                hasErrorInput={Object.keys(error).length > 0}
                messageErrorTryRegister={t(`${error.status}.${error.message}`)}
                routeRegister={'/login'}
                siteKeyRecaptcha={process.env.REACT_APP_RECAPTCHA_KEY}
              />
            </div>
          </Container>
        </main>
        <footer className="bottom-0 w-screen mt-11 bg-white">
          <Footer
            type="simple"
            legal_links={[
              { name: 'Aviso legal', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/aviso-legal' },
              { name: 'Política de cookies', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/politica-de-cookies' },
            ]}
          />
        </footer>
      </section>
    </>
  );
};

export default RegisterPage;
