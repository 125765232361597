import { useEffect } from 'react';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import useForm from '../../hooks/useForm';
import { toast } from 'react-toastify';
import { updateSetting } from '../../services/settings';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Editor } from '@tinymce/tinymce-react';

const PopupGlobalVars = ({ data, display, closePopup }) => {
  const { values, handleInputChange } = useForm(data);

  useEffect(() => {
    handleInputChange(data);
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateSetting(values)
      .then(() => {
        toast.success('Actualizado la variable global', {
          icon: '👌',
        });
        closePopup();
      })
      .catch(() => {
        toast.error('Error al actualizar la variable global', {
          icon: '👎',
        });
      });
  };

  // add css into iframes when they are rendered delay 100ms
  setTimeout(() => {
    const iframes = document.querySelectorAll('iframe');
    // if html has dark class add black background
    if (document.querySelector('html').classList.contains('dark')) {
      iframes.forEach((iframe) => {
        // add background-color: #1a1a1a; in html inside iframe
        iframe.contentDocument.querySelector('html').style.backgroundColor = '#1a1a1a';
        iframe.contentDocument.querySelector('html').style.color = 'white';
      });
    } else {
      iframes.forEach((iframe) => {
        // add background-color: #1a1a1a; in html inside iframe
        iframe.contentDocument.querySelector('html').style.backgroundColor = 'white';
        iframe.contentDocument.querySelector('html').style.color = 'black';
      });
    }
  }, 100);

  const handleChange = (selectName, e) => {
    handleInputChange({ [selectName]: e });
  };
  return (
    data && (
      <Popup display={display} width={'md:w-8/12 h-[90vh] md:min-w-6/12'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <section className="flex flex-col">
            <h3 className="text-sm bg-black-4 w-fit rounded px-1.5 dark:text-normal-dark dark:bg-transparent dark:px-0">
              Detalle de la variable global
            </h3>
            <h3 className="text-xl font-semibold dark:text-accent-dark">{data?.name}</h3>
          </section>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => closePopup()}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-3 items-start justify-between pb-5 pr-5 pl-5 rounded-t">
          <section className="flex flex-col w-full">
            <label htmlFor="value" className="dark:text-normal-dark">
              Valor
            </label>
            {data?.type === 'html_text' ? (
              <Editor
                init={{
                  menubar: false,
                }}
                initialValue={values?.value}
                onEditorChange={(e) => {
                  handleChange('value', e.level.content);
                }}
              />
            ) : (
              <Input
                id="value"
                width="w-full"
                placeholderMessage="Condición"
                name="value"
                value={values?.value}
                onChange={({ target }) => handleChange('value', target.value)}
                className="mt-1"
              />
            )}
          </section>
          <Button size="large" typeButton="submit">
            Editar variable global
          </Button>
        </form>
      </Popup>
    )
  );
};

export default PopupGlobalVars;
