import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowLeftL } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  generateAffiliateToken,
  getReferredUsers,
  getUser,
  getAffiliateTransactions,
  getPaymentsReceived,
} from '../../services/users';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import MoneyToInfluencerPopup from './MoneyToInfluencerPopup';

const InfluencerDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [displayMoneyToInfluencerPopup, setDisplayMoneyToInfluencerPopup] = useState(false);
  const [verAfiliados, setVerAfiliados] = useState(false);
  const [verTransacciones, setVerTransacciones] = useState(false);
  const navigate = useNavigate();

  async function getUserData() {
    if (id) {
      const response = await getUser(id);
      setUser(response);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  const [referredUsers, setReferredUsers] = useState([]);
  const [affiliateTransactions, setAffiliateTransactions] = useState([]);
  const [paymentsReceived, setPaymentsReceived] = useState([]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const paymentsReceived = await getPaymentsReceived(user);
        setPaymentsReceived(paymentsReceived);
        const referredUsers = await getReferredUsers(user);
        setReferredUsers(referredUsers);
        const affiliateTransactions = await getAffiliateTransactions(user);
        setAffiliateTransactions(affiliateTransactions);
      }
      fetchData();
    }
  }, [user]);

  return (
    user && (
      <div style={{ marginBottom: '100px' }}>
        <div className="flex flex-row child:w-1/2 intermedium:child:w-auto justify-between items-start">
          <h2 className="font-bold text-2xl text-black-12 mb-5 dark:text-accent-dark">{'Influencer'}</h2>
          <Button
            type="secondary"
            size="medium"
            action={() => navigate(-1)}
            icon={
              <Icon className="stroke-white text-2xl">
                <ArrowLeftL />
              </Icon>
            }>
            Volver a la tabla
          </Button>
        </div>
        <section id="user-detail" className="flex flex-col-reverse lg:flex-row">
          <section id="user-content" className="w-full lg:grow lg:mr-10">
            <div className="flex flex-row gap-4">
              <GraphicCardNumber
                className="min-h-[140px] mb-4 w-6/12"
                title="Total generado"
                number={user.affiliate_credits || 0}
                widthGraph="60"
                heightGraph="50"
                numberClass="text-3xl font-bold"
                sufix="€"
              />
              <GraphicCardNumber
                className="min-h-[140px] mb-4 w-6/12"
                title="Transacciones de afiliados"
                number={affiliateTransactions.length || 0}
                widthGraph="60"
                heightGraph="50"
                numberClass="text-3xl font-bold"
                sufix=""
              />
            </div>
            <section id="payments-received" className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
              <section
                id="payments-received-list"
                className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
                <section className="flex flex-col lg:flex-row justify-between items-start">
                  <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">Pagos recibidos</h1>
                  <span
                    className="cursor-pointer text-white bg-primary font-bold px-3 rounded-lg"
                    onClick={() => {
                      setOrderData(null);
                      setDisplayMoneyToInfluencerPopup(true);
                    }}>
                    Nuevo pago
                  </span>
                </section>
                <table>
                  <thead className="border-b border-black-4">
                    <tr className="text-left child:py-3 text-sm">
                      <th>Fecha del pago</th>
                      <th>Importe</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(paymentsReceived).length === 0 && (
                      <tr>
                        <td className="pt-3" colSpan={3}>
                          No existe ningún pago registrado
                        </td>
                      </tr>
                    )}
                    {Object.keys(paymentsReceived) &&
                      paymentsReceived.map((order, index) => (
                        <tr
                          key={index}
                          className="border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600">
                          <td>{moment(order.start_date).format('DD/MM/YYYY')}</td>
                          <td className="text-secondary font-bold">
                            {order.total.toLocaleString('es-ES', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{' '}
                            €
                          </td>
                          <td>{order.comment}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </section>
            </section>
            <section id="referred-users" className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
              <section
                id="referred-users-list"
                className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
                <section className="flex flex-col lg:flex-row justify-between items-start">
                  <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">Usuarios afiliados</h1>
                  <span
                    className="cursor-pointer text-white bg-primary font-bold px-3 rounded-lg"
                    onClick={async () => {
                      if (!user.affiliate_token) {
                        const token = await generateAffiliateToken(user);
                        navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${token}`);
                      } else {
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${user.affiliate_token}`
                        );
                      }
                      toast.success('¡El código de invitación ha sido copiado en el portapapeles!');
                    }}>
                    Copiar link de afiliados
                  </span>
                </section>
                <table>
                  <thead className="border-b border-black-4">
                    <tr className="text-left child:py-3 text-sm">
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Fecha de registro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(referredUsers).length === 0 && (
                      <tr>
                        <td className="pt-3" colSpan={3}>
                          No tiene usuarios referidos
                        </td>
                      </tr>
                    )}
                    {Object.keys(referredUsers) &&
                      referredUsers.map((user, index) => (
                        <tr
                          key={index}
                          className={`${
                            index >= 5 && !verAfiliados ? 'hidden' : ''
                          } border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600`}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{moment(user.created_at).format('DD/MM/YYYY')}</td>
                        </tr>
                      ))}
                    {Object.keys(referredUsers).length > 5 && !verAfiliados && (
                      <tr>
                        <td className="text-right" colSpan={3}>
                          <a
                            href=""
                            className="text-primary font-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              setVerAfiliados(true);
                            }}>
                            + Ver todos
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
            </section>
            <section
              id="affiliate-transactions"
              className="flex flex-col-reverse lg:flex-row w-full lg:w-full items-start">
              <section
                id="affiliate-transactions-list"
                className="bg-white w-full rounded-2xl shadow p-5 flex lg:grow flex-col mb-4">
                <section className="flex flex-col lg:flex-row justify-between items-start">
                  <h1 className="font-bold text-2xl text-black-12 mb-3 dark:text-accent-dark">
                    Transacciones de afiliados
                  </h1>
                  <CalendarInput
                    inputClass={'h-9 border-black shadow-none mt-1.5 dark:bg-white'}
                    iconClass="top-[33%]"
                    type="range"
                    initialDate={null}
                    action={(e) => manageTransactionFilter(e)}
                  />
                </section>
                <table>
                  <thead className="border-b border-black-4">
                    <tr className="text-left child:py-3 text-sm">
                      <th>Usuario</th>
                      <th>Fecha</th>
                      <th>Pagado</th>
                      <th>Ganado</th>
                      <th>Porcentaje</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(affiliateTransactions).length === 0 && (
                      <tr>
                        <td className="pt-3" colSpan={6}>
                          No tiene transacciones de afiliados
                        </td>
                      </tr>
                    )}
                    {Object.keys(affiliateTransactions) &&
                      affiliateTransactions.map(
                        (transaction, index) =>
                          transaction.user && (
                            <tr
                              key={index}
                              className={`${
                                index >= 5 && !verTransacciones ? 'hidden' : ''
                              } border-t border-black-4 child:py-3 text-sm hover:bg-gray-200 dark:hover:bg-gray-600`}>
                              <td>
                                {transaction.user.name} {transaction.user.surnames}
                              </td>
                              <td>{moment(transaction.created_at).format('DD/MM/YYYY')}</td>
                              <td>
                                {parseFloat(transaction.amount).toLocaleString('es-ES', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{' '}
                                €
                              </td>
                              <td
                                className={`${transaction.refunded ? 'text-warning-red' : 'text-secondary'} font-bold`}>
                                {parseFloat(transaction.credit).toLocaleString('es-ES', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{' '}
                                €
                              </td>
                              <td>
                                {parseFloat(transaction.percentage).toLocaleString('es-ES', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{' '}
                                %
                              </td>
                              <td>
                                {transaction.refunded ? (
                                  <span className="font-bold text-warning-red">Reembolsado</span>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          )
                      )}
                    {Object.keys(affiliateTransactions).length > 5 && !verTransacciones && (
                      <tr>
                        <td className="text-right" colSpan={6}>
                          <a
                            href=""
                            className="text-primary font-bold"
                            onClick={(e) => {
                              e.preventDefault();
                              setVerTransacciones(true);
                            }}>
                            + Ver todas
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
            </section>
          </section>
          <section id="user-card" className="w-full lg:!w-[300px] lg:!min-w-[300px] mb-4">
            <div className="bg-white rounded-2xl shadow p-3 flex flex-col">
              <img className="h-20 object-contain" src="https://cdn-icons-png.flaticon.com/512/194/194938.png" />
              <h2 className="text-black-12 font-bold text-base text-center mt-3">
                {user?.name} {user?.surnames}
              </h2>
              <h3 className="text-black-8 text-base text-center mb-3">({user?.username})</h3>
              <section id="card-info" className="px-2">
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Email
                  </span>
                  {user?.email}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Identificador de Telegram
                  </span>
                  {user?.telegram_id ?? 'No tiene identificador'}
                </p>
                <p className="text-base mb-3 flex flex-col">
                  <span className="text-black-10 text-sm bg-black-3 dark:bg-primary-1 px-1.5 py-0 rounded w-fit">
                    Se unió
                  </span>
                  {moment(user?.created_at).fromNow()}
                </p>
              </section>
            </div>
          </section>
        </section>
        <MoneyToInfluencerPopup
          display={displayMoneyToInfluencerPopup}
          closePopup={async () => {
            setDisplayMoneyToInfluencerPopup(!displayMoneyToInfluencerPopup);
            setOrderData(false);
            getUserData();
          }}
          user={user}
          order={orderData}
        />
      </div>
    )
  );

  async function manageTransactionFilter(e) {
    if (e[0] && e[1]) {
      const start_date = moment(e[0]).format('YYYY-MM-DD');
      const end_date = moment(e[1]).format('YYYY-MM-DD');

      const affiliateTransactions = await getAffiliateTransactions(user, start_date, end_date);
      setAffiliateTransactions(affiliateTransactions);
    }
  }
};

export default InfluencerDetail;
