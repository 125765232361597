import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useEffect, useState } from 'react';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { toast } from 'react-toastify';
import updateUser, { createUser } from '../../services/users';

const CreditWorkerPopup = ({ display, closePopup, data = null }) => {
  const [fields, setField] = useState(
    data === null
      ? {
          name: '',
          surnames: '',
          username: '',
          email: '',
          telegram_id: '',
          role: 'worker',
          password: '',
        }
      : data
  );
  useEffect(() => {
    if (data !== null) {
      setField(data);
    }
  }, [data]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = { ...fields };
    if (data === null) {
      await createUser(form);
      toast.success('Trabajador creado', {
        icon: '👌',
      });
    } else {
      const form = { ...fields };
      await updateUser(form);
      toast.success('Trabajador actualizado', {
        icon: '👌',
      });
    }
    setField({
      name: '',
      surnames: '',
      username: '',
      email: '',
      telegram_id: '',
      role: 'worker',
      password: '',
    });
    closePopup();
  };

  return (
    <>
      <Popup display={display} width={'md:w-6/12 md:min-w-6/12'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <h3 className="text-xl font-semibold dark:text-accent-dark">
            {data === null ? 'Añadir' : 'Editar'} trabajador
          </h3>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => {
                closePopup();
                setField({
                  bet: '',
                  bookmarker_id: '',
                  state: '',
                  event: '',
                  profit: '',
                  comment: '',
                  stake: '',
                  qualifying_loss: '',
                });
              }}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        {/* body */}
        <form
          onSubmit={handleSubmit}
          className="relative px-6 flex-auto bg-white flex flex-wrap child:mb-4 rounded-b-xl">
          <div className="w-full xl:w-8/12 xl:pr-4">
            <label className="dark:text-normal-dark" htmlFor="name">
              Nombre
            </label>
            <Input
              id="name"
              width="w-full"
              placeholderMessage={'John'}
              autoFocus
              name="name"
              value={fields.name}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-6/12 pr-4 xl:pr-0 xl:w-4/12">
            <label className="dark:text-normal-dark" htmlFor="surnames">
              Apellidos
            </label>
            <Input
              id="surnames"
              width="w-full"
              placeholderMessage={'Doe Smith'}
              name="surnames"
              value={fields.surnames}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-6/12 xl:pr-4 xl:w-4/12">
            <label className="dark:text-normal-dark" htmlFor="username">
              Nombre de usuario
            </label>
            <Input
              id="username"
              width="w-full"
              placeholderMessage={'john.doe'}
              name="username"
              value={fields.username}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-6/12 pr-4 xl:pr-4 xl:w-4/12">
            <label className="dark:text-normal-dark" htmlFor="email">
              Email
            </label>
            <Input
              id="email"
              width="w-full"
              placeholderMessage={'johndoe@gmail.com'}
              name="email"
              value={fields.email}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-6/12 xl:w-4/12">
            <label className="dark:text-normal-dark" htmlFor="password">
              Contraseña
            </label>
            <Input
              id="password"
              width="w-full"
              placeholderMessage={'****'}
              name="password"
              type={'password'}
              value={fields.password}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="w-full xl:w-full">
            <label className="dark:text-normal-dark" htmlFor="telegram_id">
              Telegram Id
            </label>
            <Input
              id="telegram_id"
              width="w-full"
              placeholderMessage={'443083437'}
              name="telegram_id"
              value={fields.telegram_id}
              onChange={handleChange}
              className="mt-1"
            />
          </div>
          <div className="flex items-center justify-start py-3 rounded-b w-full">
            <Button
              className="mr-2"
              size={'large'}
              typeButton="submit"
              disabled={
                fields.name === '' ||
                fields.surnames === '' ||
                fields.username === '' ||
                fields.email === '' ||
                fields.password === ''
              }>
              Enviar
            </Button>
          </div>
        </form>
        {/* footer */}
      </Popup>
    </>
  );
};

export default CreditWorkerPopup;
