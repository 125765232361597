import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function SuperCuotas() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={'Como afrontar las Cuotas Mejoradas (ODDSBOOST)'} />
      <p>Bienvenido a la guía dónde se detalla el funcionamiento de las cuotas mejoradas.</p>
      <p>
        El primer punto a tener en cuenta cuando se intenta desbloquear una promoción que involucra una cuota mejorada
        es la forma de pago de las ganancias derivadas de la supercuota.
      </p>
      <p>Existen 3 formas en las que las casas de apuestas nos pueden pagar nuestras apuestas con cuota mejorada:</p>
      <ul className="list-disc ml-3">
        <li>SALDO REAL</li>
        <li>APUESTAS GRATUITAS</li>
        <li>SALDO DE BONO (Rollover)</li>
      </ul>
      <p>A continuación, te detallamos como desbloquear cada una de las 3 formas, por orden de dificultad;</p>
      <h2>Saldo Real</h2>
      <p>
        Empezaremos con las supercuotas que nos pagan el beneficio en saldo real. Estas promociones no tienen ninguna
        dificultad y su desbloqueo es tan simple como abrir la calculadora normal y rellenar con la cuota mejorada y la
        cuota en contra en betfair exchange. Casas de apuestas como Leovegas, Enracha o Starvegas ofrecen esta tipología
        de promoción de bienvenida.
      </p>
      <h2>Apuestas gratuitas</h2>
      <p>
        Ahora vamos a explicar como desbloquear las supercuotas que nos pagan la ganancia en forma de free bets. Lo
        primero que debemos entender, es que la apuesta que hacemos en saldo real se paga a la cuota normal y las
        ganancias extras en free bets. Lo veremos mejor con un ejemplo.
      </p>
      <p>
        Codere nos ofrece la victoria del Bayern de Múnich a cuota mejorada 5.00 y su cuota real es de 2.00 en Codere y
        la cuota en contra en Betfair Exchange es de 2.10. Lo primero que tenemos que hacer es decidir como queremos
        afrontar la promoción, ¿queremos asegurar la apuesta de forma normal y esperar que el Bayern gane y recibir el
        extra en free bets (mayor beneficio y riesgo de no ganar nada si el Bayern no gana) o queremos asegurar la
        apuesta para ganar lo mismo pase lo que pase (menos beneficio pero asegurado)?
      </p>
      <p>¿Te va el Riesgo?</p>
      <p>
        Si lo que te va es el riesgo, entonces la promoción se realiza simplemente rellenando la calculadora normal con
        el importe de apuesta, la cuota real de Codere y la cuota en contra. Si el equipo con cuotas mejoradas consigue
        ganar recibirás las free bets referentes a las ganancias extras. Si no lo consigue tendrás una pérdida vinculada
        con tu apuesta calificante. Esta forma de afrontar esta promoción es más rentable al largo plazo pero puedes
        sufrir periodos en que no aciertes ninguna cuota mejorada y tus pérdidas se vayan comiendo tu beneficio
        acumulado.
      </p>
      FOTO
      <p>
        De este modo, si el Bayern gana el partido vamos a recibir 15 € en apuesta gratuitas de los cuales podemos
        esperar un retorno del 70%, que son 10,50 €. Una vez descontado la pérdida calificante (-0,29 €) nos da un
        beneficio potencial de 10,21 €. En el caso de que el Bayern pierda o empate el partido, tendrás una pérdida
        resultante de tu apuesta calificante. (-0,29 €)
      </p>
    </section>
  );
}
