import { Navigate, Outlet } from 'react-router-dom';

const HasSubscriptionRoute = ({ hasValidSubscription, role, redirectPath = '/subscriptions/my-subscription' }) => {
  if (
    role !== 'admin' &&
    role !== 'worker' &&
    role !== 'influencer' &&
    (hasValidSubscription || hasValidSubscription === undefined)
  ) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default HasSubscriptionRoute;
