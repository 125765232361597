import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import moment from 'moment';
import { toast } from 'react-toastify';
import { updateIncident } from '../../services/bookmarkerIncidents';

const DetailIncidentPopup = ({ display, closePopup, data = null }) => {
  const options = [
    { value: 'open', label: 'Abierta' },
    { value: 'closed', label: 'Cerrada' },
    { value: 'solved', label: 'Resuelta' },
  ];

  return (
    data && (
      <>
        <Popup display={display} width={'md:w-8/12 h-[90vh] md:min-w-6/12'}>
          <div className="flex items-start justify-between p-6 rounded-t">
            <section className="flex flex-col">
              <h3 className="text-sm bg-black-4 w-fit rounded px-1.5 dark:px-0 dark:text-normal-dark dark:bg-transparent">
                Detalle de incidencia
              </h3>
              <h3 className="text-xl font-semibold dark:text-accent-dark">{data.title}</h3>
            </section>
            <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
              <span
                onClick={() => closePopup()}
                className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
                ×
              </span>
            </button>
          </div>
          <section className="flex flex-col lg:flex-row gap-2 pr-4 pl-1 w-full">
            <div className="flex flex-col w-full">
              <div className="flex flex-col px-5">
                <h3 className="text-sm dark:text-normal-dark">Mensaje:</h3>
              </div>
              <section className="px-5 pb-6 flex-auto">
                <div
                  className="bg-black-2 p-3 rounded-lg mt-2 border border-black-12 dark:bg-primary-1 dark:text-accent-dark"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </section>
            </div>
            <div className="flex mx-auto flex-col pl-5 lg:pl-0 pr-4 mb-5">
              <h3 className="text-sm mb-2 dark:text-normal-dark">Cambiar estado de incidencia:</h3>
              <Select
                name="state"
                initialValue={options.find((option) => option.value === data.state)}
                placeholderMessage="Estado de la incidencia"
                action={(e) => {
                  data.state = e.value;
                }}
                options={options}
                width="w-full"
              />
              <Button
                action={() => {
                  updateIncident(data);
                  toast.success('Incidencia actualizada');
                }}
                type="submit"
                className="mt-4 mb-5">
                Guardar
              </Button>
              <section className="text-sm mb-2 dark:text-accent-dark">
                Incidencia creada por{' '}
                <strong>{`${data.user.name} ${data.user.surnames} (${data.user.username})`}</strong> el{' '}
                {moment(data.created_at).format('LLL')}.
              </section>
            </div>
          </section>
        </Popup>
      </>
    )
  );
};

export default DetailIncidentPopup;
