import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import Settings from '../Settings';
import { useUser } from '../../../context/UserContext';
import { generateAffiliateToken, getReferredUsers } from '../../../services/users';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

const Affiliates = () => {
  const {
    state: { user },
  } = useUser();

  const [referredUsers, setReferredUsers] = useState([]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const referreds = await getReferredUsers(user);
        setReferredUsers(referreds);
      }
      fetchData();
    }
  }, [user]);

  return (
    user && (
      <Settings>
        <h1 className="font-bold text-2xl text-black-12 mb-4 dark:text-accent-dark">Afiliados</h1>

        <p className="text-black-10">Recibe el {user.affiliate_credits_percentage}% de sus compras en créditos</p>
        {referredUsers.length > 0 && (
          <div className="mt-5">
            <h2 className="font-bold text-lg text-black-10 mb-2 dark:text-normal-dark">Tus afiliados</h2>
            <div className="flex flex-row">
              {referredUsers.map((user, index) => (
                <div key={index} className="flex flex-row items-start mb-4">
                  <p className="text-black-10 mr-1 dark:text-normal-dark">
                    {user?.name} {user?.surnames}
                    {index !== referredUsers.length - 1 && ','}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        <Button
          size="large"
          width="w-full md:w-1/2 md:max-w-[250px]"
          className="mt-5"
          action={async () => {
            if (!user.affiliate_token) {
              const token = await generateAffiliateToken(user);
              navigator.clipboard.writeText(`${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${token}`);
            } else {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_PUBLIC_PAGE_URL}/?affiliate=${user.affiliate_token}`
              );
            }
            toast.success('¡El código de invitación ha sido copiado en el portapapeles!');
          }}>
          Copiar link de afiliados
        </Button>
      </Settings>
    )
  );
};

export default Affiliates;
