import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import getSubscriptions from '../../../services/subscriptions';
import Subscriptions from '../Subscriptions';

const Invoices = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  const [subscriptions, setSubscriptions] = useState(null);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getSubscriptions(user.id);
        setSubscriptions(response);
      }
      fetchData();
    }
  }, [user]);

  if (!subscriptions) {
    return <LinearProgress className="!fixed inset-x-0 top-0 !z-100" />;
  }

  return (
    <Subscriptions>
      <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-3">Mis facturas</h1>
      <table>
        <thead className="border-b border-black-4">
          <tr className="text-left child:py-5 text-sm">
            <th>Fecha de inicio</th>
            <th>Fecha de fin</th>
            <th>Estado</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(subscriptions).length === 0 && (
            <tr>
              <td className="pt-5 text-lg font-bold" colSpan={5}>
                No tienes facturas
              </td>
            </tr>
          )}
          {Object.keys(subscriptions).length > 0 &&
            subscriptions.order.map((invoice, index) => (
              <tr key={index} className="border-t border-black-4 child:py-5 text-sm">
                <td>{invoice.start_date}</td>
                <td>{invoice.end_date}</td>
                {invoice.charged ? (
                  <td className="text-secondary font-bold leading-6">{'Pagado'}</td>
                ) : (
                  <td className="text-warning-red font-bold leading-6">{'Pendiente'}</td>
                )}
                <td className="font-bold">{invoice.total} €</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Subscriptions>
  );
};

export default Invoices;
