import axios from '../lib/axios';
import FileSaver from 'file-saver';

export default async function updateUser(user) {
  const response = await axios
    .put(`/api/v1/users/${user.id}/`, user)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getUser(id) {
  const response = await axios
    .get(`/api/v1/users/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function changePassword(user) {
  const response = await axios
    .put(`/api/v1/users/${user.id}/change-password`, { password: user.password })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getWorkers(params = '') {
  const response = await axios
    .get(`/api/v1/users?role=worker${params.replace('?', '&')}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getInfluencers(params = '') {
  const response = await axios
    .get(`/api/v1/users?role=influencer&${params.replace('?', '&')}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getNonManagementUsers(params = '') {
  const response = await axios
    .get(
      `/api/v1/users?page_size=30&with=joined_by&${
        !params.includes('page_number') ? 'page_number=1' : ''
      }${params.replace('?', '&')}`
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function exportToCsv(params = '') {
  const arrayParams = params.replace('?', '').split('&');
  let fileName = 'users';
  arrayParams.forEach((element) => {
    const datos = element.split('=');
    switch (datos[0]) {
      case 'role':
        fileName += datos[1] !== null ? '_role_' + datos[1].toString() : '';
        break;
      case 'is_test':
        fileName += datos[1] === 'false' ? '_no_test' : datos[1] === 'true' ? '_test' : '';
        break;
      case 'days_to_expired':
        fileName += datos[1] !== null ? '_days_to_expired_' + datos[1].toString() : '';
        break;
      case 'subscription_active':
        fileName += datos[1] === '0' ? '_no_subscription_active' : datos[1] === '1' ? '_subscription_active' : '';
        break;
      default:
        break;
    }
  });

  await axios
    .get(`/api/v1/users/export-csv?role=user&export_csv=1&${params.replace('?', '&')}`, { responseType: 'blob' })
    .then((res) => {
      const csvData = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
      FileSaver.saveAs(csvData, fileName + '.csv');
    })
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function getAllNonManagementUsers(params = '') {
  const response = await axios
    .get(`/api/v1/users/non-management`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function deleteUser(id) {
  await axios
    .delete(`/api/v1/users/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createUser(user) {
  await axios
    .post(`/api/v1/users/`, user)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function sendTelegramInvitation(id) {
  return await axios
    .get(`/api/v1/users/${id}/send-telegram-invitation`)
    .then((res) => {
      return {
        message: 'Invitación enviada',
        icon: '🤙',
        status: 'success',
      };
    })
    .catch((error) => {
      if (error === "Forbidden: bot can't initiate conversation with a user") {
        error = 'El bot no puede iniciar un chat con un usuario que no conce';
      }
      return {
        message: error,
        icon: '🤖',
        status: 'success',
      };
    });
}

export async function getTelegramInfo(user_id) {
  const response = await axios
    .get(`/api/v1/users/${user_id}/telegram-info`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function generateAffiliateToken(user) {
  const response = await axios
    .post(`/api/v1/users/${user.id}/generate-affiliate-token`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function unselectUserBookmarer(id, bookmarkers) {
  const response = await axios
    .post(`/api/v1/users/${id}/unselect-bookmarker`, bookmarkers)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getReferredUsers(user) {
  const response = await axios
    .get(`/api/v1/users/${user.id}/referred-users`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getAffiliateTransactions(user, start_date, end_date) {
  let url = `/api/v1/users/${user.id}/affiliate-transactions`;
  if (start_date) {
    url = `/api/v1/users/${user.id}/affiliate-transactions?start_date=${start_date}&end_date=${end_date}`;
  }
  const response = await axios
    .get(url)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getPaymentsReceived(user) {
  const response = await axios
    .get(`/api/v1/users/${user.id}/payments-received`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function createPayment(user_id, order) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/payment`, order)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getCourse(user) {
  const response = await axios
    .get(`/api/v1/users/${user.id}/course`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function updateReadArticle(user, article) {
  const response = await axios
    .put(`/api/v1/users/${user.id}/course/article/read`, { article_id: article.id, read: article.read })
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
