import useSWR from 'swr';
import axios from '../lib/axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';
import { useUser } from '../context/UserContext';
import { useCookies } from 'react-cookie';

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies(['logged']);

  const { dispatch } = useLoading();
  const { dispatchUser } = useUser();
  const {
    data: user,
    error,
    mutate,
  } = useSWR(
    '/me',
    () =>
      axios
        .get('/me')
        .then((res) => {
          setCookie('logged_user_name', res.data.name != null ? res.data.name : res.data.email, {
            domain: 'vilibets.com',
          });
          dispatchUser({ type: 'hasUser', dispatch: res.data });
          return res.data;
        })
        .catch((error) => {
          setCookie('logged_user_name', null, { domain: 'vilibets.com' });
          dispatchUser({ type: 'hasNotUser', dispatch: undefined });
          if (error.response.status !== 409) throw error;

          navigate('/verify-email');
        }),
    { refreshInterval: 10000 }
  );

  const csrf = () => axios.get('/sanctum/csrf-cookie');

  const register = async ({ setErrors, ...props }) => {
    setErrors([]);
    try {
      await csrf();

      axios
        .post('/register', props)
        .then((value) => {
          dispatch({ type: 'notLoading' });
          mutate();
        })
        .catch((error) => {
          dispatch({ type: 'notLoading' });
          if (error.response.status !== 422) throw error;
          setErrors(error.response.data);
        });
    } catch (error) {
      dispatch({ type: 'notLoading' });
      setErrors(error.message);
    }
  };

  const login = async ({ setErrors, ...props }) => {
    setErrors([]);
    try {
      await csrf();

      axios
        .post('/login', props)
        .then(() => {
          dispatch({ type: 'notLoading' });
          mutate();
        })
        .catch((error) => {
          dispatch({ type: 'notLoading' });
          if (error.response.status !== 422) throw error;
          setErrors(error.response.data);
        });
    } catch (error) {
      dispatch({ type: 'notLoading' });
      setErrors(error.message);
    }
  };

  const forgotPassword = async ({ setErrors, setStatus, email }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post('/forgot-password', { email })
      .then((response) => {
        dispatch({ type: 'notLoading' });
        setStatus(response);
      })
      .catch((error) => {
        dispatch({ type: 'notLoading' });
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data);
      });
  };

  const resetPassword = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors([]);
    setStatus(null);

    axios
      .post('/reset-password', { ...props })
      .then((response) => {
        dispatch({ type: 'notLoading' });
        navigate('/login?reset=' + btoa(response.data.status));
      })
      .catch((error) => {
        dispatch({ type: 'notLoading' });
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data);
      });
  };

  const resendEmailVerification = ({ setStatus }) => {
    axios.post('/email/verification-notification').then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    if (!error) {
      await axios.post('/logout').then(() => mutate());
    }
    dispatchUser({ type: 'hasNotUser', dispatch: undefined });
    setCookie('logged_user_name', null, { domain: 'vilibets.com' });
    window.location.pathname = '/login';
  };

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) navigate(redirectIfAuthenticated);
    if (middleware === 'auth' && error) logout();
  }, [user, error]);

  return {
    user,
    register,
    login,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
  };
};
