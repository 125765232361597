export const worker = (dashboard) => {
  const series_bounds_populars = dashboard?.count_bond_id_completed_bonds?.map((bond) => bond?.count) || [];
  const options_bounds_populars = {
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    labels: dashboard?.count_bond_id_completed_bonds?.map((bond) => bond?.title) || [],
    legend: {
      formatter: (seriesName, opts) => {
        return (
          seriesName +
          ' ' +
          (
            (opts.w.config.series[opts.seriesIndex] / opts.w.config.series.reduce((acc, cur) => acc + cur, 0)) *
            100
          ).toFixed(2) +
          '%'
        );
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  const type_bounds_populars = 'donut';

  const series = [
    {
      name: 'Perdidas',
      data:
        [
          dashboard?.profit_and_loss_comparative_for_clients?.loss?.loss_media_downward,
          dashboard?.profit_and_loss_comparative_for_clients?.loss?.loss_media,
          dashboard?.profit_and_loss_comparative_for_clients?.loss?.loss_media_upward,
        ] || [],
    },
    {
      name: 'Beneficios',
      data:
        [
          dashboard?.profit_and_loss_comparative_for_clients?.profit?.profit_media_downward,
          dashboard?.profit_and_loss_comparative_for_clients?.profit?.profit_media,
          dashboard?.profit_and_loss_comparative_for_clients?.profit?.profit_media_upward,
        ] || [],
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },

      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    xaxis: {
      type: 'string',
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: 'left',
    },
  };

  const type_bar = 'bar';

  const series_incidents_betting_house = [
    {
      data: dashboard?.incidents_bar_graph?.map((incident) => incident?.incidents) || [],
    },
  ];
  const options_incidents_betting_house = {
    chart: {
      height: 350,
      type: 'bar',
      events: {},
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dashboard?.incidents_bar_graph?.map((incident) => incident?.title) || [],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  const series_bonds_created_for_betting_house = [
    {
      data: dashboard?.bonds_created_for_betting_house_bar_graph?.map((bonds) => bonds?.count_bonds_house) || [],
    },
  ];
  const options_bonds_created_for_betting_house = {
    chart: {
      height: 350,
      type: 'bar',
      events: {},
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dashboard?.bonds_created_for_betting_house_bar_graph?.map((bonds) => bonds?.title) || [],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  return {
    series_bounds_populars,
    options_bounds_populars,
    type_bounds_populars,
    series,
    options,
    type_bar,
    series_incidents_betting_house,
    options_incidents_betting_house,
    series_bonds_created_for_betting_house,
    options_bonds_created_for_betting_house,
  };
};
