import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { LogoVilibets } from '@vilibets/vilibets-uikit/dist/atoms/imgs';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { useUser } from '../../context/UserContext';
import { useAuth } from '../../hooks/auth';

const PopupVerifyEmail = ({ ...props }) => {
  const {
    state: { user },
  } = useUser();
  const { resendEmailVerification, logout } = useAuth({});
  return (
    <Popup width={'md:w-6/12 md:min-w-6/12'} display={user?.email_verified_at === null}>
      <div className="flex items-center justify-center px-5 pt-5 rounded-t">
        <LogoVilibets className="w-24 md:w-32 xl:w-36" />
      </div>
      {/* body */}
      <div className="relative px-6 flex-auto">
        <p className="my-4 text-slate-500 text-lg leading-relaxed dark:text-accent-dark text-justify">
          ¡Gracias por registrarte! Antes de empezar, <strong>debes</strong> verificar tu correo electrónico haciendo
          clic en el enlace
          <strong>
            {' '}
            del correo que acabas de recibir. Si no recibiste el correo electrónico, pulsa en Reenviar verificación y te
            enviaremos otro correo.
          </strong>
        </p>
      </div>
      {/* footer */}
      <div className="flex items-center justify-end px-6 pb-6 rounded-b gap-2">
        <Button action={resendEmailVerification}>Reenviar verificación</Button>
        <Button action={logout}>👋 Logout</Button>
      </div>
    </Popup>
  );
};
export default PopupVerifyEmail;
