import { Alert } from '@vilibets/vilibets-uikit/dist/atoms/Alert';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button/Button';
import { useAuth } from '../../../hooks/auth';
import Settings from '../Settings';
import { downloadExcel } from '../../../services/settings';

const Excel = () => {
  const { user } = useAuth({
    middleware: 'auth',
  });

  return (
    user && (
      <Settings>
        <Alert hidden={true} /> {/* Para desactivarlo, usaremos toast */}
        <h1 className="font-bold text-2xl text-black-12 mb-2 dark:text-accent-dark">Plantilla Excel</h1>
        <h2 className="mt-2 text-l text-black-12 mb-4 dark:text-accent-dark">
          Descárgate la hoja de cálculo para llevar el seguimiento de todas tus apuestas.
        </h2>
        <Button size="large" width="w-full md:w-1/2 md:max-w-[250px]" className="mt-5" action={downloadExcel}>
          Descargar
        </Button>
      </Settings>
    )
  );
};

export default Excel;
