import FeaturedGuidesText from '../../components/FeaturedGuidesText';
import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function QueEsElMatchBetting() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={'Introducción al matched betting'} />

      <p>
        Te recomendamos que te pongas cómodo por qué esta primera guía es una poco larga. Para nosotros es muy
        importante darte información y unos buenos fundamentos con los que vas a empezar a ganar dinero con el Matched
        Betting.
      </p>
      <FeaturedGuidesText>
        Tanto si eres completamente nuevo en el mundo de las apuestas como si eres un apostador amateur buscando un
        cambio, este es un buen lugar donde empezar.
      </FeaturedGuidesText>
      <p>
        ¡Si estás leyendo esta guía es una muy buena señal! En los próximos minutos aprenderás a ganar dinero online con
        unos pocos días de formación.
      </p>
      <p>
        Nosotros no somos unos charlatanes cualesquiera del mundillo de las apuestas, nuestra comunidad de usuarios nos
        avala. Este método tiene sus bases en las matemáticas y pronto verás cómo es imposible perder dinero.
      </p>
      <h2 className="py-3">¿Qué es el Matched Betting?</h2>
      <p>
        En términos muy simples, el matched betting es una técnica dónde se hacen dos apuestas en vez de una. Las
        apuestas se contrarrestan entre ellas con el objetivo de desbloquear apuestas gratuitas o bonos para después
        transformarlas en dinero real. Con este tipo de apuestas no estás arriesgando tu dinero como si estuvieras
        apostando, ya que sea cual sea el resultado del partido vas a recuperar tu saldo.
      </p>
      <p>
        No te asustes si aún todo te parece extraño, es lo más normal. A todos nos pasa la primera vez que nos lo
        cuentan. Seguramente tengas un montón de dudas y preguntas que hacernos, pero lo mejor es que te leas toda la
        guía para hacerte una idea básica del funcionamiento del método.
      </p>
      <p>Así que, veamos cómo funciona…</p>
      <FeaturedGuidesText label={'Con el matched betting vas a poder:'}>
        <ul className="marker:text-primary mt-3 list-outside list-disc ml-4 child:mb-2">
          <li>Aprender a transformar las promociones de las casas de apuestas en dinero real.</li>
          <li>Ganarte un extra cada mes.</li>
          <li>Eliminar el concepto de riesgo asociado con las apuestas.</li>
          <li>Enseñar a tus amigos y familiares a ganarse un sueldo extra cada mes.</li>
        </ul>
      </FeaturedGuidesText>
      <FeaturedGuidesText
        margin="mt-7"
        borderColor="secondary"
        label={'Con el matched betting vas a poder:'}
        className="mt-5">
        <ul className="marker:text-secondary mt-3 list-outside list-disc ml-4 child:mb-2">
          <li>Aprender a transformar las promociones de las casas de apuestas en dinero real.</li>
          <li>Ganarte un extra cada mes.</li>
          <li>Eliminar el concepto de riesgo asociado con las apuestas.</li>
          <li>Enseñar a tus amigos y familiares a ganarse un sueldo extra cada mes.</li>
        </ul>
      </FeaturedGuidesText>
      <h3 className="text-xl font-bold text-black-12 flex flex-row items-center mt-7">
        <span className="text-primary text-2xl mr-3">1</span>
        <span>Los dos tipos de apuestas que existen</span>
      </h3>
      <p>
        Una apuesta se produce cuando alguien intenta predecir el resultado de un evento (como podría ser un partido de
        futbol o de baloncesto). Si te paras a pensar, las apuestas están presentes en la vida diaria. Aquí están
        algunos ejemplos.
      </p>
      <section id="conversation">
        <div className="mb-4">
          <div className="inline-block dark:text-black-11 p-3 bg-primary-3 mb-2">
            <span className="font-bold dark:text-black-11 mr-2">Marc:</span>
            ¡Me apuesto una birra a que te gano a un pulso!
          </div>
          <br />
          <div className="inline-block dark:text-black-11 p-3 bg-warning-red-2">
            <span className="font-bold dark:text-black-11 mr-2">Pere:</span>
            ¡No te lo crees ni tú, deja el dinero sobre la mesa y lo comprobamos!
          </div>
        </div>
        <div className="mb-4">
          <div className="inline-block dark:text-black-11 p-3 bg-primary-3 mb-2">
            <span className="font-bold dark:text-black-11 mr-2">Marc:</span>
            ¡Me apuesto algo que hoy no viene!
          </div>
          <br />
          <div className="inline-block dark:text-black-11 p-3 bg-warning-red-2">
            <span className="font-bold dark:text-black-11 mr-2">Pere:</span>
            ¡No lo creo, la he visto hoy!
          </div>
        </div>
        <div className="mb-4">
          <div className="inline-block dark:text-black-11 p-3 bg-primary-3 mb-2">
            <span className="font-bold dark:text-black-11 mr-2">Marc:</span>
            ¡Me apuesto algo que Trump gana las elecciones!
          </div>
          <br />
          <div className="inline-block dark:text-black-11 p-3 bg-warning-red-2">
            <span className="font-bold dark:text-black-11 mr-2">Pere:</span>
            ¡No! ¡Yo apuesto a que gana Biden! ¡Todas las encuestas están a su favor!
          </div>
        </div>
      </section>
      <p>
        Véase como cualquier apuesta es, en realidad,{' '}
        <strong>dos opiniones contrapuestas sobre cómo va a terminar un evento.</strong>
      </p>
      <p>
        Por un lado, Marc predice que Trump va a ganar las elecciones, y está tan seguro que quiere apostarse algo. Si
        Trump gana las elecciones, habrá predicho correctamente el resultado de la apuesta y por lo tanto la{' '}
        <strong>habrá ganado.</strong>
      </p>
      <p>
        Pero no sería una apuesta si Pere no estuviera <strong>dispuesto a apostar por el resultado contrario.</strong>{' '}
        Para que una apuesta se formalice siempre tendrá que haber alguien dispuesto a apostar por el resultado
        contrario. Si Trump no consigue ganar las elecciones, Pere habrá predicho correctamente el resultado de la
        apuesta y por lo tanto la <strong>habrá ganado.</strong>
      </p>
      <FeaturedGuidesText>
        Como puedes ver cualquier apuesta tiene dos partes. Y esto es muy importante porque{' '}
        <strong>haciendo Matched Betting apostaremos en las dos partes.</strong>
        <br />
      </FeaturedGuidesText>
      <p>
        El término técnico que vamos a utilizar para los dos tipos de apuesta va a ser apuesta&nbsp;
        <span className="estilo-favor">a favor</span>&nbsp;y apuesta&nbsp;
        <span className="estilo-contra">en contra</span>. Con los ejemplos anteriores, Marc estaría apostando&nbsp;
        <span className="estilo-favor">a favor</span> de que Trump va a ganar. Pere en cambio estaría apostando{' '}
        <span className="estilo-contra">en contra</span> del resultado que predice Marc.
      </p>
      <FeaturedGuidesText>
        Cuando predices que algo va a suceder estás{' '}
        <strong>
          apostando&nbsp;<span className="estilo-favor">a favor</span>.
        </strong>
      </FeaturedGuidesText>
      <FeaturedGuidesText borderColor="secondary">
        Cuando predices que algo no va a suceder estás&nbsp;
        <strong>
          apostado&nbsp;<span className="estilo-contra">en contra</span>.
        </strong>
      </FeaturedGuidesText>

      <h3 className="text-xl font-bold text-black-12 flex flex-row items-center mt-7">
        <span className="text-primary text-2xl mr-3">2</span>
        <span>Como y dónde apostar a FAVOR y en CONTRA en las casas de apuestas.</span>
      </h3>
      <p>
        Seguramente hayas hecho alguna apuesta en una casa de apuestas antes (o conoces alguien que lo haya hecho) pero
        si no, no te alarmes.
      </p>
      <p>
        Básicamente siempre que entres en una casa de apuestas para apostar en algún evento (partido de fútbol,
        baloncesto o carreras de caballos) estás apostando a que<strong> un evento va a suceder.</strong> Si predices el
        resultado correcto del evento, <strong>vas a ganar dinero.</strong>
      </p>
      <p>
        Recuerda que{' '}
        <strong>
          toda apuesta está formada por dos opiniones contrapuestas sobre el resultado del evento en cuestión
        </strong>
        . Cuando haces una apuesta en una casa de apuestas, igual que en los ejemplos anteriores, estás apostado&nbsp;
        <span className="estilo-favor">a favor</span>&nbsp;de que se materialice tu predicción, pero esta vez es
        <strong>
          {' '}
          la misma casa de apuestas quien apuesta&nbsp;<span className="estilo-contra">en contra</span>
        </strong>
        &nbsp;de que el evento no va a suceder.
      </p>
      <p>Veámoslo en un partido de fútbol como ejemplo:</p>
      <FeaturedGuidesText>
        Juan entra en la casa de apuestas Bet365 y{' '}
        <strong>apuesta 5 € a que el Madrid va a ganar contra el Valencia</strong> ese mismo sábado a las 18:00 horas.
        En otras palabras, Juan está apostando <span className="estilo-favor">a favor</span> que el Madrid va a ganar al
        Valencia.
        <br />
        La casa de apuestas iguala esa apuesta con el resultado opuesto,{' '}
        <strong>que gana el Valencia o que empatan</strong>. En otras palabras, la casa de apuestas está apostado&nbsp;
        <span className="estilo-contra">en contra</span>&nbsp;de que el Madrid gane el partido.
        <br />
        Si el <strong>Madrid gana</strong> el partido Juan habrá predicho correctamente el resultado del partido y por
        lo tanto le <strong>habrá ganado dinero a la casa de apuestas</strong>. Si el partido finaliza en empate o con
        victoria del Valencia Juan <strong>habrá perdido su apuesta y también su dinero.</strong>
        <br />
      </FeaturedGuidesText>
      <p>
        En resumen, todas las casas que previamente conocías solo te dan la oportunidad de apostar{' '}
        <span className="estilo-favor">A FAVOR</span> de que sucede un evento. Un poco más adelante vas a descubrir{' '}
        <strong>dónde y como apostar en contra de un resultado.</strong>
      </p>
      <h3 className="text-xl font-bold text-black-12 flex flex-row items-center mt-7">
        <span className="text-primary text-2xl mr-3">3</span>
        <span>Cuotas</span>
      </h3>
      <p>
        Si eres nuevo en las apuestas <strong>las cuotas pueden resultar un poco confusas.</strong> Vilibets está aquí
        para <strong>ayudarte a que las comprendas mejor.</strong> Si ya comprendes cómo funcionan las cuotas puedes
        saltarte este punto.
      </p>
      <p>
        Lo más importante que debes saber es que{' '}
        <strong>las cuotas son un reflejo de las probabilidades que algo pase.</strong>
      </p>
      <FeaturedGuidesText label={'Aquí algunos ejemplos. (Las cuotas son los números en negrita al final)'}>
        <ul className="marker:text-primary mt-3 list-outside list-disc ml-4 child:mb-2">
          <li>
            "Barcelona a ganar la Liga Santander" = <strong>1.70</strong>
          </li>
          <li>
            "Granada a ganar la Liga Santander" = <strong>100</strong>
          </li>
          <li>
            "Leo Messi anota durante el partido" = <strong>1.60</strong>
          </li>
          <li>
            "Ter Stegen anota durante el partido" = <strong>1000</strong>
          </li>
        </ul>
      </FeaturedGuidesText>
      <p>
        <strong>Como más alta es una cuota menor es la probabilidad de que suceda.</strong> Que Leo Messi marque un gol
        es mucho más probable que marque Ter Stegen, ¿verdad?
      </p>
      <p>
        Cuando apuestas&nbsp;<span className="estilo-favor">a favor</span>&nbsp;de un resultado a una cuota alta, si lo
        predices bien<strong> los beneficios van a ser mucho mayores</strong> que si apuestas&nbsp;
        <span className="estilo-favor">a favor</span>&nbsp;a una cuota menor.
      </p>
      <p>Así funciona:</p>
      <FeaturedGuidesText>
        Juan apuesta{' '}
        <strong>
          10 € <span className="estilo-favor">a favor</span>
        </strong>
        &nbsp;de que Leo Messi anota en el partido a una cuota&nbsp;<strong>1.60</strong>
        <br />
        Unas horas más tarde&nbsp;<strong>Leo Messi consigue anotar 1 gol</strong> en el partido en el que Juan había
        apostado. Juan <strong>ha predicho el resultado correctamente</strong> y la casa de apuestas le paga{' '}
        <strong>16 € (10 € x 1.60).</strong> En otras palabras,{' '}
        <strong>la casa de apuestas ha perdido 6 € y Juan los ha ganado y ha recibido de vuelta los 10 €</strong> que
        apostó inicialmente.
        <br />
        ¿Ves? ¡Es muy fácil! Solo tienes que <strong>multiplicar la cantidad apostada por la cuota</strong> y el número
        que te salga es la cantidad que te deberá ser pagada en caso de que tu apuesta re
        <strong>sulte ganadora.</strong>
        <br />
      </FeaturedGuidesText>
      <p>
        Cuando la casa de apuestas aceptó la apuesta, sabía que{' '}
        <strong>había un riesgo de&nbsp;6 € si Juan predecía el resultado del evento correctamente</strong> (Importe que
        le tenía que pagar a Juan en caso de que acertase la apuesta).
      </p>

      <h3 className="text-xl font-bold text-black-12 flex flex-row items-center mt-7">
        <span className="text-primary text-2xl mr-3">4</span>
        <span>Casas de contrapartida / Exchanges</span>
      </h3>
      <p>
        Con la llegada de <strong>Betfair</strong> en España en 2014, todo cambió. Ahora{' '}
        <strong>
          ya es posible apostar&nbsp;<span className="estilo-contra">en contra</span>,
        </strong>{' '}
        a través de su página web <strong>Exchange</strong>. Desde su plataforma los jugadores pueden apostar&nbsp;
        <span className="estilo-favor">a favor</span>&nbsp;y&nbsp;<span className="estilo-contra">en contra</span>
        &nbsp;en <span>multitud de eventos.</span>
      </p>
      <p>
        Si crees que el Barcelona no va a ganar el próximo partido puedes visitar la página web de{' '}
        <strong>Betfair</strong> y apostar <span className="estilo-contra">en contra</span> de la victoria del equipo
        blaugrana. También tienes la libertad de <strong>elegir a las cuotas a las que apostar,</strong> siempre que
        haya alguien dispuesto a comprarte la apuesta en el otro lado de la pantalla.
      </p>
      <p>
        Las <strong>casas de contrapartida</strong> son páginas web donde los jugadores pueden apostar entre ellos.
        Betfair únicamente actúa como proveedor del servicio y para ello cobra una pequeña{' '}
        <strong>comisión del 2% sobre las apuestas ganadas.</strong> Sería como eBay, pero para las apuestas.
      </p>
      <p>
        En la próxima guía entraremos en minucioso detalle sobre el tema de las{' '}
        <strong>casas de contrapartida y Betfair Exchange.</strong>
      </p>
    </section>
  );
}
