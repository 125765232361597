import { useAuth } from '../../../hooks/auth';
import { VarsTable } from '@vilibets/vilibets-uikit/dist/molecules/VarsTable';
import Settings from '../Settings';
import { useEffect, useState } from 'react';
import getSettings from '../../../services/settings';
import PopupGlobalVars from '../../../components/PopupGlobalVars/PopupGlobalVars';
import moment from 'moment';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { EditText } from '@vilibets/vilibets-uikit/dist/atoms/Icons';

const GlobalVars = () => {
  const [settings, setSettings] = useState([]);
  const [settingsLimitCaracters, setSettingsLimitCaracters] = useState([]);
  const [displayDetailPopup, setDisplayDetailPopup] = useState(false);
  const [editableRowData, setEditableRowData] = useState(null);
  const { user } = useAuth({
    middleware: 'auth',
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getSettings();

      getSettings().then((res) =>
        setSettingsLimitCaracters(
          res.map((item) => {
            item.value = item.value.substring(0, 100);
            if (item.value.length >= 100) item.value = item.value.concat('...');
            return item;
          })
        )
      );
      setSettings(response);
    }
    fetchData();
  }, []);

  return (
    user && (
      <Settings>
        <h1 className="font-bold text-2xl text-black-12 mb-4 dark:text-accent-dark">Variables globales</h1>
        <section className="w-full hidden lg:table">
          <VarsTable
            editElementAction={(element) => {
              setDisplayDetailPopup(true);
              setEditableRowData(settings.filter((item) => item.key === element.key)[0]);
            }}
            changeOrder={(col, dir) => {}}
            items={settingsLimitCaracters}
          />
        </section>
        <section className="lg:hidden">
          {settingsLimitCaracters.map((element, index) => (
            <div
              className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4 dark:bg-primary-1"
              key={index}>
              <div className="flex flex-col text-center mb-3">
                <p className="text-black-12 font-bold dark:text-accent-dark">{element.name}</p>
                <p className="text-black-10 text-sm ">{element.key}</p>
              </div>
              <div className="flex flex-col child:mb-3">
                <section>
                  <span className="text-black-10 text-sm bg-black-3 px-1.5 py-0 rounded">Valor</span>
                  <p className="text-black-12 font-bold dark:text-accent-dark">{element.value}</p>
                </section>
                <section>
                  <span className="text-black-10 text-sm bg-black-3 px-1.5 py-0 rounded">Actualizado hace</span>
                  <p className="text-black-12 font-bold break-words dark:text-accent-dark">
                    {element.updated_at ? moment(element.updated_at).fromNow() : '-'}
                  </p>
                </section>
              </div>
              <div className="flex flex-row mx-auto">
                <button
                  onClick={() => {
                    setDisplayDetailPopup(true);
                    setEditableRowData(settings.filter((item) => item.key === element.key)[0]);
                  }}
                  className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
                  <Icon className="stroke-black text-2xl">
                    <EditText />
                  </Icon>
                </button>
              </div>
            </div>
          ))}
        </section>
        <PopupGlobalVars
          display={displayDetailPopup}
          data={editableRowData}
          closePopup={() => {
            setDisplayDetailPopup(false);
            setEditableRowData(null);
            const response = getSettings().then((res) => {
              setSettingsLimitCaracters(
                res.map((item) => {
                  item.value = item.value.substring(0, 100);
                  if (item.value.length >= 100) item.value = item.value.concat('...');
                  return item;
                })
              );
              setSettings(res);
            });
            setSettings(response);
          }}
        />
      </Settings>
    )
  );
};

export default GlobalVars;
