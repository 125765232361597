import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './routes/App';

import { ToastContainer } from 'react-toastify';
import './lib/i18n';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { UserProvider } from './context/UserContext';

const loadTinyMCEScript = () => {
  const script = document.createElement('script');
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // eslint-disable-next-line
  console.log('apiKey', apiKey);

  script.src = `https://cdn.tiny.cloud/1/${apiKey}/tinymce/6/tinymce.min.js`;
  script.referrerPolicy = 'origin';
  document.head.appendChild(script);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <App />
    <ToastContainer />
  </UserProvider>
);

reportWebVitals();
// Carga el script para TinyMCE
loadTinyMCEScript();
