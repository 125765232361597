export const createOrEditBonds = () => {
  const initialForm = {
    bookmarker_id: '',
    title: '',
    start_at: '',
    type: '',
    type_of_bookmarker: '',
    level: '',
    type_of_bond: '',
    code: '',
    url: '',
    max_bet: '',
    min_bet: '',
    min_reward: '',
    max_reward: '',
    reward: '',
    condition: '',
    match: '',
    valid_market: '',
    golden_goal: false,
    send_it_telegram: false,
    need_invitation: false,
    risk: false,
    comment: '',
    description: '',
    content: '',
    video_url: '',
    type_repeats_bonds: '',
    calculators: [],
  };
  const typeBonds = [
    {
      value: 'welcome',
      label: 'Bienvenidas',
    },
    {
      value: 'top_bonds',
      label: 'Bonos superiores',
    },
    {
      value: 'recursive',
      label: 'Recurrentes',
    },
    {
      value: 'free_spins',
      label: 'Tiradas gratis',
    },
  ];
  const typeBookmarkers = [
    {
      value: 'sports',
      label: 'Deportes',
    },
    {
      value: 'casino',
      label: 'Casino',
    },
  ];
  const typeRepeating = [
    {
      value: '',
      label: 'No repetir',
    },
    {
      value: '1_day',
      label: 'Cada día',
    },
    {
      value: '1_week',
      label: 'Cada semana',
    },
    {
      value: '1_month',
      label: 'Cada mes',
    },
  ];
  const levels = [
    {
      value: 'easy',
      label: 'Fácil',
    },
    {
      value: 'medium',
      label: 'Medio',
    },
    {
      value: 'hard',
      label: 'Difícil',
    },
  ];
  const typeOfBond = [
    {
      value: 'bet_and_receive',
      label: 'Apuesta y recibe',
    },
    {
      value: 'bet_and_receive_with_condition',
      label: 'Apuesta y recibe con condiciones',
    },
    {
      value: 'superior_bonuses',
      label: 'Bonos superiores',
    },
    {
      value: 'cashbacks',
      label: 'Cashbacks',
    },
    {
      value: 'combined',
      label: 'Combinada',
    },
    {
      value: 'enhanced_quota',
      label: 'Cuota mejorada',
    },
    {
      value: 'deposit_and_receive',
      label: 'Deposito y recibo',
    },
    {
      value: 'refund',
      label: 'Reembolso',
    },
    {
      value: 'refund_with_conditions',
      label: 'Reembolso con condiciones',
    },
    {
      value: 'rollover_bonus',
      label: 'Rollover bonus',
    },
    {
      value: 'slots',
      label: 'Slots',
    },
  ];
  return { initialForm, typeBonds, typeBookmarkers, levels, typeOfBond, typeRepeating };
};
