import { Outlet } from 'react-router-dom';

const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  if (!user || user === undefined) {
    window.location.pathname = redirectPath;
  }
  return <Outlet />;
};

export default ProtectedRoute;
