import { AccumulatedFFCalculator } from '@vilibets/vilibets-uikit/dist/molecules/Calculators/Calculators';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Calculators from './Calculators';

const AccumulatedFFCalc = () => {
  // get params from url
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialParams, setInitialParams] = useState(null);
  // get searchParams and set it to initialParams

  useEffect(() => {
    let params = {};
    for (const [key, value] of searchParams) {
      params = { ...params, [key]: value };
    }
    if (params.combinedA) params.combinedA = parseFloat(params.combinedA.replace(',', '.'));
    if (params.cuoteA) params.cuoteA = params.cuoteA.split('-').map((element) => parseFloat(element.replace(',', '.')));
    if (params.comission)
      params.comission = params.comission.split('-').map((element) => parseFloat(element.replace(',', '.')));
    if (params.cuoteB) params.cuoteB = params.cuoteB.split('-').map((element) => parseFloat(element.replace(',', '.')));
    if (params.matrixSize) params.matrixSize = parseFloat(params.matrixSize);
    setInitialParams(params);
  }, []);

  return (
    <Calculators>
      {initialParams && (
        <AccumulatedFFCalculator
          initialParams={initialParams}
          onChange={(params) => {
            params.cuoteA = params.cuoteA.join('-');
            params.comission = params.comission.join('-');
            params.cuoteB = params.cuoteB.join('-');
            setSearchParams(params);
          }}
        />
      )}
    </Calculators>
  );
};

export default AccumulatedFFCalc;
