import Calculators from './Calculators';
import { DutchingCalculator } from '@vilibets/vilibets-uikit/dist/molecules/Calculators/Calculators';
import { useSearchParams } from 'react-router-dom';

const DutchingCalc = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // get searchParams and set it to initialParams
  const initialParams = () => {
    let params = {
      bc_bets: 2,
    };
    for (const [key, value] of searchParams) {
      params = { ...params, [key]: value };
    }
    return params;
  };

  return (
    <Calculators>
      <DutchingCalculator
        initialParams={initialParams}
        onChange={(params) => {
          setSearchParams(params);
        }}
      />
    </Calculators>
  );
};

export default DutchingCalc;
