import { GraphicCardNumber, Graphic } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { WelcomeWizard } from '@vilibets/vilibets-uikit/dist/molecules/Wizards/Wizards';
import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import updateUser from '../../services/users';
import { user as cUser } from './constants/user';

const DashboardInfluencer = ({ dashboard }) => {
  const { type, optionsProfitAndLoss, seriesProfitAndLoss, optionsCreditsByReferred, seriesCreditsByReferred } =
    cUser(dashboard);

  const {
    dispatchUser,
    state: { user },
  } = useUser();

  const [showWizard, setShowWizard] = useState(false);

  useEffect(() => {
    if (user) {
      setShowWizard(
        user?.is_showed_wizard_welcome && new Date(user?.subscription?.end_date).getTime() >= new Date().getTime()
      );
    }
  }, [user]);

  const closeWizard = async () => {
    setShowWizard(false);
    const response = await updateUser({ ...user, is_showed_wizard_welcome: false });
    dispatchUser({ type: 'hasUser', dispatch: response });
  };

  return (
    <>
      {showWizard && (
        <WelcomeWizard
          display={true}
          closePopup={() => closeWizard()}
          userName={user?.name ?? user?.username}
          male={true}
          subtitle_1={'¡Estamos encantados de ver nuestra familia crecer! '}
          p1_1={
            'Queremos que empieces a ganar dinero desde el primer momento, pero antes hay unos conceptos básicos que deberías aprender para poder lograrlo.'
          }
          p2_1={
            'Sabemos que nuestro método no es fácil de entender si no estás familiarizado, así que déjanos explicarte algunos aspectos esenciales de ViliBets.  Sólo serán unos minutos y te serán de gran ayuda.'
          }
        />
      )}
      <section className="grid grid-cols-1 justify-between md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
        {/* Beneficios del periodo (numero + % comparativo) (Profit tracker) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Beneficios del periodo"
          number={dashboard?.profitAndLossComparativePercentage?.sumProfit || 0}
          percentageNumber={dashboard?.profitAndLossComparativePercentage?.profitCalcPercentage.toString() || '0'}
          graphData={dashboard?.profitAndLossComparativePercentage?.sumProfitEveryDay?.stake}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Beneficios totales (número + % comparativo) (Profit tracker) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Beneficios totales"
          number={dashboard?.totalProfitAndLoss?.totalSumProfit || 0}
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Ratio de baneficios vs completadas */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="€/bonos"
          number={dashboard?.ratioOfPromotionsBenefitPublished || 0}
          graphData={[]}
          sufix=" €"
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Bonos completados */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Bonos completados"
          number={dashboard?.bondsCompleted || 0}
          graphData={[]}
          sufix=""
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
      </section>
      <div className="flex flex-col-reverse">
        <section className="mt-6">
          {/* Beneficios del periodo (histograma) (Profit tracker) */}
          <Graphic
            title="Beneficios del periodo"
            series={seriesProfitAndLoss}
            options={optionsProfitAndLoss}
            type={type}
            className="bg-white"
            height="350px"
            width="100%"
          />
        </section>
        {(dashboard?.joinedUsers || []).length > 0 && (
          <section className="mt-6">
            {/* Afiliados - Créditos por usuario */}
            <Graphic
              title="Afiliados - Créditos por usuario"
              series={seriesCreditsByReferred}
              options={optionsCreditsByReferred}
              type={type}
              className="bg-white"
              height="350px"
              width="100%"
            />
          </section>
        )}
      </div>
    </>
  );
};

export default DashboardInfluencer;
