const Subscriptions = ({ children }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      {/* <aside
        id="sidebar"
        className="w-115% lg:w-auto overflow-scroll  pb-3 pr-20 child:flex child:flex-row child:items-center child:mb-5 child:pr-5 lg:child:mr-0 flex flex-row lg:flex-col child:whitespace-nowrap dark:text-accent-dark">
        <NavLink to={'/subscriptions/my-subscription'} id="my_subscription">
          <Icon className="stroke-black mr-3 text-2xl">
            <SubscriptionEuro />
          </Icon>
          Mi suscripción
        </NavLink>
        {/* <NavLink to={'/subscriptions/invoices'} id="invoices">
          <Icon className="stroke-black mr-3 text-2xl">
            <Invoice />
          </Icon>
          Mis facturas
        </NavLink> 
      </aside> */}

      <section className="lg:w-9/12 flex flex-col p-5 shadow rounded-2xl bg-white grow">{children}</section>
    </div>
  );
};

export default Subscriptions;
