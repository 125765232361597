import WelcomeBonds from '../WelcomeBonds';
import { GraphicCardNumber } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { Bond } from '@vilibets/vilibets-uikit/dist/molecules/Bond/Bond';
import { ArrowRightL, ArrowsSwitchVertical } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { useUser } from '../../../context/UserContext';
import { useEffect, useRef, useState } from 'react';
import { completeBond, deleteUserBond, getBondsProfit, getKPIBonds, getUserActiveBonds } from '../../../services/bonds';
import { Link, useSearchParams } from 'react-router-dom';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import BookmarkerSelector from '../../../utils/BookmarkerSelector';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
moment.locale('es');

const AvailableWelcomeBonds = () => {
  const [time, setTime] = useState('');
  const timerRef = useRef(null);

  const {
    state: { user },
  } = useUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const [availableBonds, setAvailableBonds] = useState();
  const [bondsPages, setBondsPages] = useState();
  const [kpiBonds, setKPIBonds] = useState();
  const [profit, setProfit] = useState(null);
  const [total, setTotal] = useState(0);
  const [selectedBookmarkers, setSelectedBookmarkers] = useState([]);
  const k_types_welcome = 'welcome';

  const getSearchParamsWithTypes = () => {
    return window.location.search ? window.location.search + '&types=' + k_types_welcome : '?types=' + k_types_welcome;
  };

  useEffect(() => {
    if (user) {
      async function fetchData() {
        if (!searchParams.get('page_number')) {
          searchParams.set('page_number', 1);
        }
        setSearchParams(searchParams);
        const kpi = await getKPIBonds(user.id, k_types_welcome);
        setKPIBonds(kpi);
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getUserActiveBonds(user.id, getSearchParamsWithTypes(), selectedBookmarkers);
        setBondsPages({
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
          per_page: response.per_page,
        });
        setTotal(response.total);
        setAvailableBonds(response.data);
        const profit = await getBondsProfit(user.id, getSearchParamsWithTypes());
        setProfit(profit);
      }
      fetchData();
    }
  }, [searchParams, user, selectedBookmarkers]);

  useEffect(() => {
    // Clear the interval when the component unmounts
    if (time.length === 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    } else {
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [time]);

  return (
    availableBonds && (
      <WelcomeBonds>
        <header className="flex flex-col lg:flex-row md:mb-5">
          <section className="flex flex-col w-full lg:w-1/2 justify-between md:mb-4 lg:mb-0">
            <header className="font-bold text-2xl text-black-7 pb-3">
              Ganancias disponibles: <span className="text-3xl text-secondary">{profit?.toFixed(2)}€</span>
            </header>
            <section>
              <section className="flex flex-row child:mb-4 child:w-full md:child:mb-0 xl:child:w-1/2 mr-4">
                <Select
                  id="orderby"
                  border={false}
                  placeholderMessage="Ordenar por fecha"
                  action={(e) => {
                    searchParams.set('orderby', e.value);
                    searchParams.set('page_number', 1);
                    if (e.value === 'max_reward') searchParams.set('orderby_direction', 'desc');
                    else searchParams.set('orderby_direction', 'asc');
                    setSearchParams(searchParams);
                  }}
                  options={[
                    { value: 'valid_until', label: 'Ordenar por fecha' },
                    { value: 'max_reward', label: 'Ordenar por ganancia' },
                    { value: 'level', label: 'Ordenar por dificultad' },
                  ]}
                  icon={<ArrowsSwitchVertical />}
                />
              </section>
              <section className="flex flex-row just child:w-full gap-4 mr-4 mt-3">
                <BookmarkerSelector
                  width="lg:!w-full"
                  action={(items) => {
                    if (items.length > 0) setSelectedBookmarkers(items);
                  }}
                  items={selectedBookmarkers}
                />
                <Input
                  type="text"
                  value={searchParams.get('search')}
                  className="child:h-[48px]"
                  placeholder="Buscar bono"
                  onChange={({ target }) => {
                    searchParams.set('search', target.value);
                    searchParams.set('page_number', 1);
                    setTime(target.value);
                  }}
                />
              </section>
            </section>
          </section>
          <section
            className="flex flex-col child:mb-4 md:child:mb-0 md:flex-row child:flex-auto md:child:mr-4 lg:child:mx-4 grow"
            id="CardNumberGraphs">
            {kpiBonds && availableBonds && (
              <>
                <GraphicCardNumber
                  numberClass="text-3xl font-bold dark:text-accent-dark"
                  title="Bonos disponibles"
                  sufix=""
                  number={total}
                  widthGraph="96"
                  heightGraph="48"
                  cardColor="white"
                />
                <GraphicCardNumber
                  numberClass="text-3xl font-bold dark:text-accent-dark"
                  title="Bonos completados último mes"
                  // percentageNumber={kpiBonds?.completedBondsPercentage}
                  sufix=""
                  number={kpiBonds?.bondsCompleted?.totalBonds}
                  // graphData={kpiBonds?.bondsCompleted?.countCompletedBondsEveryDay?.bonds}
                  widthGraph="96"
                  heightGraph="48"
                  cardColor="white"
                />
              </>
            )}
          </section>
        </header>
        <section>
          <ul className="child:mb-4 mb-10">
            {availableBonds.map((bond) => (
              <li key={bond.id}>
                <Bond
                  type="available"
                  level={bond.level}
                  profit={bond.max_reward ? `${bond.min_reward ?? '0'} - ${bond.max_reward} €` : 'Variable'}
                  title={<Link to={`/bonds/${bond.id}`}>{bond.title}</Link>}
                  discount_code={bond.code}
                  description={bond.description}
                  ends_at={moment(bond.valid_until).format('LLL')}
                  logo={bond.bookmarker.logo_url}
                  bond={bond}
                  cardColor="white bond-detail"
                  url={'/bonds/' + bond.id}
                  delete_action={async () => {
                    await deleteUserBond(user.id, bond.id);
                    const profit = await getBondsProfit(user.id, getSearchParamsWithTypes());
                    setProfit(profit);
                    const kpi = await getKPIBonds(user.id, k_types_welcome);
                    setKPIBonds(kpi);
                    const response = await getUserActiveBonds(user.id, getSearchParamsWithTypes());
                    setBondsPages({
                      current_page: response.current_page,
                      last_page: response.last_page,
                      total: response.total,
                      per_page: response.per_page,
                    });
                    setAvailableBonds(response.data);
                  }}
                  complete_action={async () => {
                    await completeBond(user.id, bond.id);
                    const profit = await getBondsProfit(user.id, getSearchParamsWithTypes());
                    setProfit(profit);
                    const kpi = await getKPIBonds(user.id, k_types_welcome);
                    setKPIBonds(kpi);
                    const response = await getUserActiveBonds(user.id, getSearchParamsWithTypes());
                    setBondsPages({
                      current_page: response.current_page,
                      last_page: response.last_page,
                      total: response.total,
                      per_page: response.per_page,
                    });
                    setAvailableBonds(response.data);
                  }}
                />
              </li>
            ))}
          </ul>
        </section>
        <section id="paginator" className="mb-20 flex flex-row justify-between">
          <section id="numbers">
            {generatePaginatorRange(bondsPages).map((element) =>
              bondsPages.current_page === element ? (
                <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                  {element}
                </button>
              ) : (
                <button
                  onClick={() => changePageNumber(element)}
                  className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                  {element}
                </button>
              )
            )}
          </section>
          {bondsPages.current_page < bondsPages.last_page && (
            <button
              onClick={() => changePageNumber(bondsPages.current_page + 1)}
              className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
              Siguiente{' '}
              <Icon className="text-xl stroke-black pl-2">
                <ArrowRightL />
              </Icon>
            </button>
          )}
        </section>
      </WelcomeBonds>
    )
  );

  function generatePaginatorRange(bondsPages) {
    const pages = [];
    if (bondsPages.total > 0) {
      if (bondsPages.per_page < bondsPages.total) {
        if (bondsPages.current_page > 1) {
          for (let i = bondsPages.current_page - 1; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        } else {
          for (let i = bondsPages.current_page; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        }
      }
    }
    return pages;
  }

  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }
};

export default AvailableWelcomeBonds;
