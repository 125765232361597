import axios from '../lib/axios';

export default async function getRates() {
  const response = await axios
    .get(`/api/v1/rates`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}
