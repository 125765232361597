import { Alert } from '@vilibets/vilibets-uikit/dist/atoms/Alert';
import { useAuth } from '../../../hooks/auth';
import Settings from '../Settings';
import { useEffect, useState } from 'react';
import getSettings from '../../../services/settings';

const Telegram = () => {
  const [botTelegramStatus, setBotTelegramStatus] = useState(null);
  const { user } = useAuth({
    middleware: 'auth',
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getSettings();
      if (response.length > 0) {
        setBotTelegramStatus(response.find((objeto) => objeto.key === 'bot_telegram_status'));
      }
    }
    fetchData();
  }, []);

  return (
    user && (
      <Settings>
        <Alert hidden={true} /> {/* Para desactivarlo, usaremos toast */}
        <h1 className="font-bold text-2xl text-black-12 mb-2 dark:text-accent-dark">Telegram</h1>
        <h2 className="mt-2 text-l text-black-12 mb-4 dark:text-accent-dark">
          <p className="mb-2">
            Bienvenido <strong>{user.name}</strong>.
          </p>
          {botTelegramStatus?.value === '0' && (
            <div className="text-warning-red mt-5 mb-5">
              <p className="text-xl">
                <strong>ATENCIÓN</strong>
              </p>
              <p>
                Actualmente nuestro bot no está operativo. Para acceder a nuestros canales de Telegram, contacta con{' '}
                <a href="https://t.me/VilibetsSoporte" target="_blank" rel="noreferrer" className="text-primary">
                  @VilibetsSoporte
                </a>
                . Disculpa las molestias.
              </p>
            </div>
          )}
          <div className={`${botTelegramStatus?.value === '0' ? 'opacity-50' : ''}`}>
            <p className="mb-1">
              Para acceder a nuestros canales de Telegram debes pulsar el siguiente botón y te añadirá directamente a
              todos los grupos.
            </p>
            <div className="w-full md:w-1/2 md:max-w-[250px] py-3 mb-3">
              <a
                href={botTelegramStatus?.value === '0' ? null : 'https://t.me/VilibetsBot?start=' + user.uuid}
                size="large"
                className={`bg-primary hover:bg-primary-8 text-white rounded-xl ${
                  botTelegramStatus?.value === '0' ? 'cursor-not-allowed' : 'active:shadow-inn3r-shadow'
                } justify-center font-bold text-large py-3 px-6`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  if (botTelegramStatus?.value === '0') {
                    e.preventDefault(); // Evita que el evento de clic siga adelante
                  }
                }}>
                Contactar con el bot
              </a>
            </div>
            <p className="mb-2">
              A tener en cuenta:
              <ul className="list-disc ml-4">
                <li>Es posible que te dé un mensaje de error y tengas que seguir las indicaciones del Bot.</li>
                <li>
                  En el caso que no te funcione y el Bot no te dé ninguna indicación, contacta con{' '}
                  <a href="https://t.me/VilibetsSoporte" target="_blank" rel="noreferrer" className="text-primary">
                    @VilibetsSoporte
                  </a>
                  .
                </li>
              </ul>
            </p>
          </div>
        </h2>
      </Settings>
    )
  );
};

export default Telegram;
