import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { LogoVilibets } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { Textarea } from '@vilibets/vilibets-uikit/dist/atoms/Textarea';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../../context/UserContext';
import getBookmarkers from '../../services/bookmarker';
import { createIncident, getUserIncidents } from '../../services/bookmarkerIncidents';

const Incidents = () => {
  const {
    state: { user },
  } = useUser();

  const [incidents, setIncidents] = useState([]);
  const [resetSelector, setResetSelector] = useState(true);
  const [fields, setField] = useState({
    title: '',
    content: '',
    date_incident: moment().format('YYYY-MM-DD HH:mm:ss'),
    bookmarker_id: '',
  });
  const [availabelBookmarkers, setAvailabelBookmarkers] = useState([]);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        const response = await getUserIncidents(user.id);
        setIncidents(response);
      }
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      async function fetchFunction() {
        const response = await getBookmarkers();
        const bookmarker = [];
        bookmarker.push({
          value: null,
          label: <LogoVilibets className="w-24 md:w-32 xl:w-36" style={{ position: 'relative', left: '-50px' }} />,
        });
        response.forEach((element) => {
          bookmarker.push({
            value: element.id,
            label: <img className="h-10" src={element.logo_url} />,
          });
        });
        setAvailabelBookmarkers(bookmarker);
      }
      fetchFunction();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setField((values) => ({ ...values, [name]: value }));
  };
  const handleSelectChange = (selectName, event) => {
    const name = selectName;
    const value = event.value;
    setField((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = { ...fields, user_id: user.id, state: 'open' };
    await toast.promise(createIncident(form), {
      pending: 'Enviando incidencia...',
      success: {
        render() {
          return 'Incidencia creada con éxito';
        },
        icon: '🟢',
      },
      error: 'Error enviando incidencia 🤯',
    });
    setIncidents(await getUserIncidents(user.id));
    setField({
      title: '',
      content: '',
      bookmarker_id: '',
      date_incident: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    document.getElementById('incident-form').reset();
    setResetSelector(!resetSelector);
  };

  return (
    incidents &&
    availabelBookmarkers && (
      <section className="flex flex-col-reverse lg:flex-row gap-5 mb-20">
        <section className="flex flex-col justify-start mb-1 bg-white rounded-lg shadow w-full lg:w-9/12 p-5">
          <h2 className="font-bold text-2xl text-black-12 mb-4 dark:text-accent-dark">Incidencias</h2>
          <table className="dark:text-accent-dark">
            <thead className="border-b border-black-4">
              <tr className="text-left child:py-5 text-sm">
                <th>Casa de apuestas</th>
                <th>Asunto</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(incidents).length === 0 && (
                <tr>
                  <td className="pt-5 text-lg font-bold" colSpan={3}>
                    No tienes incidentes
                  </td>
                </tr>
              )}
              {Object.keys(incidents).length > 0 &&
                incidents.map((incident, index) => (
                  <tr key={index} className="border-t border-black-4 child:py-5 text-sm">
                    <td>
                      {incident.bookmarker != null ? (
                        <img className="h-10" src={incident.bookmarker?.logo_url} />
                      ) : (
                        <LogoVilibets
                          className="w-24 md:w-32 xl:w-36"
                          style={{ position: 'relative', left: '-30px' }}
                        />
                      )}
                    </td>
                    <td>{incident.title}</td>
                    <td className="font-bold">{translateState(incident.state)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        <section className="w-full lg:w-3/12">
          <form id="incident-form" className="flex flex-col gap-2" onSubmit={handleSubmit}>
            <Select
              id="bookmarker_id"
              name="bookmarker_id"
              type={'small'}
              borderWidth="border-2"
              width={'w-full'}
              options={availabelBookmarkers}
              placeholderMessage="Selecciona una casa de apuestas"
              action={(e) => handleSelectChange('bookmarker_id', e)}
              resetSelector={resetSelector}
            />
            <Input
              width={'w-full'}
              id="title"
              name="title"
              type="text"
              placeholderMessage={'Asunto'}
              onChange={handleChange}
            />
            <Textarea
              maxLength={100000}
              id="contents"
              name="content"
              width={'w-full'}
              placeholderMessage={'Describa su incidencia...'}
              onChange={handleChange}
            />
            <Button
              typeButton="submit"
              disabled={fields.title === '' || fields.content === '' || fields.bookmarker_id === ''}>
              Crear incidencia
            </Button>
          </form>
        </section>
      </section>
    )
  );

  function translateState(state) {
    switch (state) {
      case 'open':
        return <span className="rounded-2xl text-warning-yellow-5 px-4 py-2 font-bold block">Abierta</span>;
      case 'closed':
        return <span className="rounded-2xl text-primary px-4 py-2 font-bold block">Cerrada</span>;
      default: // solved
        return <span className="rounded-2xl text-secondary px-4 py-2 font-bold block">Resuelta</span>;
    }
  }
};

export default Incidents;
