import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { ArrowRightL, CoinEuro, IconUser } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import getPaymentMethods from '../../services/paymentMethods';
import { getOrders } from '../../services/orders';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { toast } from 'react-toastify';

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState();
  const [ordersPages, setOrdersPages] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [time, setTime] = useState('');
  const timerRef = useRef(null);

  function changeOrder(column, sortedSide) {
    searchParams.set('orderby', column);
    searchParams.set('orderby_direction', sortedSide);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  }

  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  const handleTransactionType = (e) => {
    e.value === null || e.value === undefined ? searchParams.delete('type') : searchParams.set('type', e.value);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  const handlePaymentMethod = (e) => {
    e.value === null || e.value === undefined
      ? searchParams.delete('payment_name')
      : searchParams.set('payment_name', e.payment_name);
    searchParams.set('page_number', 1);
    setSearchParams(searchParams);
  };

  function filterByDate(key, date) {
    if (date) {
      searchParams.set(key, moment(date).format('YYYY-MM-DD'));
      setSearchParams(searchParams);
    } else {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  }

  const optionsTransactionTypes = [
    { value: null, label: 'Todos los tipos' },
    { value: 'income', label: 'Ingreso' },
    { value: 'refund', label: 'Reembolso' },
    { value: 'payment', label: 'Pago a usuario' },
  ];

  useEffect(() => {
    async function fetchData() {
      // Get payment methods
      const response_methods = await getPaymentMethods();
      const paymentMethods = [];
      paymentMethods.push({ value: null, label: 'Todos los métodos de pago', payment_name: null });
      response_methods.forEach((element) => {
        if (parseInt(element.is_active)) {
          paymentMethods.push({
            value: element.id,
            label: element.description,
            payment_name: element.payment_name,
          });
        }
      });
      setPaymentMethods(paymentMethods);

      await toast
        .promise(getOrders(window.location.search), {
          pending: 'Obteniendo datos...',
          error: 'Error en la consulta 🤯',
        })
        .then((response) => {
          setOrdersPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setOrders(response.data);
        });
    }
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    // Clear the interval when the component unmounts
    if (time.length === 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    } else {
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [time]);

  return (
    orders && (
      <>
        <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Facturación</h1>
        <section className="flex flex-col w-full lg:mb-0 lg:child:w-auto lg:flex-row justify-between pb-4">
          <section className="flex gap-2 lg:gap-0 flex-col w-full child:mb-2">
            <section className="w-full child:lg:mr-2">
              <div>
                <label className="dark:text-normal-dark">Buscar usuario</label>
                <Input
                  type="text"
                  value={searchParams.get('search')}
                  placeholder="Buscar usuario"
                  title="Rol de los usuarios"
                  width="auto"
                  className="child:h-[48px] lg:mr-2"
                  onChange={({ target }) => {
                    searchParams.set('search', target.value);
                    searchParams.set('page_number', 1);
                    setTime(target.value);
                  }}
                />
              </div>
            </section>
            <section className="flex gap-2 lg:gap-0 flex-col lg:flex-row child:lg:mr-2 lg:child:w-1/2">
              <section className="flex gap-2 lg:gap-0 flex-row child:lg:mr-2 child:w-full child:w-1/2">
                <div>
                  <label className="dark:text-normal-dark">Transacción</label>
                  <Select
                    label="Método de pago"
                    name="payment_name"
                    width="w-full"
                    borderWidth="border-2"
                    placeholderMessage="Seleccione un tipo"
                    initialValue={optionsTransactionTypes.find((option) => option.value === searchParams.get('type'))}
                    action={handleTransactionType}
                    options={optionsTransactionTypes}
                    icon={<CoinEuro />}
                  />
                </div>
                <div>
                  <label className="dark:text-normal-dark">Método de pago</label>
                  <Select
                    label="Método de pago"
                    name="payment_name"
                    width="w-full"
                    borderWidth="border-2"
                    placeholderMessage="Seleccione un método de pago"
                    initialValue={paymentMethods.find(
                      (option) => option.payment_name === searchParams.get('payment_name')
                    )}
                    action={handlePaymentMethod}
                    options={paymentMethods}
                    icon={<CoinEuro />}
                  />
                </div>
              </section>
              <section className="flex gap-2 lg:gap-0 flex-row child:lg:mr-2 lg:child:w-auto child:w-1/2">
                <div>
                  <label className="dark:text-normal-dark">Fecha desde</label>
                  <CalendarInput
                    placeholder="Todas las fechas"
                    time={false}
                    inputClass={
                      'drop-shadow-md h-12 items-center border-2 font-normal text-black-7 pl-10 cursor-pointer dark:bg-white'
                    }
                    initialDate={searchParams.get('date_from') ? new Date(searchParams.get('date_from')) : null}
                    iconClass={'top-[27%]'}
                    action={(e) => {
                      filterByDate('date_from', e);
                    }}
                  />
                </div>
                <div>
                  <label className="dark:text-normal-dark">Fecha hasta</label>
                  <CalendarInput
                    placeholder="Todas las fechas"
                    time={false}
                    inputClass={
                      'drop-shadow-md h-12 items-center border-2 font-normal text-black-7 pl-10 cursor-pointer dark:bg-white'
                    }
                    initialDate={searchParams.get('date_to') ? new Date(searchParams.get('date_to')) : null}
                    iconClass={'top-[27%]'}
                    action={(e) => {
                      filterByDate('date_to', e);
                    }}
                  />
                </div>
              </section>
            </section>
          </section>
        </section>
        <Table
          tableClass="w-full hidden lg:table"
          changeOrder={(e, c) => changeOrder(e, c)}
          rowsData={prepareTableData()}
          colsData={[
            {
              Header: 'Fecha operación',
              accessor: 'created_at',
              sortable: true,
            },
            {
              Header: 'Tipo',
              accessor: 'type',
              sortable: true,
            },
            {
              Header: 'Username',
              accessor: 'username',
              sortable: true,
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: true,
            },
            {
              Header: 'Fecha inicio',
              accessor: 'start_date',
              sortable: true,
            },
            {
              Header: 'Fecha fin',
              accessor: 'end_date',
              sortable: true,
            },
            {
              Header: 'Método de pago',
              accessor: 'payment_name',
              sortable: true,
            },
            {
              Header: 'Observaciones',
              accessor: 'comment',
            },
            {
              Header: 'Importe',
              accessor: 'total',
              align: 'right',
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
            },
          ]}
        />
        <section className="lg:hidden">
          {orders.map((element, index) => (
            <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
              <div className="flex flex-col text-center mb-3">
                <p className="text-black-12 dark:text-accent-dark font-bold">
                  {moment.utc(element.created_at).format('DD/MM/YYYY HH:mm')}
                </p>
                <p className="text-black-10 text-sm ">
                  {optionsTransactionTypes.find((option) => option.value === element.type)?.label}
                  {element.payment_description ? ' - ' + element.payment_description : ''}
                </p>
              </div>
              <div className="flex flex-col child:mb-3">
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Username / Email
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">
                    {element.type === 'payment' ? element.username_payment : element.username}
                    <br />
                    {element.type === 'payment' ? element.email_payment : element.email}
                  </p>
                </section>
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Fecha inicio - Fecha fin
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">
                    {moment.utc(element.start_date).format('DD/MM/YYYY')} -{' '}
                    {moment.utc(element.end_date).format('DD/MM/YYYY')}
                  </p>
                </section>
                <section>
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Importe
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">
                    {parseFloat(element.total).toFixed(2) + '€'}
                  </p>
                </section>
                {element.comment && (
                  <section>
                    <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                      Observaciones
                    </span>
                    <p className="text-black-12 dark:text-accent-dark font-bold">{element.comment}</p>
                  </section>
                )}
              </div>
              <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
            </div>
          ))}
        </section>
        <section id="paginator" className="mt-5 flex flex-row justify-between mb-20">
          {ordersPages && (
            <>
              <section id="numbers">
                {generatePaginatorRange(ordersPages).map((element) =>
                  ordersPages.current_page === element ? (
                    <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                      {element}
                    </button>
                  ) : (
                    <button
                      onClick={() => changePageNumber(element)}
                      className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                      {element}
                    </button>
                  )
                )}
              </section>
              {ordersPages.current_page < ordersPages.last_page && (
                <button
                  onClick={() => changePageNumber(ordersPages.current_page + 1)}
                  className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
                  Siguiente{' '}
                  <Icon className="text-xl stroke-black pl-2">
                    <ArrowRightL />
                  </Icon>
                </button>
              )}
            </>
          )}
        </section>
      </>
    )
  );

  function generateActionButtons(element) {
    return (
      <section className="flex flex-row justify-around">
        <NavLink
          to={`/users/${element.type === 'payment' ? element.user_id_payment : element.user_id}`}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2"
          title="Consultar usuario">
          <Icon className="stroke-primary text-2xl">
            <IconUser />
          </Icon>
        </NavLink>
      </section>
    );
  }

  function generatePaginatorRange(ordersPages) {
    const pages = [];
    if (ordersPages) {
      if (ordersPages.total > 0) {
        if (ordersPages.per_page < ordersPages.total) {
          if (ordersPages.current_page > 1) {
            for (
              let i = ordersPages.current_page - 1;
              i <= ordersPages.last_page && i < ordersPages.current_page + 3;
              i++
            ) {
              pages.push(i);
            }
          } else {
            for (
              let i = ordersPages.current_page;
              i <= ordersPages.last_page && i < ordersPages.current_page + 3;
              i++
            ) {
              pages.push(i);
            }
          }
        }
      }
    }
    return pages;
  }

  function prepareTableData() {
    if (orders) {
      const rowsData = [];
      orders.forEach((element) => {
        rowsData.push({
          created_at: moment.utc(element.created_at).format('DD/MM/YYYY HH:mm:ss'),
          type: optionsTransactionTypes.find((option) => option.value === element.type)?.label,
          start_date: moment.utc(element.start_date).format('DD/MM/YYYY'),
          end_date: moment.utc(element.end_date).format('DD/MM/YYYY'),
          username: element.type === 'payment' ? element.username_payment : element.username,
          email: element.type === 'payment' ? element.email_payment : element.email,
          comment: element.comment,
          payment_name: element.payment_description,
          total: parseFloat(element.total).toFixed(2) + '€',
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }
};

export default Orders;
