import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function UsoExcel() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16">
      <GuideHeader title={' Como usar el excel'} />

      <p>
        A continuación podréis visualizar un vídeo realizado por Adrià sobre el uso del Excel facilitado desde el
        apartado "Mi Cuenta".
      </p>
      <p>En el vídeo se trata como anotar distintos tipos de promociones:</p>
      <ul className="list-disc ml-3 mb-6">
        <li>Apuesta y recibe</li>
        <li>Reembolso</li>
        <li>Rollover</li>
      </ul>
      <iframe
        src="//www.youtube.com/embed/sAEmx8yc0FY"
        allowFullScreen="allowfullscreen"
        width="560"
        height="314"
        className="w-full lg:w-1/2"
      />
    </section>
  );
}
