/* eslint-disable no-unused-vars */
import { PublicHeader } from '@vilibets/vilibets-uikit/dist/organisms/PublicHeader';
import { Footer } from '@vilibets/vilibets-uikit/dist/organisms/Footer';
import { Container } from '@vilibets/vilibets-uikit/dist/atoms/Container';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import { Login } from '@vilibets/vilibets-uikit/dist/molecules/Login';
import { useAuth } from '../../hooks/auth';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useLoading } from '../../context/LoadingContext';
import { Expand } from '@theme-toggles/react';

const LoginPage = () => {
  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/',
  });
  const { dispatch } = useLoading();
  const {
    state: { loading },
  } = useLoading();
  const [error, setErrors] = useState({});
  const submitForm = async (event) => {
    dispatch({ type: 'isLoading' });
    if (event.email.includes('@')) {
      login({ email: event.email, password: event.password, setErrors });
    } else {
      login({ username: event.email, password: event.password, setErrors });
    }
  };

  const [isDarkMode, setIsDarkMode] = useState(localStorage.theme === 'dark');

  useEffect(() => {
    // toLightMode();
    if (localStorage.theme === 'dark') {
      toDarkMode();
    } else {
      toLightMode();
    }
  }, []);

  const toDarkMode = () => {
    localStorage.theme = 'dark';
    setIsDarkMode(true);
    updateTheme();
  };

  const toLightMode = () => {
    localStorage.theme = 'light';
    setIsDarkMode(false);
    updateTheme();
  };

  function updateTheme() {
    switch (localStorage.theme) {
      case 'dark':
        document.documentElement.classList.add('dark');
        document.documentElement.setAttribute('color-theme', 'dark');
        break;
      case 'light':
        document.documentElement.classList.remove('dark');
        document.documentElement.setAttribute('color-theme', 'light');
        break;
    }
  }

  return (
    <>
      {loading && <Loading />}
      <section className="bg-primary-1 h-screen">
        <PublicHeader
          header_links={[
            { name: 'Descubre cómo funciona', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/como-funciona' },
            { name: 'Bonos bienvenida', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/bonos-lista' },
            {
              name: 'Curso',
              route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/curso',
            },
            { name: 'Comunidad', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/comunidad' },
            { name: 'Tarifas', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/tarifas' },
            { name: 'Contacto', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/contacto' },
          ]}
          toggleIcon={
            <Expand
              toggled={isDarkMode}
              onToggle={() => {
                if (localStorage.theme === 'dark') {
                  toLightMode();
                } else {
                  toDarkMode();
                }
              }}
            />
          }
          route_access={'/login'}
          route_try_now={'/register'}
          logo_url={process.env.REACT_APP_PUBLIC_PAGE_URL}
        />
        <main className="bg-primary-1 pt-40 flex">
          <Container>
            <div className="m-auto h-full w-[347px] md:w-[400px] lg:w-[400px] justify-center items-center">
              <Login
                submitForm={(e) => {
                  submitForm(e);
                }}
                hasErrorInput={Object.keys(error).length > 0}
                textUser={'Correo electrónico o usuario'}
                placeholderUser={'Ingresa tu correo electrónico o usuario'}
                textError={error.status ? t(`${error.status}.${error.message}`) : error}
                textButton="Iniciar sesión"
                routeRememberPassword="/forgot-password"
                routeRegister="/register"
              />
            </div>
          </Container>
        </main>
        <footer className="fixed bottom-0 w-screen mt-11 bg-white">
          <Footer
            type="simple"
            legal_links={[
              { name: 'Aviso legal', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/aviso-legal' },
              { name: 'Política de cookies', route: process.env.REACT_APP_PUBLIC_PAGE_URL + '/politica-de-cookies' },
            ]}
          />
        </footer>
      </section>
    </>
  );
};

export default LoginPage;
