import { useEffect, useRef, useState } from 'react';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Moment from 'moment';
import getBonds, { deleteBond, sendBondToTelegram, postBond } from '../../services/bonds';
import { createOrEditBonds } from './constants/createOrEditBonds';
import getBookmarkers from '../../services/bookmarker';
import { BondCard } from '@vilibets/vilibets-uikit/dist/molecules/BondCard/BondCard';

import { ArrowLeftL, ArrowRightL, Copy, Email, File, Trash, BondAlt } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import IconTelegram from '@vilibets/vilibets-uikit/dist/atoms/Icons/IconTelegram';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import BookmarkerSelector from '../../utils/BookmarkerSelector';
import EditText from '@vilibets/vilibets-uikit/dist/atoms/Icons/EditText';
import { Loading } from '@vilibets/vilibets-uikit/dist/atoms/Loading';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';

const ManageBonds = () => {
  const { typeRepeating } = createOrEditBonds();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBookmarkers, setSelectedBookmarkers] = useState([]);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [bondsPages, setBondsPages] = useState();
  const [bonds, setBonds] = useState(null);
  const [time, setTime] = useState('');
  const [types, setTypes] = useState(null);
  const timerRef = useRef(null);

  // Change table row order
  function changeOrder(column, sortedSide) {
    searchParams.set('page_number', 1);
    searchParams.set('orderby', `bonds.${column}`);
    searchParams.set('orderby_direction', sortedSide);
    setSearchParams(searchParams);
  }

  // Change table row order
  function changePageNumber(page) {
    searchParams.set('page_number', page);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    async function fetchFunction() {
      const response = await getBookmarkers();
      const bookmarker = [];
      response.forEach((element) => {
        bookmarker.push({
          value: element.id,
          label: <img className="h-10 dark:bg-[#f4f7fe]" src={element.logo_url} />,
        });
      });
    }
    fetchFunction();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const params = types && types.value ? window.location.search + '&types=' + types.value : window.location.search;
      const response = await getBonds(params, selectedBookmarkers);
      setBondsPages({
        current_page: response.current_page,
        last_page: response.last_page,
        total: response.total,
        per_page: response.per_page,
      });
      setBonds(response.data);
    }
    fetchData();
  }, [searchParams, selectedBookmarkers, types]);

  useEffect(() => {
    async function fetchData() {
      const params = types && types.value ? window.location.search + '&types=' + types.value : window.location.search;
      const response = await getBonds(params);
      setBondsPages({
        current_page: response.current_page,
        last_page: response.last_page,
        total: response.total,
      });
      setBonds(response.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Clear the interval when the component unmounts
    if (time.length === 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    } else {
      timerRef.current = setTimeout(() => setSearchParams(searchParams), 500);
    }

    return () => clearTimeout(timerRef.current);
  }, [time]);

  const generateActionButtons = (element) => {
    const color_icon_telegram = element.send_it_telegram ? 'stroke-white' : 'stroke-black-9';
    const color_button_telegram = element.send_it_telegram ? '!bg-primary-6 hover:!bg-primary-5' : 'bg-transparent';
    const color_icon_invitation = element.need_invitation ? 'stroke-primary' : 'stroke-black-9';
    return (
      <div className="flex flex-row justify-around items-center">
        <button
          onClick={async () => {
            const b = {
              bookmarker_id: element.bookmarker_id,
              writer_id: element.writer_id,
              title: element.title,
              start_at: element.start_at,
              valid_until: element.valid_until,
              type: element.type,
              type_of_bookmarker: element.type_of_bookmarker,
              level: element.level,
              type_of_bond: element.type_of_bond,
              code: element.code,
              url: element.url,
              max_bet: element.max_bet,
              min_bet: element.min_bet,
              min_reward: element.min_reward,
              max_reward: element.max_reward,
              reward: element.reward,
              estimated_banking: element.estimated_banking,
              max_deposit: element.max_deposit,
              condition: element.condition,
              comment: element.comment,
              description: element.description,
              content: element.content,
              match: element.match,
              valid_market: element.valid_market,
              slug: element.slug,
              is_active: element.is_active,
              golden_goal: element.golden_goal,
              send_it_telegram: false,
              need_invitation: element.need_invitation,
              outdated: element.outdated,
              video_url: element.video_url,
              type_repeats_bond: element.type_repeats_bond,
              finish_repeats_bond: element.finish_repeats_bond,
              last_execution: element.last_execution,
              bond_execution_id: element.bond_execution_id,
              risk: element.risk,
              calculators: element.calculators.map((calc) => calc.id),
            };
            await postBond(Object.fromEntries(Object.entries(b).filter(([_, v]) => v != null)));
            const params =
              types && types.value ? window.location.search + '&types=' + types.value : window.location.search;
            const response = await getBonds(params, selectedBookmarkers);
            setBondsPages({
              current_page: response.current_page,
              last_page: response.last_page,
              total: response.total,
              per_page: response.per_page,
            });
            setBonds(response.data);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <Copy />
          </Icon>
        </button>
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
        <button
          onClick={() => {
            navigate(`/manage-bonds/${element.id}/edit`);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
        <IconButton
          onClick={async () => {
            await sendBondToTelegram(element.id);
            toast.success('Enviado a Telegram');
            const params =
              types && types.value ? window.location.search + '&types=' + types.value : window.location.search;
            const response = await getBonds(params, selectedBookmarkers);
            setBondsPages({
              current_page: response.current_page,
              last_page: response.last_page,
              total: response.total,
              per_page: response.per_page,
            });
            setBonds(response.data);
          }}
          className={`!mr-2 ${color_button_telegram}`}>
          <Icon className={`text-2xl ${color_icon_telegram}`}>
            <IconTelegram />
          </Icon>
        </IconButton>
        <Icon className={`text-2xl mr-1 ${color_icon_invitation}`}>
          <Email />
        </Icon>
      </div>
    );
  };

  const translateTypeOfBond = (type) => {
    const types = {
      welcome: 'Bienvenidas',
      recursive: 'Recurrentes',
    };
    return types[type] || 'Otro';
  };

  const translateTypeOfBookmarker = (type) => {
    const types = {
      casino: 'Casino',
      sports: 'Sports',
    };
    return types[type] || 'Otro';
  };

  const optionsType = [
    { value: '', label: 'Todos los bonos' },
    { value: 'top_bonds,recursive,free_spins', label: 'Recurrentes' },
    { value: 'welcome', label: 'Bienvenidas' },
  ];

  function prepareTableData() {
    if (bonds) {
      const rowsData = [];
      bonds?.forEach((element) => {
        rowsData.push({
          id: <div className="flex flex-col text-center">{element.id}</div>,
          title: <div className="flex flex-col leading-5">{element.title}</div>,
          bookmarkers: <img className="max-h-[48px] mx-auto dark:bg-[#f4f7fe]" src={element?.bookmarker?.logo_url} />,
          writer: <div className="flex flex-col leading-5">{`${element?.creator_username}`}</div>,
          type_of_bookmarker: (
            <div className="flex flex-col leading-5">
              {translateTypeOfBond(element.type) + ' / ' + translateTypeOfBookmarker(element.type_of_bookmarker)}
            </div>
          ),
          start_at: (
            <div className="flex flex-col leading-5">{Moment(element.start_at).format('DD/MM/YYYY HH:mm')}</div>
          ),
          valid_until: (
            <div className="flex flex-col leading-5">{Moment(element.valid_until).format('DD/MM/YYYY HH:mm')}</div>
          ),
          finish_repeats_bond: (
            <div className="flex flex-col leading-5">
              {element.type_repeats_bond && element.finish_repeats_bond
                ? Moment(element.finish_repeats_bond).format('DD/MM/YYYY HH:mm')
                : ''}
            </div>
          ),
          comment: <div className="flex flex-col" dangerouslySetInnerHTML={{ __html: element.comment }} />,
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  function generatePaginatorRange(bondsPages) {
    const pages = [];
    if (bondsPages.total > 0) {
      if (bondsPages.per_page < bondsPages.total) {
        if (bondsPages.current_page > 1) {
          for (let i = bondsPages.current_page - 1; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        } else {
          for (let i = bondsPages.current_page; i <= bondsPages.last_page && i < bondsPages.current_page + 3; i++) {
            pages.push(i);
          }
        }
      }
    }
    return pages;
  }
  return bonds ? (
    <article id="bonds" className="mb-20">
      <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark mb-5">Gestión de bonos</h1>
      <section
        id="filters"
        className="flex flex-col w-full intermedium:mb-0 intermedium:child:w-auto intermedium:flex-row justify-between pb-4 child:mb-3">
        <section className="flex flex-col intermedium:flex-row gap-2 items-center">
          <BookmarkerSelector
            action={(items) => {
              if (items.length > 0) setSelectedBookmarkers(items);
            }}
            items={selectedBookmarkers}
          />
          <Select
            id="type"
            border={false}
            width="w-auto lg:w-52"
            placeholderMessage="Tipo de bono"
            initialValue={optionsType[0]}
            action={(e) => setTypes(e)}
            options={optionsType}
            icon={<BondAlt />}
          />
          <Input
            type="text"
            value={searchParams.get('search')}
            placeholder="Buscar bonos"
            className="child:h-[48px]"
            onChange={({ target }) => {
              searchParams.set('search', target.value);
              searchParams.set('page_number', 1);
              setTime(target.value);
            }}
          />
        </section>
        <section className="flex flex-row child:w-1/2 intermedium:child:w-auto">
          <Button
            type="secondary"
            size="medium"
            action={() => navigate('/manage-bonds/add')}
            icon={
              <Icon className="stroke-white text-2xl">
                <File />
              </Icon>
            }>
            Añadir Bono
          </Button>
        </section>
      </section>
      <Table
        tableClass="w-full hidden lg:table"
        changeOrder={(e, c) => changeOrder(e, c)}
        rowsData={prepareTableData()}
        colsData={[
          {
            Header: 'Id',
            accessor: 'id',
            sortable: true,
          },
          {
            Header: 'Titulo',
            accessor: 'title',
            sortable: true,
          },
          {
            Header: 'Casa de apuestas',
            accessor: 'bookmarkers',
            sortable: false,
          },
          {
            Header: 'Usuario creador',
            accessor: 'writer',
            sortable: false,
          },
          {
            Header: 'Tipo',
            accessor: 'type_of_bookmarker',
            sortable: false,
          },
          {
            Header: 'Empieza',
            accessor: 'start_at',
            sortable: true,
          },
          {
            Header: 'Valido',
            accessor: 'valid_until',
            sortable: true,
          },
          {
            Header: 'Repetir hasta',
            accessor: 'finish_repeats_bond',
            sortable: true,
          },
          {
            Header: 'Comentario',
            accessor: 'comment',
            sortable: true,
          },
          {
            Header: 'Acciones',
            accessor: 'actions',
          },
        ]}
      />
      <section className="lg:hidden">
        {bonds.map((element, index) => {
          return (
            <BondCard
              key={index}
              className="mb-4"
              start_at={Moment(element.start_at).format('DD/MM/YYYY HH:mm')}
              valid_until={Moment(element.valid_until).format('DD/MM/YYYY HH:mm')}
              type_repeats_bond={
                element.type_repeats_bond
                  ? typeRepeating.find((option) => option.value === element.type_repeats_bond).label
                  : ''
              }
              finish_repeats_bond={
                element.type_repeats_bond && element.finish_repeats_bond
                  ? Moment(element.finish_repeats_bond).format('DD/MM/YYYY HH:mm')
                  : ''
              }
              event={element.title}
              minimum_reward={element.min_reward}
              max_reward={element.max_reward}
              type_bond={
                translateTypeOfBond(element.type) + ' / ' + translateTypeOfBookmarker(element.type_of_bookmarker)
              }
              bookmarker={<img className="max-h-[48px] mx-auto dark:bg-[#f4f7fe]" src={element.bookmarker.logo_url} />}
              actions={generateActionButtons(element)}
              comment={element.comment}
            />
          );
        })}
      </section>
      <section id="paginator" className="mt-5 flex flex-row justify-between">
        <section id="numbers" className="flex">
          {bondsPages.current_page > 1 && (
            <button
              onClick={() => changePageNumber(bondsPages.current_page - 1)}
              className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center mr-3 dark:text-accent-dark">
              <Icon className="text-xl stroke-black pr-2">
                <ArrowLeftL />
              </Icon>{' '}
              Anterior
            </button>
          )}
          {generatePaginatorRange(bondsPages).map((element) =>
            bondsPages.current_page === element ? (
              <button className="px-5 py-3 bg-primary rounded-xl text-white font-bold shadow-inn3r-shadow dark:shadow-none mr-2">
                {element}
              </button>
            ) : (
              <button
                onClick={() => changePageNumber(element)}
                className="px-5 py-3 bg-white rounded-xl text-black-10 font-bold shadow mr-2">
                {element}
              </button>
            )
          )}
        </section>
        {bondsPages.current_page < bondsPages.last_page && (
          <button
            onClick={() => changePageNumber(bondsPages.current_page + 1)}
            className="px-5 py-3 bg-white rounded-xl text-black-12 font-bold shadow flex flex-row items-center dark:text-accent-dark">
            Siguiente{' '}
            <Icon className="text-xl stroke-black pl-2">
              <ArrowRightL />
            </Icon>
          </button>
        )}
      </section>
      <PopupDelete
        display={displayDeletePopup}
        item={deleteItem}
        closePopup={() => {
          setDisplayDeletePopup(!displayDeletePopup);
        }}
        title={deleteItem.title}
        action_cancel={() => {
          setDisplayDeletePopup(false);
        }}
        action_delete={async () => {
          await deleteBond(deleteItem.id);
          setDisplayDeletePopup(false);
          const params =
            types && types.value ? window.location.search + '&types=' + types.value : window.location.search;
          const response = await getBonds(params, selectedBookmarkers);
          setBondsPages({
            current_page: response.current_page,
            last_page: response.last_page,
            total: response.total,
            per_page: response.per_page,
          });
          setBonds(response.data);
        }}
      />
    </article>
  ) : (
    <Loading />
  );
};

export default ManageBonds;
