import FeaturedGuidesText from '../../components/FeaturedGuidesText';
import GuideHeader from '../../components/GuideHeader/GuideHeader';

export default function CasasDeIntercambio() {
  return (
    <section className="guide-article dark:text-accent-dark pb-16 dark:text-accent-dark">
      <GuideHeader title={'Casas de Intercambio'} />
      <p>
        Una <strong>casa de intercambio</strong> es un portal online donde los apostantes pueden apostar{' '}
        <strong>
          <span className="estilo-contra">en contra</span>
        </strong>{' '}
        de otros apostantes. Aquí es donde apostaremos a que algo <span>"no gana"</span>. Este portal es el{' '}
        <strong>único lugar</strong> donde vamos a poder apostar&nbsp;
        <span className="estilo-contra">en contra</span>.
      </p>
      <p>
        Posiblemente, <strong>esta sea la parte más esencial del Matched Betting</strong>, ya que sin la existencia de
        estos portales el Matched Betting carecería de sus fundamentos.
      </p>
      <p>
        <strong>Por ejemplo,</strong> si tenemos la impresión que el Osasuna <strong>no perderá</strong> este fin de
        semana podemos entrar en Betfair Exchange y apostar <span className="estilo-contra">en contra</span> del equipo
        al que se enfrente. Realizamos la apuesta&nbsp;<span className="estilo-contra">en contra</span>
        &nbsp;del Betis.
      </p>
      fotoooo
      <p>
        Como vemos en el ejemplo, si Osasuna <strong>GANA o EMPATA</strong> vamos a ganar nuestra apuesta&nbsp;
        <span className="estilo-contra">en contra</span>. Si el Betis <strong>GANA</strong>, vamos a perder nuestra
        apuesta&nbsp;<span className="estilo-contra">en contra</span>.
      </p>
      <h2>
        <strong>¿Por qué son importantes para nosotros?</strong>
      </h2>
      <p>
        Nosotros vamos a utilizar este portal cada vez que hagamos una apuesta. <strong>Por ejemplo,</strong> si
        realizamos una apuesta <span className="estilo-favor">a favor</span>&nbsp;del Madrid, entonces apostaremos la
        cantidad que nos diga la calculadora&nbsp;
        <strong>
          <span className="estilo-contra">en contra</span>&nbsp;
        </strong>
        del Madrid. De esta manera, nos aseguramos que pase lo que pase, vamos a obtener un beneficio.
      </p>
      <p>
        En España solo contamos con la casa de intercambio <strong>Betfair Exchange,</strong> una casa de Intercambio
        fundada en el año 2000 y con una impecable reputación que avala su trayectoria.
      </p>
      <p>
        Seguramente, te preguntarás como ganan dinero las casas de intercambio, ¿no? La respuesta es bastante evidente,
        como cualquier intermediario <strong>cobran una comisión.</strong> En el caso de Betfair Exchange es del{' '}
        <strong>2%</strong>, no tienes por qué preocuparte porque nuestras calculadoras{' '}
        <span>ya incorporan esa comisión en el cálculo</span>.
      </p>
      <h2>Conceptos importantes de las casas de intercambio</h2>
      <FeaturedGuidesText margin={'mb-5'} label={'Riesgo'}>
        El&nbsp;<strong>riesgo</strong>&nbsp;es un concepto relacionado con las apuestas&nbsp;en contra&nbsp;realizadas
        en Betfair Exchange. Se podría resumir como la&nbsp;
        <strong>cantidad que un usuario está dispuesto a arriesgar para ganar su apuesta.</strong>
        <br />
        El riesgo se calcula como: (Cantidad apostada * Cuota – Cantidad apostada).
        <br />
        Al colocar una apuesta,&nbsp;
        <strong>Betfair te retiene una cantidad de saldo igual al riesgo de tu apuesta</strong>, que se usará para pagar
        a otro usuario en caso de que tu apuesta sea perdedora. Por eso,
        <strong>
          {' '}
          es imprescindible disponer, como mínimo, de la cantidad de riesgo como en forma de saldo real en Betfair antes
          de colocar tus apuestas.
        </strong>{' '}
        Si tus apuestas resultan ganadoras, Betfair te devuelve la cantidad retenida en concepto de riesgo más las
        ganancias derivadas de tus apuestas.
        <br />
        Por último, como el riesgo va asociado a la cuota,&nbsp;<strong>a mayor cuota mayor riesgo.</strong>
        <br />
      </FeaturedGuidesText>
      <FeaturedGuidesText margin={'mb-5'} label={'Liquidez'}>
        La&nbsp;<strong>liquidez</strong>&nbsp;es básicamente la&nbsp;
        <strong>cantidad de dinero disponible a una determinada cuota.</strong>&nbsp;Puedes saber la liquidez de una
        cuota fijándote en&nbsp;
        <strong>el número en euros que sale debajo de ella en Betfair Exchange.</strong>&nbsp;La liquidez es un
        concepto&nbsp;muy importante.
        <br />
        Imagina que tienes que cubrir una apuesta gratuita en Betfair Exchange y para ello&nbsp;
        <strong>tienes que apostar 14 €&nbsp;</strong>
        <strong>en contra</strong>.&nbsp;Si la&nbsp;<strong>liquidez disponible a esa cuota es de 10 €</strong>
        &nbsp;<strong>te</strong>&nbsp;<strong>van a quedar 4 € pendientes de igualar</strong>. Puede que durante el
        transcurso del día esos 4 € acaben igualándose o, por el contrario, que debido a un movimiento de cuotas no se
        igualen. Entonces,&nbsp;el beneficio de tu apuesta gratuita no van a ser los 14 € que te mostró la calculadora
        inicialmente, sino 10 €, que ha sido la parte igualada de tu apuesta de 14 €.
        <br />
      </FeaturedGuidesText>
      <FeaturedGuidesText label={'Apuesta igualada'}>
        <strong>
          Una apuesta ha sido igualada cuando existe alguien al otro lado de la pantalla que nos la ha comprado.
        </strong>
        &nbsp;Para saber si una apuesta ha sido igualada puedes dirigirte al apartado de 'Mis apuestas' &lt;&lt;
        'Igualadas' dentro de la pestaña en el Exchange de Betfair.
        <br />
        En caso de que tu apuesta no se iguale, siempre&nbsp;
        <strong>tienes la opción de dejarla como pendiente pulsando el botón guardar</strong>&nbsp;o&nbsp;
        <strong>realizar tu apuesta a las cuotas superiores</strong>&nbsp;con las cantidades que te muestre la
        calculadora después de&nbsp;<strong>actualizar las cuotas.</strong>
        <br />
      </FeaturedGuidesText>
    </section>
  );
}
