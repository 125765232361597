import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { Clock } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { useEffect, useState } from 'react';
import getGuides from '../../services/guide';

const Guide = () => {
  const [guides, setGuides] = useState(null);

  const fetchGuides = async () => {
    const g = await getGuides(false);
    setGuides(g.response);
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  return (
    <section className="pb-16">
      <h1 className="pl-2.5 border-l-4 border-primary font-bold text-[40px] mb-10 dark:text-accent-dark">Guías</h1>
      <ul className="flex flex-row flex-wrap">
        {guides?.map((guide) => (
          <a
            href={`/guide/${guide.slug}`}
            key={guide.slug}
            className="w-full md:w-[calc(50%-1.75rem)] lg:w-[calc(25%-1.75rem)] pb-5">
            <li
              key={guide.id}
              className="relative flex flex-col justify-start items-center  md:mr-6 shadow bg-white p-4 mb-5 rounded-xl cursor-pointer h-full">
              <img className="w-full" src={guide.image_url} alt={guide.title} />
              <section className="flex flex-row items-center justify-start w-full child:mr-2 my-4 dark:text-accent-dark">
                <Icon>
                  <Clock />
                </Icon>
                <p>
                  Tiempo de lectura: <strong>{guide.read_time}</strong>
                </p>
              </section>
              <hr className="w-full" />
              <section id="info" className="p-1 w-full">
                <h2 className="my-3 !text-xl !leading-8 font-bold dark:text-accent-dark">{guide.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: guide.description }} />
              </section>
              {translateLevel(guide.level)}
            </li>
          </a>
        ))}
      </ul>
    </section>
  );
};

function translateLevel(level) {
  switch (level) {
    case 'easy':
      return (
        <span className="absolute top-3 -left-2 bg-secondary text-white rounded-full py-1 px-4 capitalize font-bold">
          Principiante
        </span>
      );
    case 'medium':
      return (
        <span className="absolute top-3 -left-2 bg-warning-orange-5 text-white rounded-full py-1 px-4 capitalize font-bold">
          Intermedio
        </span>
      );
    case 'hard':
      return (
        <span className="absolute top-3 -left-2 bg-primary text-white rounded-full py-1 px-4 capitalize font-bold">
          Avanzado
        </span>
      );
    default:
      return (
        <span className="absolute top-3 -left-2 bg-secondary text-white rounded-full py-1 px-4 capitalize font-bold">
          Principiante
        </span>
      );
  }
}

export default Guide;
