import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { EditText, Trash, Trophy } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Table } from '@vilibets/vilibets-uikit/dist/atoms/Table/Table';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PopupDelete from '../../components/PopupDelete/PopupDelete';
import getBookmarkers, { deleteBookmarker } from '../../services/bookmarker';
import CreditBookmarkerPopup from './CreditBookmarkerPopup';

const Bookmarkers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bookmarkers, setBookmarkers] = useState([]);
  const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [displayCreditPopup, setDisplayCreditPopup] = useState(false);
  const [editableRowData, setEditableRowData] = useState(null);

  function changeOrder(column, sortedSide) {
    setSearchParams({ orderby: column, orderby_direction: sortedSide });
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getBookmarkers(window.location.search);
      setBookmarkers(response);
    }
    fetchData();
  }, [searchParams]);

  return (
    bookmarkers && (
      <>
        <section className="flex flex-col lg:flex-row justify-between mb-7">
          <h1 className="font-bold text-2xl text-black-12 dark:text-accent-dark">Casas de apuestas</h1>
          <Button
            type="secondary"
            size="small"
            action={() => setDisplayCreditPopup(true)}
            icon={
              <Icon className="stroke-white text-2xl">
                <Trophy />
              </Icon>
            }>
            Añadir casas de apuestas
          </Button>
        </section>
        <Table
          tableClass="w-full hidden lg:table mb-20"
          changeOrder={(e, c) => changeOrder(e, c)}
          rowsData={prepareTableData()}
          colsData={[
            {
              Header: 'Casa de apuestas',
              accessor: 'logo_url',
              sortable: true,
            },
            {
              Header: 'Nombre',
              accessor: 'title',
              sortable: true,
            },
            {
              Header: 'Url',
              accessor: 'url',
              sortable: true,
            },
            {
              Header: 'Acciones',
              accessor: 'actions',
            },
          ]}
        />
        <section className="lg:hidden">
          {bookmarkers.map((element, index) => (
            <div className="flex flex-col justify-between mb-5 bg-white shadow rounded-xl p-4" key={index}>
              <div className="flex flex-col text-center mb-3">
                <img className="w-[150px] mx-auto" src={`${element.logo_url}`} />
              </div>
              <div className="flex flex-row child:mb-3">
                <section className="w-1/2">
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded">
                    Nombre
                  </span>
                  <p className="text-black-12 dark:text-accent-dark font-bold">{element.title}</p>
                </section>
                <section className="w-1/2 flex flex-col">
                  <span className="text-black-10 dark:text-black-12 text-sm bg-black-3 px-1.5 py-0 rounded w-fit">
                    Url
                  </span>
                  <a target="_blank" className="text-primary break-words" href={element.url} rel="noreferrer">
                    {element.url}
                  </a>
                </section>
              </div>
              <div className="flex flex-row mx-auto">{generateActionButtons(element)}</div>
            </div>
          ))}
        </section>
        <CreditBookmarkerPopup
          display={displayCreditPopup}
          closePopup={async () => {
            setDisplayCreditPopup(!displayCreditPopup);
            setEditableRowData(null);
            const response = await getBookmarkers(window.location.search);
            setBookmarkers(response);
          }}
          data={editableRowData}
        />
        <PopupDelete
          display={displayDeletePopup}
          item={deleteItem}
          closePopup={() => {
            setDisplayDeletePopup(!displayDeletePopup);
          }}
          title={deleteItem.title}
          action_cancel={() => {
            setDisplayDeletePopup(false);
          }}
          action_delete={async () => {
            await deleteBookmarker(deleteItem.id);
            setDisplayDeletePopup(false);
            const response = await getBookmarkers(window.location.search);
            setBookmarkers(response);
          }}
        />
      </>
    )
  );

  function prepareTableData() {
    if (bookmarkers) {
      const rowsData = [];
      bookmarkers.forEach((element) => {
        rowsData.push({
          logo_url: <img className="w-[90px]" src={`${element.logo_url}`} />,
          title: element.title,
          url: (
            <a target="_blank" className="text-primary" href={element.url} rel="noreferrer">
              {element.url}
            </a>
          ),
          actions: generateActionButtons(element),
        });
      });
      return rowsData;
    }
  }

  function generateActionButtons(element) {
    return (
      <section className="flex flex-row justify-around">
        <button
          onClick={async () => {
            setDeleteItem(element);
            setDisplayDeletePopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-warning-red text-2xl">
            <Trash stroke={'#F4304A'} />
          </Icon>
        </button>
        <button
          onClick={() => {
            setEditableRowData(element);
            setDisplayCreditPopup(true);
          }}
          className="text-secondary-blue hover:text-secondary-blue-dark mr-2">
          <Icon className="stroke-black text-2xl">
            <EditText />
          </Icon>
        </button>
      </section>
    );
  }
};

export default Bookmarkers;
