/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import getDashboard from '../../services/dashboard';
import DashboardWorker from './DashboardWorker';
import DashboardUser from './DashboardUser';
import DashboardInfluencer from './DashboardInfluencer';
import { AlertError } from '@vilibets/vilibets-uikit/dist/atoms/Alert/Alert';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import DashboardAdmin from './DashboardAdmin';
import { dashboardOptions } from './constants/dashboardOptions';
import { toast } from 'react-toastify';
import moment from 'moment';

const Dashboard = () => {
  const {
    state: { user },
  } = useUser();

  const [dashboard, setDashboard] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    sessionStorage?.dateFrom ? sessionStorage?.dateFrom : moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(sessionStorage?.dateTo ? sessionStorage?.dateTo : moment().format('YYYY-MM-DD'));
  const [role, setRole] = useState(user?.role);
  const [hiddenError, setHiddenError] = useState(true);
  const { optionsSelectRole } = dashboardOptions(user.role);
  const [loading, setLoading] = useState(false);

  function getDataDashboard(filter_date, date_from, date_to, role) {
    return getDashboard(user.id, filter_date, date_from, date_to, role)
      .then(({ response }) => {
        response.date_from = date_from;
        response.date_to = date_to;
        response.label_date = moment(date_from).format('DD/MM/YYYY') + '-' + moment(date_to).format('DD/MM/YYYY');
        setDashboard(response);
      })
      .catch(() => {
        setTimeout(() => {
          setHiddenError(false);
          setTimeout(() => {
            setHiddenError(true);
          }, 3000);
        }, 1000);
      });
  }

  async function changeFilterDate(filter_date, date_from, date_to, role) {
    if (user && !loading) {
      try {
        setLoading(true);
        await toast.promise(getDataDashboard(filter_date, date_from, date_to, role), {
          pending: 'Cargando datos...',
          error: 'Error en la obtención de datos 🤯',
        });
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (dateFrom && dateTo) {
      // Solo se puede consultar un año como máximo
      if (moment(dateTo).subtract(1, 'years') > moment(dateFrom)) {
        toast.info('El periodo máximo a consultar es de un año.');
        return;
      }

      // El primer parámetro se pasa siempre a null porque ahora se filtra siempre por fechas
      changeFilterDate('', dateFrom, dateTo, role);
    }
  }, [dateFrom, dateTo, role]);

  return (
    <>
      <header className="mb-6">
        <AlertError
          hidden={hiddenError}
          title="Ha surgido un error, intentelo otra vez"
          className="transition ease-in-out delay-200 duration-200"
        />
        <div className="flex flex-col-reverse md:justify-between md:flex-row gap-2">
          <section className="flex flex-col">
            <label className="dark:text-normal-dark">Periodo (desde-hasta)</label>
            <div className="flex gap-3">
              <CalendarInput
                time={false}
                placeholder="Fecha desde"
                initialDate={
                  sessionStorage?.dateFrom
                    ? new Date(sessionStorage?.dateFrom)
                    : new Date(moment().subtract(7, 'days').format('YYYY-MM-DD'))
                }
                inputClass={
                  'mt-1 drop-shadow-md h-12 items-center border-none font-normal text-black-7 pl-10 cursor-pointer dark:bg-white'
                }
                iconClass={'top-[30%]'}
                minDate={new Date(moment('2020-12-01'))}
                maxDate={new Date(dateTo)}
                disabled={loading}
                action={(e) => {
                  sessionStorage.dateFrom = moment(e).format('YYYY-MM-DD');
                  setDateFrom(moment(e).format('YYYY-MM-DD'));
                }}
              />
              <CalendarInput
                time={false}
                placeholder="Fecha hasta"
                initialDate={
                  sessionStorage?.dateTo ? new Date(sessionStorage?.dateTo) : new Date(moment().format('YYYY-MM-DD'))
                }
                inputClass={
                  'mt-1 drop-shadow-md h-12 items-center border-none font-normal text-black-7 pl-10 cursor-pointer dark:bg-white'
                }
                iconClass={'top-[30%]'}
                minDate={new Date(dateFrom)}
                maxDate={new Date(moment().format('YYYY-MM-DD'))}
                disabled={loading}
                action={(e) => {
                  sessionStorage.dateTo = moment(e).format('YYYY-MM-DD');
                  setDateTo(moment(e).format('YYYY-MM-DD'));
                }}
              />
            </div>
          </section>
          {user && (user.role === 'admin' || user.role === 'worker' || user.role === 'influencer') && (
            <div className="mb-2 md:mb-0">
              <label className="hidden dark:text-normal-dark md:block">&nbsp;</label>
              <Select
                options={optionsSelectRole}
                action={({ value }) => {
                  setRole(value);
                  localStorage.role = value;
                }}
                disabled={loading}
                border={false}
                hasShadow={true}
                typeShadow="drop-shadow-md"
                className="mt-1"
                initialValue={
                  optionsSelectRole.find((option) => option.value === localStorage.role)
                    ? optionsSelectRole.find((option) => option.value === localStorage.role)
                    : optionsSelectRole[0]
                }
              />
            </div>
          )}
        </div>
      </header>
      {user && (
        <>
          {/* Cliente */}
          {user.role === 'user' && <DashboardUser dashboard={dashboard} />}
          {/* Influencer */}
          {user.role === 'influencer' && role === 'influencer' ? (
            <DashboardInfluencer dashboard={dashboard} />
          ) : user.role === 'influencer' && role === 'user' ? (
            <DashboardUser dashboard={dashboard} />
          ) : null}
          {/* Worker */}
          {user.role === 'worker' && role === 'worker' ? (
            <DashboardWorker dashboard={dashboard} />
          ) : user.role === 'worker' && role === 'user' ? (
            <DashboardUser dashboard={dashboard} />
          ) : null}
          {/* Admin */}
          {user.role === 'admin' && role === 'admin' ? (
            <DashboardAdmin dashboard={dashboard} />
          ) : user.role === 'admin' && role === 'user' ? (
            <DashboardUser dashboard={dashboard} />
          ) : null}
        </>
      )}
    </>
  );
};

export default Dashboard;
