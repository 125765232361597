import { useNavigate } from 'react-router-dom';

export const admin = (dashboard) => {
  const navigate = useNavigate();

  // 1
  const type_bar = 'bar';
  const series_incidents_betting_house = [
    {
      name: 'Bonos creados',
      data: dashboard?.bonds_workers_writter_bar_graph?.map((incident) => incident?.count_bonds_writer) || [],
    },
  ];
  const options_incidents_betting_house = {
    chart: {
      height: 350,
      type: 'bar',
      events: {},
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dashboard?.bonds_workers_writter_bar_graph?.map((incident) => incident?.fullname) || [],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  // 2,3,4
  const series = [
    {
      name: 'Suscripciones activas',
      data: dashboard?.gained_expired_active_users?.active_users?.users || [],
    },
    {
      name: 'Suscripciones que caducan',
      data: dashboard?.gained_expired_active_users?.expired_users?.users || [],
    },
    {
      name: 'Suscripciones que se activan',
      data: dashboard?.gained_expired_active_users?.gained_users?.users || [],
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },

      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    labels: dashboard?.gained_expired_active_users?.gained_users.date || [],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: 'left',
    },
  };

  const options_money = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
      events: {
        dataPointMouseEnter: function (event) {
          event.target.style.cursor = 'pointer';
        },
        click: function (event, chartContext, config) {
          if (config.dataPointIndex !== -1) {
            const serie = Object.keys(dashboard?.billing_general)[config.seriesIndex];
            const date = dashboard?.billing_general[serie].date[config.dataPointIndex];
            const type = dashboard?.billing_general[serie].type;
            const payment_name = dashboard?.billing_general[serie].payment_name;
            let url = '/orders?' + 'date_from=' + date + '&date_to=' + date;
            url += payment_name ? '&payment_name=' + payment_name : '';
            url += type ? '&type=' + type : '';
            navigate(url);
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    labels: dashboard?.billing_general
      ? dashboard?.billing_general[Object.keys(dashboard.billing_general)[0]]?.date
      : [],
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value + ' €';
        },
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    plotOptions: {
      bar: {
        columnWidth: '90%',
      },
    },
  };

  // 7
  const series_betting_general = dashboard?.billing_general
    ? Object.keys(dashboard.billing_general).map((key) => ({
        name: dashboard.billing_general[key].description,
        data: dashboard.billing_general[key].billing || [],
      }))
    : [];

  // 8
  const series_bounds_populars = dashboard?.count_rate_on_subscription?.map((bond) => bond?.count_rate_id) || [];
  const options_bounds_populars = {
    chart: {
      width: 380,
      type: 'donut',
      events: {
        mouseMove: function (event) {
          event.target.style.cursor = 'pointer';
        },
        click: function (event, chartContext, config) {
          const url = '/users?type=all_subscriptions';
          navigate(url);
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return w.globals.series[seriesIndex];
      },
    },
    labels: dashboard?.count_rate_on_subscription?.map((bond) => bond?.rates_title) || [],
    legend: {
      show: true,
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return seriesName + ': ' + opts.w.globals.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
  };
  const type_bounds_populars = 'donut';
  // 9
  const series_referred_users = [
    {
      name: 'Referidos',
      data: dashboard?.referred_users?.map((affiliate) => affiliate?.count_referred) || [],
    },
  ];
  const options_referred_users = {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        dataPointMouseEnter: function (event) {
          event.fromElement.style.cursor = 'pointer';
        },
        click: function (event, chartContext, config) {
          if (config.dataPointIndex !== -1) {
            navigate('/users/' + dashboard?.referred_users[config.dataPointIndex].joined);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        distributed: true,
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: dashboard?.referred_users?.map((affiliate) => affiliate?.fullname) || [],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  // 11
  const series_bounds_populars_news_reg =
    dashboard?.count_rate_on_news_subscription?.map((bond) => bond?.count_rate_id) || [];
  const options_bounds_populars_news_reg = {
    chart: {
      selection: {
        enabled: false,
      },
      width: 380,
      type: 'donut',
      events: {
        mouseMove: function (event) {
          event.target.style.cursor = 'pointer';
        },
        click: function (event, chartContext, config) {
          // Por un bug que hay en los gráficos de tipo pie(donut), hay que obtener el dataPointIndex de la siguiente forma
          // const dataPointIndex = event.target.parentElement.getAttribute('data:realIndex');
          // console.log('dataPointIndex', dataPointIndex);
          // if (dataPointIndex && dataPointIndex >= 0) {
          // const rate_id = dashboard?.count_rate_on_news_subscription[dataPointIndex].rates_id;
          // const rate_title = dashboard?.count_rate_on_news_subscription[dataPointIndex].rates_title;
          // console.log(rate_id + ' - ' + rate_title);
          // }
          const url =
            '/users?type=new_subscriptions&date_from=' + dashboard?.date_from + '&date_to=' + dashboard?.date_to;
          navigate(url);
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return w.config.series[seriesIndex];
      },
    },
    labels: dashboard?.count_rate_on_news_subscription?.map((bond) => bond?.rates_title) || [],
    legend: {
      show: true,
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return seriesName + ': ' + opts.w.globals.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
  };

  return {
    series_incidents_betting_house,
    options_incidents_betting_house,
    type_bar,
    series,
    options,
    options_money,
    series_betting_general,
    series_bounds_populars,
    options_bounds_populars,
    type_bounds_populars,
    series_referred_users,
    options_referred_users,
    series_bounds_populars_news_reg,
    options_bounds_populars_news_reg,
  };
};
