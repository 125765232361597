import { GraphicCardNumber, Graphic } from '@vilibets/vilibets-uikit/dist/molecules/Graphic/Graphic';
import { WelcomeWizard } from '@vilibets/vilibets-uikit/dist/molecules/Wizards/Wizards';
import { Card } from '@vilibets/vilibets-uikit/dist/atoms/Card';
import { Bell, Clock } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import updateUser from '../../services/users';
import { user as cUser } from './constants/user';
import { Link } from 'react-router-dom';
import Moment from 'moment';

const DashboardUser = ({ dashboard }) => {
  const {
    type,
    optionsProfitAndLoss,
    seriesProfitAndLoss,
    optionsCumulativeProfitAndLoss,
    seriesCumulativeProfitAndLoss,
  } = cUser(dashboard);

  const {
    dispatchUser,
    state: { user },
  } = useUser();

  const [showWizard, setShowWizard] = useState(false);

  useEffect(() => {
    if (user) {
      setShowWizard(
        user?.is_showed_wizard_welcome && new Date(user?.subscription?.end_date).getTime() >= new Date().getTime()
      );
    }
  }, [user]);

  const closeWizard = async () => {
    setShowWizard(false);
    const response = await updateUser({ ...user, is_showed_wizard_welcome: false });
    dispatchUser({ type: 'hasUser', dispatch: response });
  };

  return (
    <>
      {showWizard && (
        <WelcomeWizard
          display={true}
          closePopup={() => closeWizard()}
          userName={user?.name ?? user?.username}
          male={true}
          subtitle_1={'¡Estamos encantados de ver nuestra familia crecer! '}
          p1_1={
            'Queremos que empieces a ganar dinero desde el primer momento, pero antes hay unos conceptos básicos que deberías aprender para poder lograrlo.'
          }
          p2_1={
            'Sabemos que nuestro método no es fácil de entender si no estás familiarizado, así que déjanos explicarte algunos aspectos esenciales de ViliBets.  Sólo serán unos minutos y te serán de gran ayuda.'
          }
        />
      )}
      <section className="grid grid-cols-1 justify-between md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-6 mb-6">
        <Card
          title={
            <div className="flex flex-row items-center">
              <Icon className={`${user?.notices > 0 ? 'stroke-warning-red' : 'stroke-black'} text-xl pr-2`}>
                <Bell />
              </Icon>
              Avisos
            </div>
          }
          className={`${user?.notices > 0 ? 'border border-red-300 shadow-lg shadow-red-200' : ''} bg-white relative`}>
          <p className="mb-2 dark:text-accent-dark">
            {user?.notices > 0 ? (
              <div className="relative">
                Hay{' '}
                <Link to="/notices" className="text-blue-600 hover:underline">
                  <strong>
                    {user?.notices} aviso{user?.notices > 1 ? 's' : ''}
                  </strong>
                </Link>
                .
              </div>
            ) : (
              'No hay avisos.'
            )}
          </p>
          {user?.last_updated_at_notice && (
            <div
              className="absolute bottom-2 right-2 text-xs text-gray-600 flex cursor-help dark:text-accent-dark"
              title="Última actualización">
              <Icon className="stroke-black text-lg pr-1">
                <Clock />
              </Icon>
              {Moment(user?.last_updated_at_notice).format('DD/MM/YYYY HH:mm')}
            </div>
          )}
        </Card>
        {/* Beneficios del periodo (numero + % comparativo) (Profit tracker) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Beneficios del periodo"
          number={dashboard?.profitAndLossComparativePercentage?.sumProfit || 0}
          percentageNumber={dashboard?.profitAndLossComparativePercentage?.profitCalcPercentage.toString() || '0'}
          graphData={dashboard?.profitAndLossComparativePercentage?.sumProfitEveryDay?.stake}
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Beneficios totales (número + % comparativo) (Profit tracker) */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Beneficios totales"
          number={dashboard?.totalProfitAndLoss?.totalSumProfit || 0}
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Ratio de baneficios vs completadas */}
        <GraphicCardNumber
          className="min-h-[140px] hidden xl:block"
          title="€/bonos"
          number={dashboard?.ratioOfPromotionsBenefitPublished || 0}
          graphData={[]}
          sufix=" €"
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
        {/* Bonos completados */}
        <GraphicCardNumber
          className="min-h-[140px]"
          title="Bonos completados"
          number={dashboard?.bondsCompleted || 0}
          graphData={[]}
          sufix=""
          widthGraph="60"
          heightGraph="50"
          numberClass="text-3xl font-bold dark:text-accent-dark"
        />
      </section>
      <section className="grid grid-cols-1 justify-between xl:grid-cols-2 gap-6 mb-10">
        {/* Beneficios del periodo (histograma) (Profit tracker) */}
        <Graphic
          title="Beneficios del periodo"
          series={seriesProfitAndLoss}
          options={optionsProfitAndLoss}
          type={type}
          className="bg-white"
          height="350px"
          width="100%"
        />
        {/* Beneficios del periodo (histograma) (Profit tracker) */}
        <Graphic
          title="Beneficios totales"
          series={seriesCumulativeProfitAndLoss}
          options={optionsCumulativeProfitAndLoss}
          type={'line'}
          className="bg-white"
          height="350px"
          width="100%"
        />
      </section>
    </>
  );
};

export default DashboardUser;
