import axios from '../lib/axios';

export default async function getBonds(params = '', selectedBookmarks = null) {
  const page_size = 30;
  if (selectedBookmarks != null) {
    const activeBookmarks = [];
    selectedBookmarks.forEach((el) => {
      if (el.defaultChecked === true) activeBookmarks.push(el.id);
    });
    const response = await axios
      .get(
        `/api/v1/bonds?page_size=${page_size}${params.replace('?', '&')}&bookmarkers=${activeBookmarks}${
          params.includes('page_number') ? '' : '&page_number=1'
        }`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  } else {
    const response = await axios
      .get(
        `/api/v1/bonds?page_size=${page_size}${params.replace('?', '&')}${
          params.includes('page_number') ? '' : '&page_number=1'
        }`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  }
}

export async function putBond(params) {
  if (typeof params.video_url !== 'string' && params.video_url) {
    params.video_url = await uploadVideo(params.video_url);
  }
  const response = await axios
    .put(`/api/v1/bonds/${params.id}`, params)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

export async function postBond(params) {
  if (params.video_url) {
    params.video_url = await uploadVideo(params.video_url);
  }
  const params_without_voids = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== ''));
  const response = await axios
    .post('/api/v1/bonds', params_without_voids)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

export async function deleteUserBond(user_id, bond_id) {
  const response = await axios
    .delete(`/api/v1/users/${user_id}/delete-bond/${bond_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function deleteBond(id) {
  await axios
    .delete(`/api/v1/bonds/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function uploadVideo(video_url) {
  const formData = new FormData();
  formData.append('video_url', video_url);

  const response = await axios
    .post('/api/v1/bonds/upload-video', formData)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

// export default async function getBonds() {
//   const response = await axios
//     .get(`/api/v1/bonds`)
//     .then((res) => res)
//     .catch((error) => {
//       if (error.response.status !== 409) throw error;
//     });

//   return response.data;
// }

export async function getBond(bond_id) {
  const response = await axios
    .get(`/api/v1/bonds/${bond_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getUserActiveBonds(user_id, params = '', selectedBookmarks = null) {
  if (selectedBookmarks != null) {
    const activeBookmarks = [];
    selectedBookmarks.forEach((el) => {
      if (el.defaultChecked === true) activeBookmarks.push(el.id);
    });
    const response = await axios
      .get(
        `/api/v1/bonds/available?user_id=${user_id}&is_active=true&outdated=false&page_size=30&orderby=valid_until&orderby_direction=asc${params.replace(
          '?',
          '&'
        )}&bookmarkers=${activeBookmarks}
        }`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  } else {
    const response = await axios
      .get(
        `/api/v1/bonds/available?user_id=${user_id}&is_active=true&outdated=false&page_size=30&orderby=valid_until&orderby_direction=asc${params.replace(
          '?',
          '&'
        )}`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });

    return response.data;
  }
}

export async function getUserCompletedBonds(user_id, params = '', selectedBookmarks = null) {
  if (selectedBookmarks != null) {
    const activeBookmarks = [];
    selectedBookmarks.forEach((el) => {
      if (el.defaultChecked === true) activeBookmarks.push(el.id);
    });
    const response = await axios
      .get(
        `/api/v1/bonds/completed?user_id=${user_id}&page_size=30${params.replace(
          '?',
          '&'
        )}&bookmarkers=${activeBookmarks}
        }`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  } else {
    const response = await axios
      .get(`/api/v1/bonds/completed?user_id=${user_id}&page_size=30${params.replace('?', '&')}`)
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });

    return response.data;
  }
}

export async function getUserDeletedBonds(user_id, params = '') {
  const response = await axios
    .get(`/api/v1/bonds/deleted?user_id=${user_id}&page_size=30${params.replace('?', '&')}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getUserExpiredBonds(user_id, params = '', selectedBookmarks = null) {
  if (selectedBookmarks != null) {
    const activeBookmarks = [];
    selectedBookmarks.forEach((el) => {
      if (el.defaultChecked === true) activeBookmarks.push(el.id);
    });
    const response = await axios
      .get(
        `/api/v1/bonds/expired?user_id=${user_id}&page_size=30${params.replace('?', '&')}&bookmarkers=${activeBookmarks}
        }`
      )
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  } else {
    const response = await axios
      .get(`/api/v1/bonds/expired?user_id=${user_id}&page_size=30${params.replace('?', '&')}`)
      .then((res) => res)
      .catch((error) => {
        if (error.response.status !== 409) throw error;
      });
    return response.data;
  }
}

export async function getBondsProfit(user_id, params = '') {
  const response = await axios
    .get(`/api/v1/bonds/profit?user_id=${user_id}&is_active=true&outdated=false${params.replace('?', '&')}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getKPIBonds(user_id, types) {
  const response = await axios
    .get(`/api/v1/users/${user_id}/kpi-bonds${types ? '?types=' + types : ''}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function getKPIProfitTracker(user_id) {
  const response = await axios
    .get(`/api/v1/users/${user_id}/kpi-profittracker`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

// export async function deleteBond(user_id, bond_id) {
//   const response = await axios
//     .delete(`/api/v1/users/${user_id}/delete-bond/${bond_id}`)
//     .then((res) => res)
//     .catch((error) => {
//       if (error.response.status !== 409) throw error;
//     });

//   return response.data;
// }

export async function undeleteBond(user_id, bond_id) {
  const response = await axios
    .delete(`/api/v1/users/${user_id}/undelete-bond/${bond_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function completeBond(user_id, bond_id) {
  const response = await axios
    .post(`/api/v1/users/${user_id}/complete-bond/${bond_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function uncompleteBond(user_id, bond_id) {
  const response = await axios
    .delete(`/api/v1/users/${user_id}/uncomplete-bond/${bond_id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function sendBondToTelegram(bond_id) {
  const response = await axios
    .post(`/api/v1/bonds/${bond_id}/send-to-telegram`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });

  return response.data;
}

export async function uploadPhoto(blobInfo) {
  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  const response = await axios
    .post('/api/v1/bonds/upload-photo', formData)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}
