import { useUser } from '../context/UserContext';
import axios from '../lib/axios';

export default async function getMe() {
  const { dispatchUser } = useUser();
  const response = await axios
    .get('/me')
    .then((res) => res)
    .catch((error) => {
      dispatchUser({ type: 'hasNotUser', dispatch: undefined });
      if (error.response.status !== 409) throw error;
    });

  return {
    response: response.data,
  };
}
