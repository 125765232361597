import { Helmet } from 'react-helmet';

const OddsDutcher = () => {
  const date = new Date();
  return (
    <>
      <Helmet>
        <script
          src={
            'https://widgets.scrapebet.com/static/oddsmatcher.js?d=' +
            date.getDay() +
            '-' +
            new Date().getMonth() +
            '-' +
            date.getHours()
          }
          type="text/javascript"
        />
      </Helmet>
      <div
        id="sbet_widget"
        data-elements-per-page="20"
        data-type="dutcher-full"
        data-font="Inter"
        data-client="6324e6ce518a3317260083a2"
        data-style="2"
      />
    </>
  );
};

export default OddsDutcher;
// https://widgets.scrapebet.com/static/oddsmatcher_dev4.js?d=
