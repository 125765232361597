import axios from '../lib/axios';

export default async function getNotices(params = '') {
  const response = await axios
    .get(
      `/api/v1/notices?page_size=30${params.replace('?', '&')}${params.includes('page_number') ? '' : '&page_number=1'}`
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}

export async function deleteNotice(id) {
  await axios
    .delete(`/api/v1/notices/${id}`)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function createNotice(notice) {
  await axios
    .post(`/api/v1/notices/`, notice)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function updateNotice(notice) {
  await axios
    .put(`/api/v1/notices/${notice.id}`, notice)
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
}

export async function getActiveNotices() {
  const response = await axios
    .get('/api/v1/notices/active')
    .then((res) => res)
    .catch((error) => {
      if (error.response.status !== 409) throw error;
    });
  return response.data;
}
