import Calculators from './Calculators';
import { RiskFreeCalculator } from '@vilibets/vilibets-uikit/dist/molecules/Calculators/Calculators';
import { useSearchParams } from 'react-router-dom';

const RiskFreeCalc = () => {
  // get params from url
  const [searchParams, setSearchParams] = useSearchParams();
  // get searchParams and set it to initialParams
  const initialParams = () => {
    let params = {};
    for (const [key, value] of searchParams) {
      params = { ...params, [key]: value };
    }
    if (!('txtFreeBetRetension' in params)) {
      params.txtFreeBetRetension = '75.00';
    }
    return params;
  };

  return (
    <Calculators>
      <RiskFreeCalculator
        initialParams={initialParams}
        onChange={(params) => {
          setSearchParams(params);
        }}
      />
    </Calculators>
  );
};

export default RiskFreeCalc;
